<template>
  <div>
    <div v-if="!isStarted">
      <div class="d-flex justify-center mb-3">
        <custom-dialog
          class="d-flex"
          :is-visible="isDialogVisible"
          :button-text="dialogData.buttonText"
          :title="dialogData.title"
          :description="dialogData.description"
        />
      </div>
      <div class="d-flex justify-center mb-5">
        <v-btn
          color="success"
          @click="init()"
          >Comenzar</v-btn
        >
      </div>
    </div>

    <div
      v-if="isStarted"
      class="grid w-100 justify-center"
    >
      <div class="d-flex w-100 justify-center mb-2">
        <level-progress
          :value="currentProgressValue"
          :maxValue="50"
          :width="10"
        />
      </div>
      <div class="d-flex justify-center">
        <memory-card-manager @on-update-game="updateValue" />
      </div>
    </div>

    <winner-modal
      v-if="!isExercise"
      :hasWon="isWinner"
      @reload="updateLevel"
    />
    <winner-modal
      v-if="isExercise"
      :hasWon="isWinner"
      @reload="finishExercise"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Emit, Prop } from 'vue-property-decorator';
  import MemoryCardManager from './MemoryCardManager.vue';
  import CustomDialog from '../../../Misc/CustomDialog.vue';
  import { DIALOG_MEMORYCARD_DATA } from '../../../../helpers/DialogHelper';
  import LevelProgress from '../../../Misc/LevelProgress.vue';
  import WinnerModal from '../../../Misc/WinnerModal.vue';

  @Component({
    components: {
      MemoryCardManager,
      CustomDialog,
      LevelProgress,
      WinnerModal,
    },
  })
  export default class MemoryCardGame extends Vue {
    @Prop({ default: false }) isExercise!: boolean;

    state: string = '';
    dialogData: any = DIALOG_MEMORYCARD_DATA;
    isDialogVisible: boolean = false;
    progressValue: number = 0;

    get isStarted(): boolean {
      return this.state === 'started';
    }

    get currentProgressValue(): number {
      return this.progressValue;
    }

    get isWinner(): boolean {
      return this.progressValue === 100;
    }

    created(): void {
      this.state = 'menu';
    }

    init(): void {
      this.state = 'started';
    }

    public processFlow(): void {
      if (this.isExercise) {
        this.finishExercise();
      } else {
        this.updateLevel();
      }
    }

    @Emit('finishExercise')
    public finishExercise(): number {
      return 1;
    }

    setOnMenu(): void {
      this.state = 'menu';
    }

    updateValue(newValue: number): void {
      this.progressValue = newValue;
    }

    updateLevel(): void {
      this.reset();
      console.log('here');
    }

    reset(): void {
      this.progressValue = 0;
      this.setOnMenu();
    }
  }
</script>
