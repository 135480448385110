<template>
  <words v-if="isVisible" :matrixMode="true" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Words from '@/components/Words.vue';

@Component({
  components: {
    Words,
  },
})
export default class Matrix extends Vue {
  isVisible: boolean = false;

  mounted() {
    this.isVisible = true;
  }
}
</script>
