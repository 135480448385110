import http from '@/helpers/https';
import { AxiosInstance } from 'axios';
import Global from '@/helpers/Global';

export default class GameService {
  private http: AxiosInstance = http();

  private gameMapping: any = {
    'memory-colour': 'MemoryColourController.php',
    signos: 'SignosController.php',
    words: 'WordsController.php',
    matrix: 'MatrixController.php',
    smiler: 'SmilerController.php',
    questions: 'QuestionsController.php',
  };

  public initGame(controller: string, body: any, callback: Function): void {
    this.http
      .post(this.getGameUrl(controller), {
        ...body,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private getGameUrl(controller: string): string {
    const gameController = this.gameMapping[controller] ?? '';

    if (gameController === '') {
      throw new Error('Invalid controller');
    }

    const baseURL = Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/';

    return baseURL + gameController;
  }
}
