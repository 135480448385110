import RequestService from '../../Request/RequestService';
import GamePerformanceStructure from '../Structure/GamePerformanceStructure';

export default class GamePerformanceRepository {


    private requestService: RequestService;
    private controller: string = 'GamePerformanceController';
    private actions: any = {
        save: 'save',
        get: 'get',
        update: 'update',
        delete: 'delete',
    };

    constructor () {
        this.requestService = new RequestService();
    }

    public saveGamePerformance(gamePerformanceStructure: GamePerformanceStructure) {

        gamePerformanceStructure.action = this.actions.save;
        gamePerformanceStructure.controller = this.controller;

        this.requestService.send(gamePerformanceStructure);
    }
}