import { AxiosInstance } from 'axios';
import http from '../../../helpers/https';
import Global from '../../../helpers/Global';
import RequestStructure from '@/components/Request/Structures/RequestStructure';

export default class Request {

    protected _controllerRoute: string = 'IntelectoController/';

    protected sender: AxiosInstance;

    constructor () {
        this.sender = http();
    }

    public async post(structure: RequestStructure): Promise<any> {
        //@ts-ignore
        const route: string = this.buildRoute(structure.controller);

        return await this.sender.post(route, structure).then(response => {
            return response.data;
        }).catch(error => {
            console.log('Error');
            console.log(error);
        }).finally(() => {
            //console.log("Saved results");
        });
    }

    public async get(structure: RequestStructure): Promise<any> {
        //@ts-ignore
        const route: string = this.buildRouteWithAction(structure.controller, structure.action);

        return await this.sender.get(route).then(response => {
            return response.data;
        }).catch(error => {
            console.log('Error');
            console.log(error);
        }).finally(() => {
        });
    }

    private buildRoute(controller: string): string {
        const domain: string = Global.resolveUrl(process.env.NODE_ENV)
        const route: string = domain + this._controllerRoute + controller + '.php';

        return route;
    }

    private buildRouteWithAction(controller: string, action: string): string {
        const domain: string = Global.resolveUrl(process.env.NODE_ENV)
        const route: string = domain + this._controllerRoute + controller + '.php?action=' + action;

        return route;
    }
}