class SmileItem {
    /**
     * @param {Integer} id  
     */
    id;

    /**
     * @property {String} icon
     */
    icon;

    /**
     * @param {Integer} id
     * id number
     * @param {String} icon 
     * mdi-icon config from Material design Icon reference.
     */
    constructor (id: Number| string, icon: string) {
        this.id = id;
        this.icon = icon;
    }
}
export default SmileItem;