<template>
  <div>
    <v-progress-circular
      color="green"
      class="rounded"
      :width="width"
      :size="maxValue"
      rounded
      v-bind:value="this.value"
    >
      {{ this.value + '%' }}
    </v-progress-circular>
  </div>
</template>

<script lang="js">
  export default {
    name: "LevelProgress",

    props: {
        value: {
            type: Number,
            required: true,
            default: 0
        },
        maxValue: {
          type: Number,
            required: false,
            default: 100
        },
        
        width: {
          type:Number,
          required:false,
          default:15,
        },
    }
  };
</script>
