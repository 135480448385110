<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    :persistent="true"
    v-model="isVisible"
  >
    <v-card>
      <v-card-title class="text-h3 grey lighten-2 mx-auto">
        <p class="mx-auto">Has ganado!</p>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          v-if="displayPreviousLevelOption"
          class="yellow mb-2 mx-auto"
          @click="onRequestPreviousLevel"
        >
          Nivel anterior
        </v-btn>
        <v-btn
          class="primary mb-2 mx-auto"
          @click="onRepeat"
        >
          Repetir ejercicio
        </v-btn>
        <v-btn
          class="primary mb-2 mx-auto"
          @click="onAcceptCallback"
          >Siguiente nivel
        </v-btn>
        <v-btn
          class="primary mb-2 mx-auto"
          @click="onCloseModal"
          >Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import { on } from 'process';
  import Vue from 'vue';
  import { Component, Emit, Prop } from 'vue-property-decorator';

  @Component({})
  export default class ExerciseWinnerModal extends Vue implements IBaseModal {
    @Prop({ required: true }) isVisible: boolean;
    @Prop({ required: false, default: false }) previousLevelOption: boolean;

    get displayPreviousLevelOption(): boolean {
      return this.previousLevelOption;
    }

    @Emit('callback')
    onAcceptCallback(): boolean {
      return true;
    }

    @Emit('repeat')
    onRepeat(): boolean {
      return true;
    }

    @Emit('previousLevel')
    onRequestPreviousLevel(): boolean {
      return true;
    }

    @Emit('close-modal')
    onCloseModal(): boolean {
      return true;
    }
  }
</script>
