import Request from "./Models/Request"
import RequestStructure from '../Request/Structures/RequestStructure';

export default class RequestService {
    private request: Request

    constructor () {
        this.request = new Request();
    }

    public async send(structure: RequestStructure): Promise<any> {
        return await this.request.post(structure);
    }

    public async get(structure: RequestStructure): Promise<any> {
        return await this.request.get(structure);
    }
}