import { AxiosInstance } from 'axios';
import http from './https';
import Global from './Global';
class HardModeHelper {
    text: string;
    conecction: AxiosInstance = http();
    prefix: string = 'IntelectoController/';

    async getDistractionText(): Promise<string> {
        // implement fetch from db 

        await this.conecction.post(this.getControllerPath('WordsController'), {
            action: 'get_distraction',
            hashDev: Global.hashDev()
        }).then(response => {

            this.text = response.data;
        });

        return this.text
    }

    getControllerPath(controllerName: string = 'WordsController'): string {

        return Global.resolveUrl(process.env.NODE_ENV) + this.prefix + controllerName + '.php';
    }
}
export default HardModeHelper;