import { AxiosInstance } from 'axios';
import Global from '@/helpers/Global';
import http from '@/helpers/https';
import { Component, Emit, Vue } from 'vue-property-decorator'
import { time } from 'console';

@Component
class GameRequestMixing extends Vue {

    request: AxiosInstance = http();
    prefix: string = "IntelectoController/"
    initLastGameAction: string = 'init_last_game'
    initChallengeAction: string = 'init_challenge'
    sendResultsAction: string = 'save_user_results'
    initAction: string = 'init_game';
    isOnChallenge: boolean = false;
    gameController: string = ''
    builderMethod: Function = () => { };
    builderGameParamsMethod: Function = () => { };
    challengeLevel: number = 1;

    public async initGameMixin(controller: string, builderMethod: Function, builderGameParamsMethod: Function) {
        this.builderMethod = builderMethod;
        this.gameController = controller;
        this.builderGameParamsMethod = builderGameParamsMethod;

        if (this.isOnChallenge) {
            await this.initChallenge();
        }
        else {
            await this.initGame();
        }
    }

    /**
     * @param {function} builderGameParamsMethod 
     */
    setBuilderParamsMethod(builderGameParamsMethod: Function) {
        this.builderGameParamsMethod = builderGameParamsMethod;
    }

    setBuilderMethod(builderMethod: Function) {
        this.builderMethod = builderMethod;
    }


    setGameController(controller: string = '') {
        if (controller) {
            this.gameController = controller;
        }
        else {
            alert("Debes llamar a initGameMixin() para utilizar el mixin");
        }
    }

    async initGame(needLastLevel: boolean = false) {
        await this.request.post(Global.resolveUrl(process.env.NODE_ENV) + this.prefix + this.gameController + '.php', {
            action: needLastLevel ? 'init_last_game' : this.initAction,
            hashDev: Global.hashDev(),
        }).then((response) => {
            this.builderMethod(response.data);
        })
    }


    async initChallenge() {
        await this.request.post(Global.resolveUrl(process.env.NODE_ENV) + this.prefix + this.gameController + '.php', {
            action: this.initChallengeAction,
            levelId: this.challengeLevel,
            hashDev: Global.hashDev(),

        }).then((response) => {
            this.builderMethod(response);
        })
    }

    async initLastGame() {
        await this.request.post(Global.resolveUrl(process.env.NODE_ENV) + this.prefix + this.gameController + '.php', {
            action: this.initLastGameAction,
            hashDev: Global.hashDev(),
        }).then((response) => {
            this.builderMethod(response.data);
        })  
    }

    @Emit("on-finish-challenge")
    async saveResults(isOnChallenge: boolean = false, needLastLevel: boolean = false) {
        if (isOnChallenge) {
            this.$emit('on-finish-challenge', this.builderGameParamsMethod());
            console.log("on-finish-challenge");
            return;
        }

        await this.request.post(Global.resolveUrl(process.env.NODE_ENV) + this.prefix + this.gameController + '.php', {
            action: this.sendResultsAction,
            hashDev: Global.hashDev(),
            data: this.builderGameParamsMethod(),
        });

        await this.initGame(needLastLevel);
    }
}
export default GameRequestMixing;
