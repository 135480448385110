<template>
  <div class="d-flex">
    <div class="d-flex mx-auto my-auto">
      <div
        id="game"
        :class="{ gameStarted: !hasReloads }"
      >
        <div>
          <v-switch
            v-if="!isAnimationStarted"
            v-model="isHardMode"
            label="Modo difícil"
            color="red"
            prepend-icon="mdi-fire"
            class="mr-auto my-5"
          >
          </v-switch>
        </div>
        <div
          v-if="!isAnimationFinished"
          class="d-flex justify-center mt-5"
        >
          <!-- Random word to remember -->
          <div
            v-for="word in arrayWords"
            :key="word.id"
          >
            <div
              class="mx-1 word"
              :class="{ letter: !hasReloads }"
            >
              {{ word }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-center mt-5">
          <v-btn
            v-if="!isAnimationFinished"
            @click="getRound()"
            :disabled="!hasReloads"
          >
            Nueva combinación
          </v-btn>
        </div>

        <!-- After animation finished -->
        <div
          v-if="isAnimationFinished && step === 2"
          class="d-flex justify-center mt-5"
        >
          <div
            v-for="word in arrayWords"
            :key="word.id"
          >
            <div class="mx-1 word ul-letters">
              {{ word }}
            </div>
          </div>
        </div>
        <div>
          <linear-progress
            v-if="step === 2 && timerValue"
            :value="timerValue"
            @on-time-over="startMixAnimation()"
          />
        </div>
        <!-- After timeOut! -->
        <div
          v-if="step === 3"
          class="d-flex justify-center mt-5"
        >
          <v-carousel
            :show-arrows="false"
            :cycle="true"
            height="100"
            :vertical="true"
            :hide-delimiters="true"
            interval="150"
            class="collection d-flex justify-center"
          >
            <v-carousel-item
              v-for="word in randomArray"
              :key="word.id"
            >
              <div class="mx-auto word ul-letters">
                {{ word }}
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>

        <custom-dialog-modal
          :title="'Hey!'"
          :subtitle="randomDistraction"
          :isVisible="isDistractionVisible"
        >
          <v-icon
            x-large
            color="red"
            >mdi-fire</v-icon
          >
        </custom-dialog-modal>

        <div
          v-if="step === 5"
          class="w-100 mx-auto my-auto justify-center"
        >
          <div class="d-flex ma-1 w-100 justify-center">
            <h3 class="d-flex mx-auto">Cual es la respuesta correcta?</h3>
          </div>
          <div class="d-flex w-100 justify-center mr-4">
            <v-radio-group v-model="selectedAnswer">
              <v-radio
                v-for="option in options"
                :key="option.id"
                :label="`${option}`"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div
          v-if="step === 5"
          class="d-flex my-auto w-100 justify-center"
        >
          <v-btn
            elevation="2"
            @click="onSelectedAnswer()"
            :disabled="selectedAnswer === null"
            >Enviar</v-btn
          >
        </div>
      </div>
      <ExerciseWinnerModal
        :is-visible="isWinner && step === 6"
        :previousLevelOption="true"
        @callback="onReload()"
        @repeat="onRepeat"
        @previousLevel="onPreviousLevel"
        @close-modal="onReload()"
      />

      <ExerciseLoserModal
        :is-visible="isLoser"
        :previousLevelOption="true"
        @callback="onReload()"
        @repeat="onRepeat"
        @previousLevel="onPreviousLevel"
        @close-modal="onReload()"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import GameUtilsMixin from '../mixins/gameUtilsMixin';
  import GameRequestMixin from '../mixins/gameRequestMixin';
  import scrollToGame from '../helpers/GameHelper';
  import LinearProgress from './Misc/LinearProgress.vue';
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import CustomDialog from './Misc/CustomDialog.vue';
  import CustomDialogModal from './Misc/CustomDialogModal.vue';
  import HardModeHelper from '../helpers/HardModeHelper';
  import { GamePerformanceService } from './UserPerformance/GamePerformanceService';
  import ExerciseLoserModal from './Misc/Exercises/Modals/ExerciseLoserModal.vue';
  import ExerciseWinnerModal from './Misc/Exercises/Modals/ExerciseWinnerModal.vue';

  @Component({
    components: {
      LinearProgress,
      CustomDialog,
      CustomDialogModal,
      ExerciseLoserModal,
      ExerciseWinnerModal,
    },
  })
  export default class Words extends Mixins(GameUtilsMixin, GameRequestMixin) {
    @Prop({ required: false, default: false }) matrixMode: boolean;

    name: string = 'Words';
    level: number | null = null;
    arrayWords: Array<any> = [];
    characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    numbers: string = '1234567890';
    reloads: number = 1;
    isStarted: boolean = false;
    isAnimationFinished: boolean = false;
    isAnimationStarted: boolean = false;
    timerValue: number = 50;
    randomArray: Array<any> = [];
    step: number = 1;
    options: Array<any> = [];
    selectedAnswer: string | null = null;
    rightAnswer: string | null = '';
    isWinner: boolean = false;
    isLoser: boolean = false;
    wordSize: number = 0;
    isHardMode: boolean = false;
    hardModeHelper: HardModeHelper;
    randomDistraction: string = '¿?';

    private performanceService: GamePerformanceService;

    constructor() {
      super();
      this.performanceService = GamePerformanceService.getInstance();
      this.performanceService.setGameId(7);
    }

    get isLogged() {
      return this.$store.getters.getIsLogged;
    }

    get hasReloads() {
      return this.reloads > 0;
    }

    get isDistractionVisible() {
      return this.step === 4 && this.isHardMode;
    }

    async created() {
      this.hardModeHelper = new HardModeHelper();

      await this.initGameMixin('WordsController', this.setWordSize, this.gameResults);
      scrollToGame();
    }

    getRound() {
      let times = 0;
      this.reloads--;
      this.isAnimationStarted = true;
      const interval = setInterval(() => {
        this.buildGameWords(this.wordSize);
        times++;
        if (times > 20) {
          clearInterval(interval);
          if (!this.hasReloads) {
            this.isStarted = true;
            this.start();
          }
        }
      }, 70);
    }

    gameResults() {
      return {
        game: 'Words',
        idNivelPregunta: this.level,
        idAlumno: this.level,
        total: this.isWinner ? this.arrayWords.length : 0,
      };
    }

    setWordSize(params: { currentLevel: number; wordSize: number }) {
      this.level = params.currentLevel;
      this.wordSize = params.wordSize;
    }

    buildGameWords(wordSize: number) {
      this.arrayWords = this.generateRandomString(wordSize);
    }

    generateRandomString(num: number) {
      let result = [];
      const charactersLength = this.characters.length;
      const numbersLenght = this.numbers.length;
      for (let i = 0; i < num; i++) {
        if (this.matrixMode) {
          // Refactor later
          if (i !== 0 && i % 2 === 0) {
            result.push(this.characters.charAt(Math.floor(Math.random() * charactersLength)));
          } else {
            result.push(this.numbers.charAt(Math.floor(Math.random() * numbersLenght)));
          }
        } else {
          result.push(this.characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
      }

      return result;
    }

    onRepeat(): void {
      this.onReload(true);
    }

    /**
     * Reload the game with the previous level
     */
    onPreviousLevel(): void {
      this.initLastGame();
      this.resetDefault();
    }

    start() {
      this.reloads = 0;
      this.isStarted = true;
      this.randomArray = [...this.arrayWords];

      setTimeout(() => {
        this.isAnimationFinished = true;
        this.performanceService.setCurrentAttempt();
        this.showTimer();
      }, 700);
    }

    showTimer() {
      this.step = 2;
      this.initTimer(this.timerValue);
      this.buildMix();
    }

    buildMix() {
      this.rightAnswer = this.getWordFromArray(this.arrayWords);
      this.options.push(this.rightAnswer);
      const optionsLength = this.arrayWords.length - this.shuffleRules(this.arrayWords.length);

      for (let i = 0; i < optionsLength; i++) {
        // Generate a new word with the game rules from: gameUtilsMixin::shuffleRules();

        let word = this.getWordFromArray(this.arrayWords);

        // Mix word to avoid pushing repeated values when generating a new set
        while (this.options.includes(word)) {
          word = this.getMixedWordFromArray(this.arrayWords);
        }

        this.options.push(word);

        // Mix array to avoid placing the solution at first position [0] line 177
        this.shuffleArray(this.options);
      }
    }

    async startMixAnimation() {
      this.step = 3;

      if (this.isHardMode) {
        this.randomDistraction = await this.hardModeHelper.getDistractionText();
      }

      // Show distraction
      setTimeout(() => {
        if (this.isHardMode) {
          this.step = 4;
        } else {
          this.performanceService.trackAttemptStart();
          this.step = 5;
        }
      }, 4000);

      // Hide distraction and show options
      if (this.isHardMode) {
        setTimeout(() => {
          this.performanceService.trackAttemptStart();
          this.step = 5;
        }, 8000);
      }
    }

    getWordFromArray(array: string | any[]) {
      let word = '';
      for (let i = 0; i < array.length; i++) {
        word += array[i];
      }
      return word;
    }

    getMixedWordFromArray(array: any) {
      let firstWords = this.shuffleRules(array.length);
      let firstWordCollection: Array<any> = [];

      let newArray = [...array];

      array.forEach((item: any) => {
        if (firstWords > 0) {
          firstWordCollection.push(item);
          newArray.shift();
        }
        firstWords--;
      });

      this.shuffleArray(newArray);

      let word = '';

      firstWordCollection.forEach((item) => (word += item));
      newArray.forEach((item) => (word += item));

      return word;
    }

    onSelectedAnswer() {
      this.performanceService.trackAttemptEnd();
      this.isWinner = this.validate();

      this.performanceService.setAttemptResult(this.isWinner ? 1 : 0);
      this.performanceService.collectAttempt();

      if (!this.isWinner) {
        this.isLoser = true;
      }

      this.performanceService.calculateGamePerformance();
      this.performanceService.saveGamePerformance();

      this.step = 6;
    }

    validate() {
      return this.selectedAnswer === this.rightAnswer;
    }

    onReload(needLastLevel: boolean = false) {
      if (this.isLogged) {
        this.saveResults(false, needLastLevel);
      }

      this.resetDefault();
    }

    resetDefault(): void {
      this.step = 1;
      this.reloads = 1;
      this.isStarted = false;
      this.selectedAnswer = null;
      this.isAnimationFinished = false;
      this.isAnimationStarted = false;
      this.arrayWords = [];
      this.timerValue = 50;
      this.options = [];
      this.isLoser = false;
      this.isWinner = false;
    }
  }
</script>

<style>
  @keyframes startRound {
    0% {
      font-size: 18px;
      margin-left: 5% !important;
    }

    25% {
      font-size: 20px;
      margin-left: 10% !important;
    }

    50% {
      font-size: 36px;
      margin-left: 20% !important;
    }

    100% {
      font-size: 56px;
      margin-left: 25% !important;
    }
  }

  @keyframes moveDown {
    0% {
      margin-top: 0%;
    }

    10% {
      margin-top: 1.5%;
    }

    20% {
      margin-top: 3%;
    }

    30% {
      margin-top: 4.5%;
    }

    40% {
      margin-top: 6%;
    }

    50% {
      margin-top: 7.5%;
    }

    60% {
      margin-top: 9%;
    }

    70% {
      margin-top: 10.5%;
    }

    80% {
      margin-top: 12%;
    }

    90% {
      margin-top: 13.5%;
    }

    100% {
      margin-top: 15%;
    }
  }

  .letter:first-child {
    font-size: 56px;
    margin-left: 25%;
    animation-name: startRound;
    animation-duration: 0.7s;
  }

  .gameStarted {
    margin-top: 15%;
    animation-name: moveDown;
    animation-duration: 0.7s;
  }

  .ul-letters {
    font-size: 56px;
    margin-left: 25%;
  }

  .hide {
    display: none !important;
  }

  .collection {
    max-width: 500px;
  }
</style>
