<template>
  <v-dialog
    v-model="isVisible"
    @click:outside="onHideGame"
    max-width="100%"
    max-height="100%"
    transition="dialog-bottom-transition"
    content-class="d-flex flex-column white w-100 h-100"
  >
    <div class="d-flex w-100 mt-1 ml-1 align-center">
      <v-btn
        small
        @click="$emit('on-hide-game')"
      >
        <v-icon>mdi-close</v-icon>
        <div class="d-flex align-center">
          <p class="my-auto">Salir</p>
        </div>
      </v-btn>
    </div>

    <div
      v-if="show"
      class="my-auto mx-auto"
    >
      <MemoryColour v-if="showMemoryColour" />
      <Signos v-if="showSignos" />
      <Smiler v-if="showSmiler" />
      <Category v-if="showCategory" />
      <QuestionsNew v-if="showQuestions" />
      <challenge-basic
        v-if="showChallenge"
        @skip-challenge="onHideGame"
      />
      <words v-if="showWords" />
      <matrix v-if="showMatrix" />
      <memory-card-game v-if="showMemoryCardGame" />
      <creative v-if="showCreative" />
      <go-no-go v-if="showGoNoGo" />
      <ecuations v-if="showEcuations" />
      <countries v-if="showCountries" />
      <the-waiter v-if="showWaiter" />
    </div>
  </v-dialog>
</template>

<script>
  import MemoryCardGame from '../Classes/MemoryCard/components/MemoryCardGame.vue';
  import Matrix from '../Matrix.vue';
  import Words from '../Words.vue';
  import ChallengeBasic from '../ChallengeBasic.vue';
  import Questions from '../Questions.vue';
  import Category from '../Category.vue';
  import Smiler from '../Smiler.vue';
  import Signos from '../Signos.vue';
  import MemoryColour from '../MemoryColour.vue';
  import Creative from '../Creative.vue';
  import GoNoGo from '../Games/GoNoGo/components/GoNoGo.vue';
  import Ecuations from '../Games/Ecuations/components/Ecuations.vue';
  import Countries from '../Games/Countries/components/Countries.vue';
  import TheWaiter from '../Games/Waiter/Components/TheWaiter.vue';
  import QuestionsNew from '../QuestionsNew.vue';

  export default {
    name: 'GameModal',
    components: {
      MemoryColour,
      Matrix,
      MemoryCardGame,
      Signos,
      Smiler,
      Category,
      Questions,
      ChallengeBasic,
      Words,
      Creative,
      GoNoGo,
      Ecuations,
      Countries,
      TheWaiter,
      QuestionsNew,
    },

    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },

      activeGame: {
        type: Number,
        required: true,
      },
    },

    computed: {
      selectedGame() {
        return this.activeGame;
      },
      show() {
        return this.isVisible;
      },

      showMemoryColour() {
        return this.selectedGame === 1;
      },

      showSignos() {
        return this.selectedGame === 2;
      },

      showSmiler() {
        return this.selectedGame === 3;
      },

      showCategory() {
        return this.selectedGame === 4;
      },

      showQuestions() {
        return this.selectedGame === 5;
      },

      showChallenge() {
        return this.selectedGame === 6;
      },

      showWords() {
        return this.selectedGame === 7;
      },

      showMatrix() {
        return this.selectedGame === 8;
      },

      showMemoryCardGame() {
        return this.selectedGame === 9;
      },

      showCreative() {
        return this.selectedGame === 10;
      },

      showGoNoGo() {
        return this.selectedGame === 11;
      },

      showEcuations() {
        return this.selectedGame === 12;
      },

      showCountries() {
        return this.selectedGame === 13;
      },

      showWaiter() {
        return this.selectedGame === 14;
      },
    },

    methods: {
      onHideGame() {
        this.$emit('on-hide-game');
      },
    },
  };
</script>
