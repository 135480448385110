<template>
  <div>
    <SimpleBarChart
      v-if="chartData"
      :title="'Bar chart'"
      :chart-options="chartData.options ?? options"
      :chart-data="chartData"
      :chart-id="'weekly'"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';
  import { Bar as SimpleBarChart } from 'vue-chartjs/legacy';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
  } from 'chart.js';

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
  );
  @Component({
    components: {
      SimpleBarChart,
    },
  })
  export default class BarChart extends Vue {
    @Prop({ required: true }) chartData: any;
    @Prop({ required: false }) options: any;
  }
</script>
