<template>
  <v-icon
    class="mx-auto my-auto translated-top"
    color="yellow darken-2"
    :size="iconSize"
  >
    mdi-crown
  </v-icon>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class CrownIcon extends Vue {
    @Prop({ required: false }) iconSize: number = 30;
  }
</script>
<style lang="scss">
  .translated-top {
    transform: translateY(-2px);
  }
</style>
