<template lang="">
  <div class="container">
    <div class="d-flex w-100 justify-center mb-4">
      <v-icon color="yellow">mdi-lightbulb-on</v-icon>

      <span class="mx-2 mt-2 align-center">Actividades</span>
    </div>

    <warning-banner :visible="isWarningVisible" />
    <div v-if="isSelecting">
      <div class="col-12 text-center">Nivel: {{ level }} </div>
      <div class="d-flex w-100 justify-space-around">
        <div class="w-25 col-3 text-center">
          <v-icon slot="prepend-icon">mdi-target</v-icon>
          <v-select
            v-model="target"
            class=""
            default-text="Selecciona un objetivo"
            label="Selecciona un objetivo"
            :items="getPosibleTargets()"
          >
          </v-select>
        </div>
        <div class="w-25 col-3 text-center">
          <v-icon slot="prepend-icon">mdi-arrow-up</v-icon>
          <v-select
            v-model="level"
            label="Elige un nivel"
            :items="getPosibleLevels()"
          >
          </v-select>
        </div>
      </div>
      <div class="w-100 col-12 text-center mt-5">
        <v-btn
          color="primary"
          :loading="isLoadingTask"
          :disabled="isButtonDisabled"
          @click="getExcercise(level, target)"
        >
          Obtener ejercicio
        </v-btn>
      </div>
    </div>
    <div>
      <div v-if="!isSelecting">
        <div class="col-12 text-center">Objetivo: {{ target }} </div>
        <div class="col-12 text-center">Nivel: {{ level }} </div>
        <div class="col-12 my-5">
          <div class="col-12 text-center tarea">Ejercicio: </div>
          <div class="col-12 text-center text-bold bold tarea">
            {{ excercise.tarea }}
          </div>
          <div class="col-12 text-center">
            <v-btn
              v-if="!isExercise"
              class="mt-5"
              small
              :loading="isBtnLoading"
              color="primary"
              variant="primary"
              @click="selectAgain()"
            >
              Volver a seleccionar
            </v-btn>

            <v-btn
              v-if="isExercise"
              class="mt-5"
              small
              color="primary"
              variant="primary"
              @click="finishExercise()"
            >
              Finalizar ejercicio
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import CognosApi from '@/core/API/CognosApi';
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import CreativeExercise from '@/components/Classes/CreativeExercise';
  import WarningBanner from './Content/WarningBanner.vue';

  @Component({
    components: {
      WarningBanner,
    },
  })
  export default class Creative extends Vue {
    @Prop({ required: false, default: false }) isExercise: boolean;

    path: string = 'creativity/single';
    type: string = 'Creativo';
    level: string = '';
    target: string = '';
    excercise: CreativeExercise = null;
    apiService: CognosApi = new CognosApi();
    isBtnLoading: boolean = false;
    isLoadingTask: boolean = false;
    isWarningVisible: boolean = false;

    get isButtonDisabled(): boolean {
      return this.level === '' || this.target === '';
    }

    get isSelecting(): boolean {
      return this.excercise === null;
    }

    public selectAgain(): void {
      this.isBtnLoading = true;

      setTimeout(() => {
        this.excercise = null;
        this.isBtnLoading = false;
      }, 1000);
    }

    @Emit('finishExercise')
    public finishExercise(): boolean {
      return true;
    }

    async getExcercise(level: string, target: string) {
      this.isLoadingTask = true;
      this.removeWarning();

      if (level === '' || target === '') {
        return;
      }

      try {
        const response = await this.apiService.get(this.path + '?level=' + level + '&target=' + target);
        setTimeout(() => {
          this.excercise = new CreativeExercise(response);
          this.isLoadingTask = false;
        }, 1000);
      } catch (error) {
        this.onRequestError();
      }
    }

    private onRequestError(): void {
      this.isWarningVisible = true;
      this.isLoadingTask = false;
    }

    private removeWarning(): void {
      this.isWarningVisible = false;
    }

    public getPosibleTargets(): string[] {
      return [
        'Poner en otro uso',
        'Metas a corto plazo',
        'Metas a largo plazo',
        'Riesgos',
        'Creatividad',
        'Pensamiento divergente',
        'Curiosidad',
        'Sustituir',
        'Combinar',
        'Adaptar',
        'Modificar',
        'Eliminar',
        'Lluvia de ideas',
        'Reordenar',
        'Alter ego',
      ];
    }

    public getPosibleLevels(): string[] {
      return ['Básico', 'Intermedio', 'Alto', 'Difícil', 'Muy difícil'];
    }
  }
</script>
<style>
  .w-25 {
    width: 25%;
  }

  .bold {
    font-weight: bold;
  }

  .tarea {
    font-size: 1.5rem;
  }
</style>
