<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="500"
      outlined
      shaped
    >
      <v-card-title>Nº de veces jugado</v-card-title>
      <v-card-subtitle>En los últimos 30 dias</v-card-subtitle>
      <base-pie-chart
        v-if="preparedData"
        :chartData="preparedData"
      />
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import BasePieChart from './Charts/BasePieChart.vue';

  @Component({
    components: {
      BasePieChart,
    },
  })
  export default class DashboardGameUsage extends Vue {
    @Prop({ default: () => [] }) readonly data!: any[];

    public preparedData: any = null;

    created() {
      this.setData();
    }

    setData() {
      const labels = this.data.map((item: any) => item.name);
      const totals = this.data.map((item: any) => item.total);
      const colors = this.data.map((item: any) => item.color);

      this.preparedData = {
        labels: [...labels],
        datasets: [
          {
            label: 'Juegos',
            data: totals,
            backgroundColor: colors,
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
    }
  }
</script>
