import OrderParams from '../Structures/OrderParams';
import Product from '../Structures/Product';

export default class OrderParamsService {
  public addProduct(order: OrderParams, product: Product) {
    order.products.push(product);
    order.total += product.price;
  }

  public removeProduct(order: OrderParams, product: Product) {
    const index = order.products.indexOf(product);
    order.products.splice(index, 1);
    order.total -= product.price;
  }
}
