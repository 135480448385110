<template>
  <v-main>
    <div>
      <default-navbar
        :isLogged="isLoggedUser"
        @logout="logout"
        :userInfo="userInfo"
        :isMobile="isMobileSize"
      >
        <div class="d-md-flex d-flex justify-space-between align-center">
          <div class="mr-2">
            <v-btn
              class="mr-2 dark"
              :color="'grey'"
              @click="pushTo('/login')"
              :text="true"
              :small="true"
            >
              Ingresar
            </v-btn>
          </div>
          <div class="mr-2">
            <v-btn
              class="mr-2 dark"
              :color="'grey'"
              @click="pushTo('/register')"
              :text="true"
              :small="true"
            >
              Registrarse
            </v-btn>
          </div>
        </div>
        <social-media class="d-none d-md-flex" />
      </default-navbar>
    </div>
  </v-main>
</template>

<script lang="ts">
  import DefaultNavbar from '../DefaultNavbar.vue';
  import UserModel from '../../Models/User/UserModel';
  import SocialMedia from '../Content/SocialMedia.vue';
  import Vue from 'vue';
  import { Component, Emit, Prop } from 'vue-property-decorator';
  import SessionService from '../Services/SessionService';

  //@ts-ignore
  @Component({
    components: {
      DefaultNavbar,
      SocialMedia,
    },
  })
  export default class Home extends Vue {
    @Prop({ required: true }) isLogged: boolean;
    @Prop({ required: true }) userInfo: UserModel;

    hasMobileSize: boolean = false;

    sessionService: SessionService = new SessionService();

    get userModel() {
      return this.userInfo;
    }

    get isMobileSize() {
      return this.hasMobileSize;
    }

    get isLoggedUser() {
      return this.isLogged;
    }

    async created() {
      window.addEventListener('resize', this.setSize);
      this.setSize();
    }

    pushTo(url: string): void {
      const currentUrl = this.$route.path;

      if (currentUrl === url) {
        return;
      }

      this.$router.push(url);
    }

    @Emit('logout')
    logout(): void {
      // this.isUserLogged = false;
       this.userInfo = null;
    }

    setSize(): void {
      this.hasMobileSize = window.innerWidth < 960;

      this.$store.commit('setIsMobile', this.hasMobileSize);
      this.$store.commit('setScreenSize', window.innerWidth);
    }
  }
</script>
