export default class EcuationLevelHelper {
  static getAmountOfEcuationByLevel(level: number): number {
    switch (level) {
      case 1:
        return 5;
      case 2:
        return 10;
      case 3:
        return 15;
      case 4:
        return 20;
      case 5:
        return 25;
      default:
        return 5;
    }
  }

  public static getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
