<template>
  <div class="w-100">
    <pro-subscription-default
      :subscription="subscription"
      :isMobile="isMobile"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Prop } from 'vue-property-decorator';
  import Component from 'vue-class-component';
  import Subscription from '../../Models/User/Subscription';
  import ProSubscriptionDefault from './ProSubscriptionDefault.vue';

  @Component({ components: { ProSubscriptionDefault } })
  export default class TheProSubscription extends Vue {
    @Prop({ required: true }) subscription: Subscription;
    @Prop({ required: true }) isMobile: boolean;
  }
</script>
