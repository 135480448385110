<template>
  <div>
    <v-card
      v-if="requestOrder"
      class="mx-auto rounded-lg"
      max-width="200"
      max-height="600"
      rounded
      tile
    >
      <v-card-title> Quiero pedir.. </v-card-title>

      <v-card-text>
        <ul>
          <li v-for="item in requestOrder.products">{{ item.quantity }} x {{ item.name }} </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <Timer
          v-if="initialTime > 0"
          class="ml-auto rounded-circle"
          :time="initialTime"
          @on-timeout="onTimeOut"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Emit, Prop } from 'vue-property-decorator';
  import GameParams from '../Structures/GameParams';
  import CustomerOrderService from '../Services/CustomerOrderService';
  import OrderParams from '../Structures/OrderParams';
  import OrderLevelResolvingService from '../Services/OrderLevelResolvingService';
  import Timer from './Timer.vue';
  import Order from '../Structures/Order';

  @Component({
    components: {
      Timer,
    },
  })
  export default class OrderRequest extends Vue {
    @Prop({ required: true }) gameParams: GameParams;

    customerOrderService: CustomerOrderService = new CustomerOrderService();
    orderLevelResolvingService: OrderLevelResolvingService = new OrderLevelResolvingService();

    requestOrder: OrderParams | null = null;
    initialTime: number = 0;

    created() {
      this.requestOrder = this.customerOrderService.genererateOrderParams(this.gameParams);
      this.initialTime = this.orderLevelResolvingService.getTimeToSeeOrderRequest(this.gameParams.level);
    }

    @Emit('on-timeout')
    onTimeOut() {
      this.initialTime = 0;

      const order = new Order();
      order.products = this.requestOrder.products;
      order.total = this.requestOrder.total;

      return order;
    }
  }
</script>
