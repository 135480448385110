<template>
  <div class="category">
    <div class="d-flex h-fit w-100">
      <custom-dialog
        class="ml-auto mt-4 mr-2"
        :is-visible="dialog"
        :button-text="dialogData.buttonText"
        :title="dialogData.title"
        :description="dialogData.description"
      />
    </div>
    <div class="d-flex w-100 h-fit mt-4">
      <v-btn
        v-if="!this.isGameStarted"
        elevation="2"
        text
        class="d-flex mb-15 mx-auto primary"
        @click.prevent="start()"
      >
        Comenzar!
      </v-btn>
      <custom-dialog-modal
        :isVisible="openModal"
        :title="'Antes de empezar!'"
        :description="dialogData.rotateScreenRequired"
      />
    </div>
    <div
      id="game"
      v-if="isGameStarted"
      class="w-100 h-fit mt-4"
    >
      <div
        :id="items.id"
        v-for="items in params"
        :key="items.id"
        class="d-flex w-100"
      >
        <v-chip-group class="mx-auto">
          <div
            :id="item"
            v-for="item in items.dataSet"
            :key="item.id"
            class="rounded"
          >
            <v-chip @click="validate(item, items.intruder, items.id)">{{ item }}</v-chip>
            <hr />
          </div>
        </v-chip-group>
      </div>
    </div>
    <div v-if="isWinner">
      <ExerciseWinnerModal
        :isVisible="isWinner"
        @callback="reload"
        @repeat="onRepeat"
        @close-modal="onRepeat"
      />
    </div>
    <div v-if="isLoser">
      <ExerciseLoserModal
        :isVisible="isLoser"
        @callback="reload"
        @repeat="onRepeat"
        @close-modal="onRepeat"
      />
    </div>
  </div>
</template>

<script>
  import CustomDialog from './Misc/CustomDialog.vue';
  import CustomDialogModal from './Misc/CustomDialogModal.vue';
  import { DIALOG_CATEGORY_DATA } from '../helpers/DialogHelper';
  import http from '@/helpers/https';
  import Global from '@/helpers/Global';
  import scrollToGame from '@/helpers/GameHelper';
  import ExerciseWinnerModal from './Misc/Exercises/Modals/ExerciseWinnerModal.vue';
  import ExerciseLoserModal from './Misc/Exercises/Modals/ExerciseLoserModal.vue';

  export default {
    name: 'Category',
    components: { CustomDialog, CustomDialogModal, ExerciseLoserModal, ExerciseWinnerModal },

    props: {
      isOnChallenge: {
        type: Boolean,
        required: false,
        default: false,
      },

      challengeLevel: {
        type: Number,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        dialogData: DIALOG_CATEGORY_DATA,
        dialog: false,
        isStarted: false,
        params: {},
        isWinner: false,
        checks: 0,
        assertions: 0,
        isLoser: false,
        hasMobileSize: false,
        size: 0,
        isRequiredChangeScreenOrientation: false,
        orientationScreen: '',
      };
    },

    computed: {
      /**
       * @return {boolean}
       */
      isGameStarted() {
        return this.isStarted;
      },

      /**
       * @return {boolean}
       */
      isMobile() {
        return this.hasMobileSize;
      },

      /**
       * @return {string}
       */
      screenOrientation() {
        return this.orientationScreen;
      },

      /**
       * @return {boolean}
       */
      openModal() {
        return this.isRequiredChangeScreenOrientation;
      },
    },

    async created() {
      if (this.isOnChallenge) {
        this.initChallenge();
      } else {
        await this.initComponent();
      }
      document.addEventListener('resize', this.setSize());
      this.validateScreen();
    },

    methods: {
      start() {
        if (!this.isGameStarted) {
          this.isStarted = true;
          scrollToGame();
        }
      },

      /**
       * @return {void}
       */
      async initComponent() {
        let actionGame = 'init_game';

        const request = http();
        request
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/CategoriesController.php', {
            action: actionGame,
            hashDev: Global.hashDev(),
          })
          .then(
            function (response) {
              if (response) {
                this.params = response.data;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error.response.data);
          });
      },

      /**
       * @param {string} item
       * @param {string} intruder
       * @param {string} dataSetId
       * @return {void}
       */
      validate(item, intruder, dataSetId) {
        this.checks++;
        setTimeout(() => {
          document.getElementById(dataSetId).remove();
        }, 400);

        if (item === intruder) {
          this.assertions++;
          document.getElementById(dataSetId).classList.add('success');
        } else {
          document.getElementById(dataSetId).classList.add('red');
        }

        let data = {
          game: 'Categorias',
          total: this.assertions,
          idNivelPregunta: 1,
        };

        if (this.assertions > 4) {
          if (this.isOnChallenge) {
            this.$emit('on-finish-challenge', data);
          }
          this.isWinner = true;
        }
        if (this.assertions < 5 && this.checks > 4) {
          if (this.isOnChallenge) {
            this.$emit('on-finish-challenge', data);
          }
          this.isLoser = true;
        }
      },

      /**
       * @param {boolean} needLastGame
       * @return {void}
       */
      sendResults(needLastGame = false) {
        if (needLastGame) {
          this.initComponent(needLastGame);
          return;
        }

        const results = http();

        const data = {
          total: this.assertions,
          idNivelPregunta: 1,
        };

        results
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/CategoriesController.php', {
            action: 'save_user_results',
            data: JSON.stringify(data),
            hashDev: Global.hashDev(),
          })
          .then(
            function () {
              this.initComponent();
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error.response.data);
          });
      },

      /**
       * @return {void}
       */
      reload() {
        this.sendResults();
        this.isWinner = false;
        this.isLoser = false;
        this.assertions = 0;
        this.isStarted = false;
        this.checks = 0;
      },

      /**
       * @return {void}
       */
      onRepeat() {
        this.isWinner = false;
        this.isLoser = false;
        this.assertions = 0;
        this.isStarted = false;
        this.checks = 0;
      },

      setSize() {
        this.hasMobileSize = window.innerWidth < 768;
      },

      handleOrientationChange() {
        if (this.screenOrientation === 'portrait-primary' && this.isMobile) {
          this.isRequiredChangeScreenOrientation = true;
        } else {
          this.orientationScreen = 'landscape-primary';
          this.isRequiredChangeScreenOrientation = false;
          scrollToGame();
        }
      },

      validateScreen() {
        let isPortrait = window.matchMedia('(orientation: portrait)');

        if (isPortrait.matches) {
          this.orientationScreen = 'portrait-primary';
          this.isRequiredChangeScreenOrientation = true;
          this.handleOrientationChange();
        }

        isPortrait.addListener((m) => {
          if (m.matches) {
            this.orientationScreen = 'portrait-primary';
            this.handleOrientationChange();
          } else {
            this.orientationScreen = 'landscape-primary';
            this.handleOrientationChange();
          }
        });
      },

      async initChallenge() {
        const request = http();
        request
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/CategoriesController.php', {
            action: 'init_challenge',
            levelId: this.challengeLevel,
            hashDev: Global.hashDev(),
          })
          .then(
            function (response) {
              if (response) {
                this.params = response.data;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error.response.data);
          });
      },
    },
  };
</script>

<style>
  .h-fit {
    height: fit-content;
  }

  .w-100 {
    width: 100%;
  }

  .h2 {
    font-size: 36px;
  }
</style>
