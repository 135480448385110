
class MemoryCard {
    readonly STATUS_SOLVED = 'solved';
    readonly STATUS_SELECTED = 'selected';
    readonly STATUS_DEFAULT = 'default';

    readonly STATE_HIDDEN = 'hidden';
    readonly STATE_FRONT = 'front';
    readonly STATE_MOVING = 'moving';

    id: string;
    state: string = this.STATE_HIDDEN;
    imageSource: string;
    status: string = this.STATUS_DEFAULT;
    width: number = 100;
    height: number = 100;
    class: string = '';
    frontSide: HTMLElement | null;
    backSide: HTMLElement | null;

    constructor (id: string, imageSource: string) {
        this.id = id;
        this.imageSource = imageSource;
    }

    public setState(state: string): void {
        this.state = state;
    }

    public setStatus(status: string): void {
        this.status = status;
    }

    isHidden() {
        return this.state === this.STATE_HIDDEN;
    }

    isOnAnimation() {
        return this.state = this.STATE_MOVING;
    }

    isSelected() {
        return this.status = this.STATUS_SELECTED;
    }

    setOnMovement() {
        this.state = this.STATE_MOVING;
    }

    setHidden() {
        this.setState(this.STATE_HIDDEN)

    }

    setFront() {
        this.setState(this.STATE_FRONT)
    }

    flipFromHidden() {
        this.setOnMovement()
        this.backSide?.classList.add('flipFromHidden');
        this.frontSide?.classList.add('flipToHidden');
        this.setFront()
    }

    flipToHidden() {
        this.setOnMovement();
        this.backSide?.classList.remove('flipFromHidden');
        this.frontSide?.classList.remove('flipToHidden');
        this.setHidden();
    }

    onSelect(side: string) {
        if (!this.frontSide || !this.backSide) {
            this.frontSide = document.getElementById(this.id + '_' + 'front');
            this.backSide = document.getElementById(this.id + '_' + 'back');
        }

        if (this.status !== this.STATUS_SOLVED) {
            switch (this.state) {
                case this.STATE_HIDDEN:
                    this.flipFromHidden();
                    break;
                case this.STATE_FRONT:
                    this.flipToHidden();
                    break;
                default:
                    break;
            }
        }
    }

    disableEvents() {
        this.frontSide?.classList.add('disable');
        this.backSide?.classList.add('disable');
    }
}
export default MemoryCard;