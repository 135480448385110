import axios, { AxiosInstance } from 'axios';

export const baseURL = process.env.NODE_ENV === 'production'
    ? '/app/system' : 'http://localhost/INTELECTO_APP/system';


export default function http(): AxiosInstance {

    const http = axios.create({
        baseURL: baseURL,
        timeout: 10000,
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Credentials': 'true',
            'Content-Type': ' application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, HEAD, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type'
        },

        transformRequest: [data => {
            return JSON.stringify(data);
        }]
    });

    return http;
}


