<template lang="">
  <div class="d-flex align-center">
    <div class="h3 no-wrap d-flex"
      >Cual es el valor de <p class="bold ml-2 mr-1">{{ unknownKey }} </p> ?
    </div>
    <div class="d-flex ml-5">
      <div v-for="data in values">
        <v-chip
          :key="data"
          class="ml-5 mb-4"
          :class="getChipColor(data)"
          text-color="white"
          @click="onSelectedValue(data)"
        >
          {{ data }}
        </v-chip>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class EcuationUnknownSelector extends Vue {
    @Prop({ default: 0 }) public values!: number[];
    @Prop({ required: true }) public unknownKey!: string;

    value: number | null = null;

    @Watch('values', { immediate: true })
    public onValuesChange(): void {
      this.value = null;
    }

    get selectedValue(): number | null {
      return this.value;
    }

    public getChipColor(value: number): string {
      return this.selectedValue === value ? 'green' : 'blue';
    }

    public onInput(): void {
      this.$emit('on-select', this.unknownKey, this.value);
    }

    public onSelectedValue(value: number): void {
      this.value = value;
      this.onInput();
    }
  }
</script>
