<template>
  <div>
    <v-card>
      <v-card-title>
        {{ subscriptionName }}
        <v-icon
          class="w-50 mx-auto my-auto"
          color="yellow darken-2"
          size="50"
        >
          mdi-crown
        </v-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-subheader> Información </v-subheader>

      <v-card-subtitle class="d-flex"
        ><h3 class="mr-2">Estado: </h3>
        <p class="text-center align-center">{{ 'Activa' }} </p>
      </v-card-subtitle>
      <v-card-text class="d-flex" v-html="expirationInformation"></v-card-text>

      <v-divider></v-divider>

      <v-card-text
        >{{ subscriptionDescription }}

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"> Ventaja </th>
                <th class="text-left"> Uso </th>
                <th class="text-left"> Incluido </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in features"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.usage }}</td>
                <td>
                  <v-icon color="yellow darken-1">{{ item.checkMark }}</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-btn
            class="w-25 mt-3"
            color="deep-purple lighten-2"
            text
            small
            @click="onClickDisable"
          >
            Desactivar suscripción
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <unsubscribe-modal
      :isMobile="isMobile"
      :isVisible="showUnsubscribeModal"
      :features="features"
      @close-modal="showUnsubscribeModal = false"
      @on-cancel-subscription="onCancelSubscription()"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Prop, Component } from 'vue-property-decorator';
  import Subscription from '../../Models/User/Subscription';
  import UnsubscribeModal from './UnsubscribeModal.vue';

  @Component({ components: { UnsubscribeModal } })
  export default class ProSubscriptionDefault extends Vue {
    @Prop({ required: true }) subscription: Subscription;
    @Prop({ required: true }) isMobile: Boolean;

    showUnsubscribeModal: boolean = false;

    headers: Array<any> = [
      { text: 'Ventaja', value: 'name' },
      { text: 'Acceso', value: 'usage' },
      { text: 'Incluido', value: 'checkMark' },
    ];

    features: Array<any> = [
      {
        name: 'Seguimiento personalizado',
        usage: 'Ilimitado',
        checkMark: 'mdi-check',
      },
      {
        name: 'Desafíos semanales',
        usage: 'Ilimitado',
        checkMark: 'mdi-check',
      },
      {
        name: 'Estadísticas cognitivas',
        usage: 'Ilimitado',
        checkMark: 'mdi-check',
      },
    ];

    get subscriptionName(): string {
      return this.subscription.name;
    }

    get subscriptionDescription(): string {
      return `Esto es todo lo que puedes disfrutar con ${this.subscriptionName}:`;
    }

    get expirationInformation(): string {
      return `La suscripción vence el: <p class="bold ml-2">${this.expirationDate()}</p>`;
    }

    expirationDate(): string {
      return this.subscription.expiresAt.split(' ')[0];
    }

    onClickDisable(): void {
      this.showUnsubscribeModal = true;
    }

    onCancelSubscription(): void {
      this.showUnsubscribeModal = false;

      console.log('Has cancelado');
    }
  }
</script>
