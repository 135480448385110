<template>
  <div class="d-flex w-100 justify-center">
    <v-card>
      <v-card-title class="text-center">
        <h3 class="h5">Los camareros</h3>
      </v-card-title>

      <v-img
        src="../../../../components/img/waiter.svg"
        max-width="200"
        max-height="200"
        class="mx-auto"
      ></v-img>

      <v-select
        v-model="level"
        :items="levels"
        label="Nivel"
        outlined
        dense
        class="mt-3"
      ></v-select>

      <v-btn
        :disabled="buttonDisabled"
        :loading="buttonDisabled"
        @click="onStartGame"
        class="mt-3"
        color="primary"
        block
        >Iniciar
      </v-btn>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({})
  export default class WaiterLevelSelector extends Vue {
    levels: number[] = [1, 2, 3];
    level: number = 1;

    buttonDisabled: boolean = false;

    onStartGame() {
      this.$emit('on-start-game', this.level);

      this.buttonDisabled = true;

      setTimeout(() => {
        this.buttonDisabled = false;
      }, 2000);
    }
  }
</script>
