export default class MessageHelper {
  static getResultMessage(percentageAssertion: number): string {
    let extraMessage = '';

    if (percentageAssertion >= 0 && percentageAssertion <= 20) {
      extraMessage = '¡Sigue intentando!';
    } else if (percentageAssertion > 20 && percentageAssertion <= 40) {
      extraMessage = '¡Vamos, tú puedes!';
    } else if (percentageAssertion > 40 && percentageAssertion <= 60) {
      extraMessage = '¡Bastante bien!';
    } else if (percentageAssertion > 60 && percentageAssertion <= 80) {
      extraMessage = '¡Muy bien!';
    } else if (percentageAssertion > 80 && percentageAssertion <= 100) {
      extraMessage = '¡Excelente!';
    }

    return extraMessage;
  }
}
