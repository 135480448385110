<template>
  <div>
    <WaiterLevelSelector @on-start-game="startGame" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import WaiterLevelSelector from './WaiterLevelSelector.vue';
  import { Emit } from 'vue-property-decorator';

  @Component({
    components: {
      WaiterLevelSelector,
    },
  })
  export default class WaiterDefault extends Vue {
    @Emit('on-start-game')
    public startGame(level: number) {
      return level;
    }
  }
</script>
