export default class ResponseAnimatorService {
  public markWrongSelected(selected: string) {
    const wrongId = `id-${selected}`;
    const wrongElement = document.getElementById(wrongId);
    wrongElement.classList.add('border-danger');

    setTimeout(() => {
      wrongElement.classList.remove('border-danger');
    }, 1300);
  }

  public displayCorrectAnswer(correct: string): void {
    const correctId = `id-${correct}`;
    const correctElement = document.getElementById(correctId);
    correctElement.classList.add('border-success');

    setTimeout(() => {
      correctElement.classList.remove('border-success');
    }, 1300);

    this.enchanceElement(correctElement);
  }

  private enchanceElement(element: HTMLElement): void {
    element.classList.add('enchance');
  }
}
