export default class ScaleColorService {
    // hex colors from red to green
    private _colors: string[] = [
        '#FF0000',
        '#FF3300',
        '#FF6600',
        '#FF9900',
        '#FFCC00',
        '#FFFF00',
        '#CCFF00',
        '#99FF00',
        '#66FF00',
        '#33FF00',
        '#00FF00'
    ];

    private max_value: number = 100;

    public getColor(value: number): string {
        if (value > this.max_value) {
            value = this.max_value;
        }

        const index = Math.round(value / 10);

        return this._colors[index];
    }

    public getRgbaColor(value: number): string {

        const color = this.getColor(value);

        return this.hexToRgbA(color);
    }

    public getScaleColors(values: number[]): string[] {
        const colors: string[] = [];

        for (let i = 0; i < values.length; i++) {

            const value = values[i];
            colors.push(this.getRgbaColor(value));
        }

        return colors;
    }

    private hexToRgbA(hex: string): string {
        let c;

        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            // @ts-ignore
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', 0.5)';
        }
        throw new Error('Bad Hex');
    }

    public buildBorderColorsFullOpacity(values: number[]): string[] {
        const colors: string[] = [];

        for (let i = 0; i < values.length; i++) {
            const value = values[i];
            let color = this.getRgbaColor(value);
            color = color.replace('0.5', '1');
            colors.push(color);
        }

        return colors;
    }
}