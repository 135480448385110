<template>
  <div class="text-center">
    <v-dialog :persistent="true" v-model="isVisible" width="500">
      <v-card>
        <v-btn icon large @click="$emit('close-modal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h5 grey lighten-2">
          <slot></slot>
          {{ title }}
        </v-card-title>
        <br />
        <v-card-title class="text-center text-break" v-if="subtitle">{{ subtitle }}</v-card-title>
        <v-card-text v-if="!subtitle" v-html="description"> </v-card-text>

        <div
          v-if="showButtonLoader"
          class="d-flex justify-center justify-lg-end"
        >
          <div class="mr-2">
            <v-btn
              :loading="loading3"
              :disabled="loading3"
              color="blue-grey"
              class="ma-2 white--text mr-auto"
              @click="nextLevel()"
            >
              Siguiente nivel
              <v-icon right dark> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'CustomDialogModal',

    props: {
      title: {
        type: String,
        required: true,
        default: '',
      },

      description: {
        type: String,
        required: false,
        default: '',
      },

      isVisible: {
        type: Boolean,
        required: true,
        default: false,
      },

      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },

      showButtonLoader: {
        type: Boolean,
        required: false,
        default: false,
      },

      subtitle: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        visible: this.isVisible,
        loader: null,
        loading: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },

    methods: {
      nextLevel() {
        this.loader = 'loading3';

        setTimeout(() => {
          this.visible = false;
          this.$emit('reload');
        }, 3000);
      },
    },
  };
</script>
