<template>
  <div class="container">
    <div class="text-center w-100 mt-2 mb-4">
      <h1 class="mx-auto">Bienvenido a Cognos!</h1>
    </div>
    <div
      v-if="isLoaded"
      class="container"
    >
      <bar-chart
        class="mx-auto chart"
        :chartData="weeklyChartData"
        :chartOptions="weeklyChartData.options"
      />
    </div>
    <div
      class="container col-4"
      v-if="isLoaded"
    >
      <v-banner
        color="yellow darken-2"
        elevation="20"
        icon="mdi-progress-star"
        outlined
        shaped
      >
        <h3>Avance en los últimos 30 dias</h3>
      </v-banner>

      <custom-line-chart
        v-if="memoryColourChartData"
        :id="'Memoria'"
        :data="memoryColourChartData"
      />
      <custom-line-chart
        v-if="signosChartData"
        :id="'Signos'"
        :data="signosChartData"
      />

      <custom-line-chart
        v-if="smilerChartData"
        :id="'Smiler'"
        :data="smilerChartData"
      />
      <custom-line-chart
        v-if="questionChartData"
        :id="'Preguntas'"
        :data="questionChartData"
      />

      <custom-line-chart
        v-if="categoryChartData"
        :id="'Categorias'"
        :data="categoryChartData"
      />
      <custom-line-chart
        v-if="challengeChartData"
        :id="'Challenges'"
        :data="smilerChartData"
      />
      <custom-line-chart
        v-if="wordsChartData"
        :id="'Letras'"
        :data="wordsChartData"
      />
      <hr class="my-5" />

      <DashboardPerformance
        v-if="performanceData"
        :performanceData="performanceData"
        class="chart mt-2"
      />

      <DashboardEfectiveness
        v-if="efectivenessData"
        :efectivenessData="efectivenessData"
        class="chart mt-2"
      />
    </div>

    <div>
      <DashboardGameUsage
        v-if="usageData"
        :data="usageData"
        class="chart mt-2 mx-auto"
      />
      <DashboardGlobalGameUsage
        v-if="usageData"
        :data="usageData"
        class="chart mt-2 mx-auto"
      />
    </div>
    <div v-if="isEmpty">
      <DashboardEmpty />

      <div class="text-center mt-5">
        <v-btn
          color="primary"
          class="mx-auto"
          @click="$router.push('/games')"
        >
          Comienza a jugar!
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import CustomLineChart from './Charts/CustomLineChart.vue';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import DashboardStatisticsService from './Services/DashboardStatisticsService';
  import BarChart from './Charts/BarChart.vue';
  import ChartDataStructure from './Charts/Structures/ChartDataStructure';
  import DashboardPerformance from './DashboardPerformance.vue';
  import DashboardEmpty from './DashboardEmpty.vue';
  import DashboardGameUsage from './DashboardGameUsage.vue';
  import DashboardEfectiveness from './DashboardEfectiveness.vue';
  import GamePerformanceStructure from '../UserPerformance/Structure/GamePerformanceStructure';
  import DashboardGlobalGameUsage from './DashboardGlobalGameUsage.vue';

  @Component({
    components: {
      BarChart,
      CustomLineChart,
      DashboardPerformance,
      DashboardEmpty,
      DashboardGameUsage,
      DashboardEfectiveness,
      DashboardGlobalGameUsage,
    },
  })
  export default class DashboardDefault extends Vue {
    service: DashboardStatisticsService;
    weeklyData = {
      labels: [],
      datasets: [],
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Resumen semanal',
            font: {
              size: 36,
            },
          },
          legend: {
            title: {
              display: false,
            },
            display: false,
          },
        },
      },
    };

    memoryColourChartData?: ChartDataStructure = null;
    signosChartData?: ChartDataStructure = null;
    wordsChartData?: ChartDataStructure = null;
    smilerChartData?: ChartDataStructure = null;
    questionChartData?: ChartDataStructure = null;
    categoryChartData?: ChartDataStructure = null;
    challengeChartData?: ChartDataStructure = null;
    performanceData?: GamePerformanceStructure = null;
    usageData?: any = null;
    efectivenessData?: number = null;

    mappedWeekDays = {
      Monday: 'Lunes',
      Tuesday: 'Martes',
      Wednesday: 'Miercoles',
      Thursday: 'Jueves',
      Friday: 'Viernes',
      Saturday: 'Sabado',
      Sunday: 'Domingo',
    };

    mappedGameColors = {
      MEMORY_COLOUR: { color: '#f87979', displayName: 'Memoria' },
      SIGNOS: { color: 'blue', displayName: 'Signos' },
      SMILER: { color: 'orange', displayName: 'Sonrisas' },
      WORDS: { color: 'yellow', displayName: 'Letras' },
      CHALLENGE: { color: 'gray', displayName: 'Challenge' },
      CATEGORIAS: { color: 'green', displayName: 'Categorias' },
      PREGUNTAS: { color: 'skyblue', displayName: 'Preguntas' },
    };

    isWeeklyLoaded = false;
    isMonthlyLoaded = false;
    isUsageDataLoaded = false;
    isEmpty = false;

    get weeklyChartData() {
      return this.weeklyData;
    }

    get isLoaded() {
      return this.isWeeklyLoaded;
    }

    get userId() {
      return this.$store.getters?.getUserId;
    }

    constructor() {
      super();
      this.service = new DashboardStatisticsService();
    }

    async created() {
      await this.setWeeklyData();
      await this.setUsageData();

      this.memoryColourChartData = await this.setChartByGameId(1, 'Memoria');
      this.signosChartData = await this.setChartByGameId(2, 'Signos');
      this.smilerChartData = await this.setChartByGameId(3, 'Sonrisas');
      this.questionChartData = await this.setChartByGameId(4, 'Preguntas');
      this.categoryChartData = await this.setChartByGameId(5, 'Categorias');
      this.challengeChartData = await this.setChartByGameId(6, 'Challenges');
      this.wordsChartData = await this.setChartByGameId(7, 'Letras');

      this.performanceData = await this.service.getPerformanceData(this.userId);
      this.efectivenessData = Math.round(
        (this.performanceData.averageCorrectAnswersPercentage /
          (this.performanceData.averageCorrectAnswersPercentage +
            this.performanceData.averageWrongAnswersPercentage +
            this.performanceData.averageSkippedAnswersPercentage)) *
          100
      );
    }

    async setUsageData() {
      this.usageData = await this.service.getUsageData();

      if (this.usageData.length === 0) {
        this.isEmpty = true;

        return;
      }

      if (this.usageData.length > 0) {
        this.isUsageDataLoaded = true;
      }
    }

    async setWeeklyData() {
      let weeklyData = await this.service.getWeeklyGameResults();

      weeklyData.forEach((el) => {
        this.weeklyData.datasets.push(this.buildDataset(el));
      });

      if (weeklyData.length > 0) {
        this.isWeeklyLoaded = true;
      }
    }

    private buildDataset(el: any) {
      if (!el || !el.gameName) {
        return;
      }

      let formattedDayName = this.getTranslatedWeekDay(el.weekDay) + ' ' + el.day;

      return {
        label: this.getGameDisplayName(el.gameName),
        backgroundColor: this.getBackgroundColor(el.gameName),
        data: [{ x: formattedDayName, y: el.total }],
        options: {
          parsing: false,
        },
      };
    }

    private buildLineDataset(el: any) {
      return {
        label: this.getGameDisplayName(el.gameName),
        backgroundColor: this.getBackgroundColor(el.gameName),
        borderColor: 'blue',
        data: [],
      };
    }

    async setChartByGameId(id: number, chartTitle: string): Promise<ChartDataStructure> {
      let monthlyData = await this.service.getMonthlySingleGameResults(id);

      if (monthlyData.length === 0) {
        return;
      }

      let data = [];

      monthlyData.forEach((element) => {
        data.push(element.total);
      });

      let monthlyStructure = new ChartDataStructure();

      monthlyStructure.setText(chartTitle);
      monthlyStructure.datasets.push(this.buildLineDataset(monthlyData[0]));
      monthlyStructure.datasets[0].data = data;
      monthlyData.forEach((el) => {
        monthlyStructure.labels.push(this.getTranslatedWeekDay(el.weekDay) + ' ' + el.day);
      });

      return monthlyStructure;
    }

    getBackgroundColor(gameName: string): string {
      return this.mappedGameColors[gameName].color;
    }

    getGameDisplayName(gameName: string): string {
      return this.mappedGameColors[gameName].displayName;
    }

    getTranslatedWeekDay(weedDay: string): string {
      return this.mappedWeekDays[weedDay];
    }
  }
</script>
<style>
  .chart {
    max-width: 400px;
  }
</style>
