import GamePerformanceStructure from '../../UserPerformance/Structure/GamePerformanceStructure';
import Utils from '../../../core/Utils/Utils';

export default class PerformancePreparatorService {

    public prepareData(data: any): GamePerformanceStructure | null {

        if (data.length === 0) {
            return null;
        }

        let parsedData = Utils.fromObjectSnakeToCamel(data);
        let preparedData: GamePerformanceStructure = new GamePerformanceStructure();

        preparedData.gameId = parsedData.gameId;
        preparedData.averageScore = parsedData.averageScore;
        preparedData.averageTime = parsedData.averageTime;
        preparedData.averageAttempts = parsedData.averageAttempts;
        preparedData.averageCorrectAnswers = parsedData.averageCorrectAnswers;
        preparedData.averageWrongAnswers = parsedData.averageWrongAnswers;
        preparedData.averageSkippedAnswers = parsedData.averageSkippedAnswers;
        preparedData.averageCorrectAnswersPercentage = parsedData.averageCorrectAnswersPercentage;
        preparedData.averageWrongAnswersPercentage = parsedData.averageWrongAnswersPercentage;
        preparedData.averageSkippedAnswersPercentage = parsedData.averageSkippedAnswersPercentage;
        preparedData.attempts = JSON.parse(parsedData.attempts ?? '[]');


        return preparedData;
    }

    public getResponseTimeColor(responseTime: number): string {
        const maxResponseTime = 2000;
        const minResponseTime = 200;

        if (responseTime <= minResponseTime) {
            return 'green';
        }

        if (responseTime > minResponseTime && responseTime <= maxResponseTime) {
            return 'yellow';
        }

        return 'red';
    }
}