class QuestionItem {
    idActividadPregunta: string;
    question: string;
    optionOne: string;
    optionTwo: string;
    optionThree: string;
    optionFour: string;
    rightAnswer: string;

    constructor (params: {
        id_actividad_pregunta: string,
        pregunta: string,
        opcion1: string,
        opcion2: string,
        opcion3: string,
        opcion4: string,
        respuesta: string
    }) {
        this.idActividadPregunta = params.id_actividad_pregunta;
        this.question = params.pregunta;
        this.optionOne = params.opcion1;
        this.optionTwo = params.opcion2;
        this.optionThree = params.opcion3;
        this.optionFour = params.opcion4;
        this.rightAnswer = params.respuesta;
    }

    getRightAnswer(): string {
        return this.rightAnswer;
    }
}
export default QuestionItem;