<template lang="">
  <div class="d-flex">
    <v-carousel
      class="mx-auto my-auto w-75"
      :continuous="false"
      :cycle="false"
      :show-arrows="true"
      delimiter-icon="mdi-minus"
      hide-delimiters
      height="auto"
      width="50"
    >
      <v-carousel-item
        v-for="(level, i) in levels"
        :key="i"
      >
        <v-card :color="level.backgroundColor" class="carousel">
          <v-card-title>{{ level.title }}</v-card-title>

          <v-card-subtitle class="bold"> <h4 class="font-weight-bold">Descripción</h4></v-card-subtitle>
          <v-card-subtitle class="description">{{ level.description }}</v-card-subtitle>

          <v-card-subtitle class="bold"> <h4 class="font-weight-bold">Reglas</h4></v-card-subtitle>
          <v-card-text class="rulesHeight">
            {{ level.rules }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="white"
              class="mx-auto mb-5"
              @click="onSelectLevel(level.id)"
            >
              Jugar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import EcuationEnum from '../EcuationEnum';

  @Component
  export default class EcuationLevelSelector extends Vue {
    public levels: Array<any> = EcuationEnum.LEVELS;

    public onSelectLevel(level: any): void {
      this.$emit('on-select-level', level);
    }
  }
</script>
<style scoped>
  .rulesHeight {
    min-height: 80px;
  }
  .description {
    min-height: 50px;
  }

  .carousel {
    min-height: 350px;
  }
</style>
