<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="500"
      outlined
      tile
    >
      <v-card-title>
        <v-icon>mdi-flag</v-icon>
        <span class="mx-2">{{ params.question }}</span>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-radio-group
          v-model="selectedCountry"
          column
          class="mx-auto"
        >
          <div
            v-if="isRegionMode || isCapitalMode"
            v-for="country in countryNames"
            class="my-1"
          >
            <v-radio
              :key="country"
              :label="country"
              :value="country"
            >
              <template v-slot:label>
                <span
                  :id="'id-' + country"
                  class="mx-2"
                  >{{ country }}</span
                >
              </template>
            </v-radio>
          </div>

          <v-radio
            v-if="isFlagMode"
            v-for="flag in countryFlags"
            :key="flag"
            :value="flag"
            height="30"
            width="30"
          >
            <template v-slot:label>
              <v-img
                :id="'id-' + flag"
                class="border rounded"
                :src="flag"
                height="50"
                width="80"
                max-width="80"
              ></v-img>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!selectedCountry"
          text
          @click.prevent="checkAnswer"
        >
          Comprobar
        </v-btn>

        <v-spacer></v-spacer>

        <v-icon
          v-if="showIcon"
          x-large
          :color="checkedCountry === params.correctAnswer ? 'green' : 'red'"
        >
          {{ icon }}
        </v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import CountryGameParams from '../Models/CountryGameParams';
  import Utils from '@/core/Utils/Utils';
  import ResponseAnimatorService from '../Services/ResponseAnimatorService';

  @Component
  export default class CountryGame extends Vue {
    @Prop({ required: true }) params: CountryGameParams;

    // Services
    private responseAnimatorService = new ResponseAnimatorService();

    public selectedCountry: string = null;
    public showIcon: boolean = false;

    public mode: string = '';

    created() {
      this.mode = this.params.mode;
    }

    // Mode computed properties

    get isFlagMode(): boolean {
      return this.mode === 'Banderas';
    }

    get isRegionMode(): boolean {
      return this.mode === 'Regiones';
    }

    get isCapitalMode(): boolean {
      return this.mode === 'Capitales';
    }

    // End mode computed properties

    get checkedCountry(): string {
      return this.selectedCountry;
    }

    get icon(): string {
      return this.getIcon(this.checkedCountry === this.params.correctAnswer);
    }

    get countryNames(): string[] {
      return Utils.shuffleArray(this.params.countries.map((country) => country.name));
    }

    get countryFlags(): string[] {
      return Utils.shuffleArray(this.params.countries.map((country) => country.flag));
    }

    // Methods

    getIcon(isCorrect: boolean): string {
      return isCorrect ? 'mdi-check' : 'mdi-close';
    }

    checkAnswer(): void {
      const isCorrect = this.params.correctAnswer === this.selectedCountry;
      this.showIcon = true;

      if (!isCorrect) {
        this.responseAnimatorService.markWrongSelected(this.selectedCountry);
      }

      this.responseAnimatorService.displayCorrectAnswer(this.params.correctAnswer);

      setTimeout(() => {
        this.showIcon = false;
        this.selectedCountry = null;
        this.$emit('answer', isCorrect);
      }, 1300);
    }
  }
</script>

<style scoped>
  .border {
    border: 1px solid black;
  }

  .border-success {
    border: 5px solid green;
    padding: 2px;
  }

  .border-danger {
    border: 5px solid red;
  }

  .enchance {
    animation-name: enchance;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  @keyframes enchance {
    0% {
      transform: scale(1);
      transform: translateX(0px);
    }

    50% {
      transform: scale(1.5);
      transform: translateX(50px);
    }

    100% {
      transform: scale(2);
      transform: translateX(100px);
    }
  }
</style>
