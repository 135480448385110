<template lang="">
  <div>
    <Pie v-if="data" :chartData="data" :options="options" />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';
  import { Pie } from 'vue-chartjs/legacy';
  import ScaleColorService from '../Services/ScaleColorService';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    ArcElement,
  } from 'chart.js';

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    ArcElement
  );

  @Component({
    components: {
      Pie,
    },
  })
  export default class PieChart extends Vue {
    @Prop({ required: true }) chartData!: any;

    scaleColorService: ScaleColorService;

    constructor() {
      super();
      this.scaleColorService = new ScaleColorService();
    }

    data: any = null;

    options: any = null;

    mounted() {
      this.setBackgroundColors();
      this.setData();
    }

    created() {
      this.data = {
        labels: ['Aciertos', 'Errores'],
        datasets: [
          {
            label: '%',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
      this.options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],

          max: 20,
        },

        plugins: {
          legend: {
            display: true,
            labels: {
              formatter: function (value, ctx) {
                return value + '%';
              },
            },
          },
        },
      };
    }

    private setData(): void {
      const total  = this.chartData.averageCorrectAnswersPercentage + this.chartData.averageWrongAnswersPercentage;
      const correctPercentage = (this.chartData.averageCorrectAnswersPercentage / total) * 100;
      const wrongPercentage = (this.chartData.averageWrongAnswersPercentage / total) * 100;

      this.data.datasets[0].data = [correctPercentage, wrongPercentage];
    }

    private setBackgroundColors(): void {
      const values = [
        this.chartData.averageCorrectAnswersPercentage,
        this.chartData.averageWrongAnswersPercentage,
      ];

      this.data.datasets[0].backgroundColor = [ '#00ff00', '#ff0000'];
      this.data.datasets[0].borderColor = ['#00ff00', '#ff0000']
    }
  }
</script>
