<template>
  <div>
    <Pie
      v-if="chartData"
      :chartData="chartData"
      :options="options"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { Pie } from 'vue-chartjs/legacy';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    ArcElement,
  } from 'chart.js';

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    ArcElement
  );

  @Component({
    components: {
      Pie,
    },
  })
  export default class BasePieChart extends Vue {
    @Prop({ required: true }) chartData!: any;
    options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],

        max: 20,
      },

      plugins: {
        legend: {
          display: true,
          labels: {
            formatter: function (value, ctx) {
              return value + '%';
            },
          },
        },
      },
    };
  }
</script>
