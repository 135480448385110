<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="350"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span class="headline mb-5">Resultados</span>
        </v-card-title>

        <v-card-subtitle>
          {{ message }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="subtitle"
            >
              {{ extraMessage }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeModal"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import MessageHelper from '../../Common/Helpers/MessageHelper';

  @Component
  export default class EcuationGameResultModal extends Vue {
    @Prop({ required: true }) showModal: boolean;
    @Prop({ required: true }) correctAnswers: number;
    @Prop({ required: true }) totalAnswers: number;

    message: string = '';
    extraMessage: string = '';

    closeModal() {
      this.$emit('close-modal');
    }

    created() {
      this.buildMessage();
    }

    private buildMessage(): void {
      const percentage = (this.correctAnswers / this.totalAnswers) * 100;
      const roundedPercentage = Math.round(percentage * 100) / 100;
      this.message = `Has acertado ${this.correctAnswers} de ${this.totalAnswers} (${roundedPercentage}%)`;
      this.extraMessage = MessageHelper.getResultMessage(roundedPercentage);
    }
  }
</script>
