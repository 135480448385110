<template>
  <div>
    <v-img
      src="../assets/img/waiter_lobby_2.svg"
      class="mx-auto d-flex"
      max-width="900"
      max-height="600"
    ></v-img>
    <OrderRequest
      v-if="canSeeOrderRequest"
      class="order"
      :gameParams="gameParams"
      @on-timeout="onTimeOut"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import GameParams from '../Structures/GameParams';
  import OrderRequest from './OrderRequest.vue';
  import Order from '../Structures/Order';

  @Component({
    components: {
      OrderRequest,
    },
  })
  export default class WaiterLobby extends Vue {
    @Prop({ required: true }) gameParams: GameParams;

    canSeeOrderRequest: boolean = true;

    onTimeOut(customerOrder: Order) {
      this.canSeeOrderRequest = false;

      this.$emit('onTimeOut', customerOrder);
    }
  }
</script>
<style>
  .order {
    position: absolute;
    top: /*middle of the screen*/ 22%;
    left: 34%;
  }
</style>
