<template>
  <div>
    <v-container fluid>
      <v-data-iterator :items="params" :items-per-page.sync="itemsPerPage" hide-default-footer>
        <template v-slot:header>
          <v-toolbar class="mb-2" color="indigo darken-5" dark flat>
            <v-toolbar-title>Resultados</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4" lg="3">
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  {{ item.name }}
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Total:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.total }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-toolbar class="mt-2" color="indigo" dark flat>
            <v-toolbar-title class="subheading">Bien hecho! </v-toolbar-title>
            <div class="mx-auto">
              <v-btn color="green" @click="onFinishReview()">Siguiente nivel</v-btn>
            </div>
          </v-toolbar>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'TableCustom',

    props: {
      params: {
        type: Array,
        required: true,
        default: () => [],
      },
    },

    data: () => ({
      itemsPerPage: 4,
      items: [],
    }),

    created() {
      this.items = this.params;
    },

    methods: {
      onFinishReview() {
        this.$emit('on-finish-review');
      },
    },
  };
</script>
