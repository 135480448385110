import FeatureFlag from '../FeatureFlags/FeatureFlag';
import UserRoles from './UserRoles';

export default class UserPermissionsService {
  private static readonly GUEST_PERMISSIONS: string[] = [
    FeatureFlag.FF_CAN_SEE_GAME_LIST,
    FeatureFlag.FF_CAN_SEE_MEMORY_GAME,
    FeatureFlag.FF_CAN_SEE_MEMORY_GAME_LEVEL_1,
    FeatureFlag.FF_CAN_SEE_LETRAS_GAME,
    FeatureFlag.FF_CAN_SEE_LETRAS_GAME_LEVEL_1,
    FeatureFlag.FF_CAN_SEE_SONRISAS_GAME,
    FeatureFlag.FF_CAN_SEE_SONRISAS_GAME_LEVEL_1,
  ];

  private static readonly LOGGED_USER_PERMISSIONS: string[] = [
    ...UserPermissionsService.GUEST_PERMISSIONS,
    FeatureFlag.FF_CAN_USE_CATEGORY_GAME,
    FeatureFlag.FF_CAN_USE_PREGUNTAS_GAME,
    FeatureFlag.FF_CAN_USE_MATRIX_GAME,
    FeatureFlag.FF_CAN_USE_SIGNOS_GAME,
    FeatureFlag.FF_CAN_USE_CHALLENGE_GAME,
  ];

  private static readonly SUSCRIBED_USER_PERMISSIONS: string[] = [
    ...UserPermissionsService.LOGGED_USER_PERMISSIONS,
    FeatureFlag.FF_CAN_USE_CREATIVITY_GAME,
    FeatureFlag.FF_CAN_USE_DUPLICADOS_GAME,
  ];

  private static readonly PERMISSIONS: any = {
    [UserRoles.GUEST]: UserPermissionsService.GUEST_PERMISSIONS,

    [UserRoles.LOGGED_USER]: [...UserPermissionsService.LOGGED_USER_PERMISSIONS],

    [UserRoles.SUSCRIBED_USER]: [...UserPermissionsService.SUSCRIBED_USER_PERMISSIONS],
  };

  public static getPermissions(role: string): string[] {
    return UserPermissionsService.PERMISSIONS[role] ?? [];
  }
}
