<template>
  <div class="d-flex align-center ml-5">
    <v-icon large :color="color" class="mx-auto"
      >mdi-trophy-outline
    </v-icon>
    <v-chip color="gray " class="text--darken-2">
      {{ this.level }}
    </v-chip>
  </div>
</template>

<script>
  export default {
    name: "TrophyItem",

    props: {
      level: {
        type: Number,
        required: true,
        default: null,
      },

      color: {
        type: String,
        required: true,
        default: "",
      },
    },
  };
</script>