<template>
  <div class="d-flex flex-wrap">
    <v-app-bar elevation="4" outlined prominent rounded shaped height="auto">
      <v-container grid-list-xs>
        <div class="d-flex w-100 rounded">
          <div class="d-md-inline-flex d-flex justify-end w-100">
            <div class="d-flex w-100">
              <div class="d-flex mr-md-auto mx-md-0 align-center">
                <logo />
              </div>
            </div>
            <div v-if="!isLogged" class="d-flex justify-end mt-4">
              <slot></slot>
            </div>
            <div class="d-none d-md-flex">
              <button-loader
                v-if="isLogged"
                class="ml-auto mr-4 mt-1"
                :buttonText="'Cerrar sesión'"
                :animationTime="2000"
                @event="logout()"
              />
            </div>
            <div class="d-flex d-md-none">
              <button-loader
                v-if="isLogged"
                class="ml-auto mt-1"
                :buttonText="'Salir'"
                :animationTime="2000"
                @event="logout()"
              />
            </div>
            <div class="d-none d-md-flex mt-1">
              <div v-if="isLogged && userData" class="d-flex">
                <h5 class="ml-auto mr-2">Bienvenido, {{ userData.userName }}</h5
                ><!-- Reemplazar por User name -->
              </div>
            </div>
          </div>
          <div class="ml-4">
            <p class="text-lg-left" v-if="!typeof group == 'undefined'">{{
              actualGame
            }}</p>
          </div>
        </div>
        <div class="d-flex mt-5">
          <v-tabs
            class="justify-center d-none d-md-flex"
            slider-size="3"
            align-with-title
            grow
            centered
            show-arrows
          >
            <v-tab
              v-if="
                (item.mustBeLogged && isLogged) ||
                (item.mustBeUnLogged && !isLogged) ||
                (!item.mustBeLogged && !item.mustBeUnLogged)
              "
              v-for="item in items"
              :key="item.id"
              :ref="item.view"
              :to="item.view"
            >
              {{ item.key }}
            </v-tab>
          </v-tabs>

          <v-tabs
            id="sliderMobile"
            ref="sliderMobile"
            class="d-flex d-md-none w-100 justify-center"
          >
            <v-tab
              v-if="
                (mobileItem.mustBeLogged && isLogged) ||
                (mobileItem.mustBeUnLogged && !isLogged) ||
                (!mobileItem.mustBeLogged && !mobileItem.mustBeUnLogged)
              "
              v-for="mobileItem in mobileItems"
              :key="mobileItem.id"
              :to="mobileItem.view"
              class="d-flex"
              style="max-width: 90px"
              @click="setTab(mobileItem.id)"
            >
              <v-icon>{{ mobileItem.icon }}</v-icon>
            </v-tab>
          </v-tabs>
        </div>
      </v-container>
    </v-app-bar>

    <div class="d-flex justify-center align-center bg-blur bg-white w-100">
      <router-view />
    </div>
    <div class="d-flex w-100" v-if="tab === 7 && isMobile">
      <v-card class="w-100 justify-center">
        <v-list class="w-75 mx-auto">
          <v-list-item
            v-for="item in mobileExtraItems"
            :key="item.id"
            @click="setTab(item.id)"
          >
            <div class="d-flex mx-auto justify-center align-center">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-text style="min-width: 100px">{{ item.key }}</v-text>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>
<script>
  import ButtonLoader from './Misc/ButtonLoader.vue';
  import UserModel from '../Models/User/UserModel';
  import DefaultContent from './Content/DefaultContent.vue';
  import Logo from './Content/Logo.vue';
  import MainGoalMarketing from './Content/MainGoalMarketing.vue';
  import ContentGameDefault from './Content/ContentGameDefault.vue';
  import StoreDefault from './Store/StoreDefault.vue';
  import DashboardDefault from './Dashboard/DashboardDefault.vue';
  export default {
    components: {
      ButtonLoader,
      DefaultContent,
      Logo,
      MainGoalMarketing,
      ContentGameDefault,
      StoreDefault,
      DashboardDefault,
    },
    name: 'DefaultNavbar',
    event: 'logout',

    props: {
      isLogged: {
        type: Boolean,
        required: true,
      },

      userInfo: {
        type: UserModel,
        required: false,
        default: null,
      },

      isMobile: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        container: '',
        drawer: false,
        group: null,
        games: [
          { id: 1, name: 'Memoria', img: require('./img/memoriaIcon.png') },
          { id: 2, name: 'Signos', img: require('./img/signosIcon.png') },
          { id: 3, name: 'Sonrisas', img: require('./img/smileIcon.png') },
          {
            id: 4,
            name: 'Categorias',
            img: require('./img/categoriaIcon.png'),
          },
          { id: 5, name: 'Preguntas', img: require('./img/preguntaIcon.png') },
          {
            id: 6,
            name: 'Challenges',
            img: require('./img/challengeIcon.png'),
          },
          { id: 7, name: 'Letras', img: require('./img/wordIcon.png') },
          { id: 8, name: 'Matrix', img: require('./img/matrix.jpg') },
          { id: 9, name: 'Gemelos', img: require('./img/twins.png') },
        ],
        context: 'Menu principal',
        showGameModal: false,
        tab: 0,
        items: [
          {
            id: 0,
            key: 'Inicio',
            icon: 'mdi-home',
            view: '/home',
            mustBeLogged: false,
            mustBeUnLogged: true,
          },
          {
            id: 1,
            key: 'Inicio',
            icon: 'mdi-home',
            view: '/dashboard',
            mustBeLogged: true,
            mustBeUnLogged: false,
          },

          {
            id: 2,
            key: 'Nosotros',
            icon: 'mdi-brain',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/us',
          },
          {
            id: 3,
            key: 'Contacto',
            icon: 'mdi-face-agent',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/contact',
          },
          {
            id: 4,
            key: 'Juegos',
            icon: 'mdi-controller-classic-outline',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/games',
          },
          {
            id: 6,
            key: 'Tienda',
            icon: 'mdi-store',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/store',
          },
          {
            id: 7,
            key: 'Desafios',
            icon: 'mdi-book-open',
            mustBeLogged: true,
            mustBeUnLogged: false,
            view: '/exercises',
          },
        ],
        mobileItems: [
          {
            id: 0,
            key: 'Inicio',
            icon: 'mdi-home',
            view: '/dashboard',
            mustBeLogged: true,
            mustBeUnLogged: false,
          },
          {
            id: 1,
            key: 'Inicio',
            icon: 'mdi-home',
            view: '/default',
            mustBeLogged: false,
            mustBeUnLogged: true,
          },
          {
            id: 2,
            key: 'Juegos',
            icon: 'mdi-controller-classic-outline',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/games',
          },
          {
            id: 4,
            key: 'Extra',
            icon: 'mdi-menu',
            mustBeLogged: false,
            view: '/extra',
          },
        ],
        mobileExtraItems: [
          {
            id: 5,
            key: 'Nosotros',
            icon: 'mdi-brain',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/us',
          },
          {
            id: 6,
            key: 'Contacto',
            icon: 'mdi-face-agent',
            mustBeLogged: false,
            mustBeUnLogged: false,
            view: '/contact',
          },
          {
            id: 7,
            key: 'Tienda',
            icon: 'mdi-store',
            mustBeLogged: false,
            view: '/store',
          },
          {
            id: 8,
            key: 'Desafios',
            icon: 'mdi-book-open',
            mustBeLogged: true,
            view: '/exercises',
          },
        ],
        hasMobileSize: false,
        sliderMobile: null,
        size: null,
      };
    },

    watch: {
      group() {
        this.drawer = false;
      },

      isLogged() {
        this.setTabByPath();
      },
    },

    computed: {
      activeSubscription() {
        return this.userInfo && this.userInfo.subscription
          ? this.userInfo.subscription
          : null;
      },

      currentPath() {
        return this.$router.currentRoute;
      },

      isSmallDevice() {
        return this.size < 420;
      },

      userData() {
        return this.userInfo;
      },

      actualGame() {
        return 'Entrenando : ' + this.gameSelected;
      },

      gameSelected() {
        return this.games[this.group] ?? '';
      },
    },

    created() {
      this.sliderMobile = document.getElementById('sliderMobile');
      window.addEventListener('resize', () => {
        this.size = window.innerWidth;
      });
    },

    mounted() {
      window.addEventListener('resize', this.fixMenu);
      setTimeout(() => {
        this.setTabByPath();
      }, 400);
    },

    methods: {
      logout() {
        this.$emit('logout');
      },

      setTab(selectedTab) {
        this.tab = selectedTab;
      },

      setTabByPath() {
        /**
         * @type {string}
         */
        let currentPath = this.$router.currentRoute.path;

        if (currentPath.endsWith('/')) {
          currentPath = currentPath.substring(0, currentPath.length - 1);
        }

        let currentItem = this.items.find((item) => item.view === currentPath);

        if (currentItem) {
          this.tab = currentItem.id;
        }
      },

      fixMenu() {
        this.sliderMobile = document.getElementById('sliderMobile');

        const tabList = this.sliderMobile.firstChild;

        tabList.classList.add('slider-mobile');
        let wrapper = tabList.childNodes[1];

        wrapper.firstChild.classList.add('d-flex');
        wrapper.firstChild.classList.add('justify-space-around');
      },
    },
  };
</script>

<style>
  .w-75 {
    width: 75%;
  }
  .slider-mobile {
    display: flex;
    width: 100%;
    max-width: inherit !important;
  }

  .v-item-group {
    flex: 0 1 auto;
    position: relative;
    max-width: inherit !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .h-100 {
    height: 100%;
  }

  .game-card {
    max-height: 150px;
    min-width: 150px;
    min-height: 150px;
  }

  .enchanced:hover {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 2);
    transform: scale(1.16px);
    animation: enchance 1s linear;
  }

  .bg-blur {
    background-color: rgba(253, 251, 251, 0.2);
    backdrop-filter: blur(10px);
  }

  @keyframes enchance {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
      transform: scale(1.02);
    }
    20% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.4);
      transform: scale(1.04);
    }
    30% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: scale(1.06);
    }
    40% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.8);
      transform: scale(1.08);
    }
    50% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1);
      transform: scale(1.1px);
    }
    60% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1.2);
      transform: scale(1.1px);
    }
    70% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1.4);
      transform: scale(1.12px);
    }
    80% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1.6);
      transform: scale(1.14px);
    }
    90% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 1.8);
      transform: scale(1.16px);
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 2);
      transform: scale(1.16px);
    }
  }
</style>
