import UserModel from '@/Models/User/UserModel';
import Global from '@/helpers/Global';
import http from '@/helpers/https';

export default class SessionService {
  async checkSession(callback: CallableFunction) {
    try {
      await http()
        .post(Global.resolveUrl(process.env.NODE_ENV) + `IntelectoAuth/AuthController.php?t=${Date.now()}`, {
          token: '',
          action: 'check-login',
        })
        .then((response) => {
          const userInfo = new UserModel(response.data?.userInfo ?? {});
          const isUserLogged = response.data.success;

          callback(isUserLogged, userInfo);
        });
    } catch (error) {
      console.log(error);
    }
  }
}
