<template>
  <div class="chart">
    <div
      v-for="index in 100"
      class="bar"
      style="height: 30px"
      :class="getRandomBarClass()"
    ></div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component
  export default class TicketBarCode extends Vue {
    public getRandomBarClass(): string {
      const randomNumber = Math.floor(Math.random() * 4) + 1;
      return `bar${randomNumber}`;
    }
  }
</script>

<style scoped>
  .chart {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 50px;
  }

  .bar {
    background-color: black;
    transition: height 0.5s ease;
  }
  .bar1 {
    width: 5px;
  }
  .bar2 {
    width: 3px;
  }
  .bar3 {
    width: 1px;
  }
  .bar4 {
    width: 0.5px;
  }
</style>
