<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="500"
      outlined
      shaped
    >
      <v-card-title>Actividad mas utilizada (%)</v-card-title>
      <v-card-subtitle>En los últimos 30 dias</v-card-subtitle>
      <bar-chart
        v-if="preparedData && options"
        :chartData="preparedData"
        :options="options"
      />
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import BarChart from './Charts/BarChart.vue';
  import ChartOptionProvider from './Services/ChartOptionProvider';

  @Component({
    components: {
      BarChart,
    },
  })
  export default class DashboardGlobalGameUsage extends Vue {
    @Prop({ default: () => [] }) readonly data!: any[];

    public preparedData: any = null;
    public options: any = null;
    private optionProvider: ChartOptionProvider = new ChartOptionProvider();

    mounted() {
      this.setData();
    }

    setData() {
      const orderedData = this.data.slice().sort((a: any, b: any) => b.total - a.total);
      const allTotal = orderedData.reduce((acc: any, item: any) => acc + item.total, 0);

      const totals = orderedData.map((item: any) => (item.total / allTotal) * 100);
      const labels = orderedData.map((item: any) => item.name);
      const colors = orderedData.map((item: any) => item.color);

      this.preparedData = {
        labels: [...labels],
        datasets: [
          {
            data: totals,
            backgroundColor: colors,
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };

      this.options = this.optionProvider.getOptionsForVerticalBarChart();
    }
  }
</script>
