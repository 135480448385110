<template>
  <div class="">
    <v-progress-linear
      class="progress mx-auto"
      rounded
      width="10"
      color="green"
      height="20px"
      v-bind:value="this.value"
      v-bind:buffer-value="this.buffer"
    />
  </div>
</template>

<script>
  export default {
    name: 'LinearProgress',

    props: {
      value: {
        type: Number,
        required: true,
        default: 100,
      },

      buffer: {
        type: Number,
        required: false,
        default: 100,
      },
    },

    watch: {
      value: {
        handler(newValue) {
          if (newValue === 1) {
            this.$emit('on-time-over');
          }
        },
      },
    },
  };
</script>
