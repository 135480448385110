<template>
  <div class="">
    <v-card class="mx-auto" max-width="400" min-width="220">
      <v-icon class="w-100 mx-auto my-auto" color="yellow darken-2"
        size="100">mdi-crown</v-icon>
      <p class="text-center my-5"> Cognosmemory Pro </p>
      <div class="w-100">
        <v-btn-toggle class="d-flex mx-auto my-auto justify-center"
          v-model="revenueSwitch" mandatory variant="text"
          @change="onChangeRevenue()">
          <v-btn> Mensual </v-btn>
          <v-btn> Anual </v-btn>
        </v-btn-toggle>
      </div>
      <div class="w-100 d-flex justify-center my-5">
        <h4> {{ periodText }} </h4>
      </div>

      <v-card-actions class="d-flex flex-column">
        <div class="d-flex w-100 justify-center">
          <v-btn v-if="!buttonDisabled"
            class="deep-purple accent-2 mx-auto my-auto"
            :class="{ disabled: buttonDisabled }"
            @click="onShowForm()">Suscribirme!</v-btn>
          <v-btn v-if="buttonDisabled" disabled
            class="deep-purple accent-2">Suscribirme!</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="w-100 d-flex justify-center mt-3">
          <v-btn icon @click="onExpand()">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            <p class="subtitle-1"> Cognosmemory Pro {{ revenueSelected }}</p>
            <v-subheader class="mb-3"> Con este plan tendras acceso ilimitado a
              las siguientes ventajas: </v-subheader>

            <v-list-item v-for="feature in features">
              <v-list-item-icon>
                <v-icon>{{ feature.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ feature.text }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'StoreOfferPro',

  props: {
    buttonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    currency: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      set: {
        planId: 1,
        price: 0,
        period: 'mensual',
        name: 'Cognosmemory Pro',
      },

      prices: {
        USD: {
          symbol: 'U$S',
          monthly: 8,
          yearly: 90,
        },
        UYU: {
          symbol: '$',
          monthly: 320,
          yearly: 3600,
        },
      },
      features: [
        { text: 'Plan de entrenamiento personal', icon: 'mdi-account-cog-outline' },
        { text: 'Desafios semanales', icon: 'mdi-trophy-outline' },
        { text: 'Estadisticas personalizadas', icon: 'mdi-chart-areaspline' },
        { text: 'Evaluacion mediante inteligencia artificial', icon: 'mdi-robot' },
        { text: 'Ejercicios exclusivos', icon: 'mdi-dumbbell' },
        { text: 'Acceso ilimitado a todos los niveles', icon: 'mdi-lock-open-outline' }
      ],
      revenueSwitch: false,
      periodSelected: '',
      subscriptionData: {
        id: '2c93808481b537980181be9538060424',
        // id interno (del negocio) del item
        title: 'Cognosmemory Pro',
        // nombre que viene de la prop que recibe del controller
        description: 'Cognosmemory Pro mensual',
        // descripción del producto
        picture_url: '../img/logo.png',
        // url de la imágen del producto
        category_id: 'subscriptions',
        // categoría interna del producto (del negocio)
        quantity: parseInt(1),
        // cantidad, que tiene que ser un intiger
        unit_price: parseFloat(2500),
        // el precio, que por su complejidad tiene que ser tipo FLOAT
      },
    };
  },

  computed: {
    periodText() {
      return this.revenueSwitch
        ? 'Uso ilimitado  ' + this.currencySymbol + this.price + '/anual *'
        : 'Uso ilimitado  ' + this.currencySymbol + this.price + '/mensual *';
    },

    revenueSelected() {
      return this.revenueSwitch ? 'anual' : 'mensual';
    },

    price() {
      return this.revenueSwitch ? this.prices[this.currency].yearly : this.prices[this.currency].monthly;
    },

    currencySymbol() {
      return this.prices[this.currency].symbol;
    },
  },

  methods: {
    onInitSubscription() {
      this.$emit('on-init-subscription', this.subscriptionData);
    },

    onChangeRevenue() {
      this.$emit('on-change-revenue', this.revenueSelected);
    },

    onShowForm() {
      this.set.price = this.price;
      this.set.currency = this.currency;
      this.set.period = this.revenueSelected;
      this.$emit('on-show-form', this.set);
    },

    onExpand() {
      this.show = !this.show;
      this.scrollDown();
    },

    scrollDown() {
      setTimeout(() => {
        window.scrollBy({
          top: window.innerHeight * 2,
          behavior: 'smooth',
        });
      }, 300);
    },
  },
};
</script>

<style>
.subscription-offer {
  max-width: 215px;
}

#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
</style>
