export default class CreativeExercise {
    id: number;
    nivel: string;
    tarea: string;
    target: string;
    target_copy: string;
    used: number;

    constructor(params: any) {
        this.id = params.id;
        this.nivel = params.nivel;
        this.tarea = params.tarea;
        this.target = params.target;
        this.target_copy = params.target_copy;
        this.used = params.used;
    }
}