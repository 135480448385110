export default class GoNoGoSubject {
    private idPrefix: string = 'gonogo-subject-';

    public id: string;
    public name: string;
    public imageUrl: string;
    public isTarget: boolean;
    public width: number;
    public height: number;
    public x: number;
    public y: number;
    public visibility: boolean;

    public constructor(name:string) {
        this.id = this.idPrefix + name;
        this.name = name;
        this.imageUrl = '';
        this.isTarget = false;
        this.width = 0;
        this.height = 0;
        this.x = 0;
        this.y = 0;
        this.visibility = false;
    }
}