<template>
  <div>
    <div v-if="order.errors.length > 0">
      <div class="mb-4">
        <h2 class="text-center">Errores encontrados en la orden</h2>
        <v-divider></v-divider>
      </div>

      <v-alert
        class="mx-auto"
        type="error"
        :value="true"
        transition="scale-transition"
      >
        <v-list-item
          three-line
          v-for="(error, index) in order.errors"
        >
          <v-list-item-content>
            <div class="overline">{{ index + 1 + '.' + error }}</div>
            <br />
          </v-list-item-content>
        </v-list-item>
      </v-alert>

      <v-alert
        class="mx-auto"
        type="info"
        :value="true"
        transition="scale-transition"
      >
        Intentalo de nuevo!
      </v-alert>
    </div>
    <div v-else>
      <v-card
        class="mx-auto rounded-lg"
        max-width="900"
        max-height="600"
        outlined
        tile
      >
        <v-card-title class="text-center">
          <h1> {{ successTextTitle }} </h1>
        </v-card-title>
        <v-card-text class="text-center">
          <h2> {{ successComment }} </h2>
        </v-card-text>
      </v-card>
    </div>
    <div class="d-flex w-100 justify-center mt-3">
      <v-btn
        class="mx-auto"
        color="primary"
        @click="$emit('on-restart')"
      >
        Finalizar
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import Order from '../Structures/Order';

  @Component
  export default class WaiterSummary extends Vue {
    @Prop() order: Order;

    successTextTitle: string = '¡Felicidades! Has completado la orden correctamente';
    successComment: string = 'El cliente ha quedado satisfecho con tu servicio';
  }
</script>

<style scoped></style>
