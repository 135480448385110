<template>
  <div>
    <h1 class="text-center mb-4">Menu</h1>
    <div class="d-flex justify-center">
      <div class="d-flex flex-column rounded mx-5">
        <h3 class="text-center mb-3">Comidas</h3>
        <div
          class="d-flex justify-center"
          v-for="item in foods"
        >
          <v-icon
            class="d-flex mb-2 selector"
            @click="removeProduct(item)"
            >mdi-minus</v-icon
          >
          <v-chip
            class="d-flex flex-row ml-2 mb-2"
            color="primary"
            dark
            :draggable="true"
            >{{ item.name }}
            <v-icon>{{ item.icon }}</v-icon>
          </v-chip>
          <v-icon
            class="d-flex mb-2 selector"
            @click="addProduct(item)"
            >mdi-plus</v-icon
          >
        </div>
      </div>

      <div class="d-flex flex-column mx-5">
        <h3 class="text-center mb-3">Bebidas</h3>
        <div
          class="d-flex justify-center"
          v-for="item in drinks"
        >
          <v-icon
            class="d-flex mb-2 selector"
            @click="removeProduct(item)"
            >mdi-minus</v-icon
          >
          <v-chip
            class="d-flex flex-row ml-2 mb-2"
            color="primary"
            dark
            min-width="200"
            :draggable="true"
            >{{ item.name }}
            <v-icon>{{ item.icon }}</v-icon>
          </v-chip>
          <v-icon
            class="d-flex mb-2 selector"
            @click="addProduct(item)"
            >mdi-plus</v-icon
          >
        </div>
      </div>
    </div>
    <OrderPreview
      class="d-flex mx-auto"
      :order="newOrder"
      :isTimeOut="isTimeOut"
      @onDeliver="onDeliver"
    />
    <Timer v-if="!isTimeOut" :time="timeToMakeOrder" @on-timeout="onTimeOut" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import ProductHelper from '../Helpers/ProductHelper';
  import Order from '../Structures/Order';
  import OrderService from '../Services/OrderService';
  import OrderPreview from './OrderPreview.vue';
  import { Prop } from 'vue-property-decorator';
  import Timer from './Timer.vue';

  @Component({
    components: {
      OrderPreview,
      Timer,
    },
  })
  export default class MenuBar extends Vue {
    @Prop({ required: true }) timeToMakeOrder: number;

    orderService: OrderService;

    constructor() {
      super();
      this.orderService = new OrderService();
    }

    menu: boolean = false;
    drinks: any[] = ProductHelper.getDrinks();
    foods: any[] = ProductHelper.getFoods();

    newOrder: Order = new Order();

    // Boolean vars
    public isTimeOut: boolean = false;

    public addProduct(product: any) {
      this.orderService.addProduct(this.newOrder, product);
    }

    public removeProduct(product: any) {
      this.orderService.removeProduct(this.newOrder, product);
    }

    public onTimeOut() {
      this.isTimeOut = true;
      this.$emit('onTimeOut', this.newOrder);
    }

    public onDeliver() {
      this.$emit('onDeliver', this.newOrder);
    }
  }
</script>

<style scoped>
  .selector {
    margin: 0 5px;
  }

  .selector:hover {
    cursor: pointer;
    color: blue;
  }
</style>
