<template lang="">
  <div class="d-flex w-25 mx-auto">
    <v-select
      v-model="selected"
      :items="items"
      label="Elige una moneda (USD, UYU)"
      item-text="name"
      menu-icon="mdi-menu-down"
      item-value="name"
      @change="onChange($event, selected)"
    >
      <template v-slot:selection="{ item, index }">
        <div v-if="index === 0">
          <span class="mr-2">{{ item.name }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                >{{ countryFlag }}</v-icon
              >
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-select>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Emit } from 'vue-property-decorator';

  @Component
  export default class CurencySelector extends Vue {
    selected: string = '';
    items: any[] = [
      { id: '1', name: 'USD', countryCode: 'US', description: 'Dólares americanos' },
      { id: '2', name: 'UYU', countryCode: 'UY', description: 'Pesos uruguayos' },
    ];

    get countryFlag() {
      const flag = this.items.find((item) => item.name === this.selected)?.countryCode ?? 'mdi-map';

      return this.getFlag(flag);
    }

    private getFlag(countryCode: string) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => char.codePointAt(0) + 127397);

      return String.fromCodePoint(...codePoints);
    }

    @Emit('on-select-currency')
    onChange() {
      return this.selected;
    }
  }
</script>
