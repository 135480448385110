import MemoryCard from './MemoryCard';
class MemoryCardField {
  cardField: HTMLElement | null;
  cards: Array<MemoryCard>;
  currentCard: MemoryCard | null = null;
  lastCard: MemoryCard | null = null;
  matches: number = 0;
  requiredMatches: number = 0;

  constructor(cards: Array<MemoryCard>) {
    this.cards = cards;

    this.setUp();
  }

  setUp() {
    this.requiredMatches = this.cards.length / 2;
  }

  get isTwinCardSelected(): boolean {
    return this.currentCard?.imageSource === this.lastCard?.imageSource && this.currentCard?.id !== this.lastCard?.id;
  }

  get cardFieldElement(): HTMLElement | null {
    return this.cardField;
  }

  onCardSelected(id: string, side: string): void {
    if (this.currentCard && this.currentCard.id !== id) {
      this.lastCard = this.currentCard;
      this.cardField?.classList.add('disable');
    }

    this.currentCard = this.cards.find((MemoryCard) => MemoryCard.id === id) ?? null;

    if (!this.currentCard) {
      return;
    }

    this.currentCard.onSelect(side);

    this.validate(side);
  }

  validate(side: string) {
    // Means that we're selecting the same card no need to validate
    if (!this.lastCard) {
      return;
    }

    if (this.isTwinCardSelected) {
      this.onAssertTwin(); /**Process the assertion and reset */

      return;
    }

    setTimeout(() => {
      this.currentCard?.onSelect(side);
      this.lastCard?.onSelect(side);
      this.resetToDefault();
      this.cardField?.classList.remove('disable');
    }, 700);
  }

  validateGame(): void {
    if (this.matches === this.requiredMatches) {
    }
  }

  resetToDefault(): void {
    this.currentCard = null;
    this.lastCard = null;
  }

  onAssertTwin() {
    this.currentCard?.disableEvents();
    this.lastCard?.disableEvents();
    this.matches++;
    this.validateGame();
    this.resetToDefault();

    setTimeout(() => {
      this.cardField?.classList.remove('disable');
    }, 700);
  }

  setField() {
    this.cardField = document.getElementById('cardField');

    if (!this.cardField) {
      alert('Must be set in the mounted Vue hook');
    }
  }
}
export default MemoryCardField;
