<template>
  <v-container>
    <v-row>
      <v-col cols="col-sm-12 col-md-8">
        <h2 class="text--secondary"> Nuestra visión</h2>
        <div class="py-2">
          <p>
            Lograr que las personas cuenten con los mecanismos de defensa para
            evitar el desgaste cognitivo al avanzar la edad de cada uno y que
            incluso se potencie aun mas que en la edad juvenil.
          </p>
        </div>
        <div class="py-2">
          <p>
            Los módulos que componen al proyecto, pondrán a prueba tus aptitudes
            y permitirán la superacion de barreras. A eso hay que sumarle la
            incorporación de buenos hábitos mentales, aprender más de todo y
            empezar a trabajar sobre enfoques nunca antes pensados.
          </p>
        </div>
        <div class="py-2">
          <p>
            Aunque la ciencia estuvo hasta cierto tiempo, indecisa sobre si es
            posible aumentar el coeficiente intelectual o no, recientes
            investigaciones sugieren que es posible aumentar la inteligencia a
            través de ciertas actividades de entrenamiento cerebral. Entrenar la
            memoria, el control ejecutivo y el razonamiento visuo-espacial,
            entre otros, puede ayudar a aumentar los niveles de inteligencia.
          </p>
        </div>
        <div class="py-2">
          <h2 class="text--secondary"> Nuestra misión</h2>
        </div>
        <div class="py-2">
          <p>
            Conservar y mejorar las capacidades cognitivas tales como:
            inteligencia, memoria, percepción, atención, orientación espacial,
            comprensión, funciones ejecutivas, concentración, razonamiento,
            abstracción, operaciones aritméticas, pensamiento deductivo y
            pensamiento lógico.
          </p>
        </div>
        <div class="py-2">
          <h2 class="text--secondary"> Nuestros valores</h2>
          <v-list flat>
            <v-list-item v-for="value in values" :key="value">
              <v-list-item-icon class="mr-5">
                <v-icon small>mdi-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="value"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'MainGoalMarketing',

    data() {
      return {
        values: [
          'Excelencia',
          'Desarrollo e innovación',
          'Personalización',
          'Compromiso',
        ],
      };
    },
  };
</script>

<style></style>
