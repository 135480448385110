import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'DefaultContent',
    component: () => import(/* webpackChunkName: "about" */ '../views/DefaultContentView.vue'),
  },
  {
    path: '/dashboard',
    name: 'DashboardDefault',
    component: () => import(/* webpackChunkName: "about" */ '../views/DashboardDefaultView.vue'),
  },
  {
    path: '/us',
    name: 'Us',
    component: () => import(/* webpackChunkName: "about" */ '../views/Us.vue'),
  },
  {
    path: '/games',
    name: 'Games',
    component: () => import(/* webpackChunkName: "about" */ '../views/GamesView.vue'),
  },
  {
    path: '/games-default',
    name: 'GamesDefault',
    component: () => import(/* webpackChunkName: "about" */ '../views/GamesDefaultView.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
  },
  {
    path: '/store',
    name: 'StoreView',
    component: () => import(/* webpackChunkName: "about" */ '../views/StoreView.vue'),
  },
  {
    path: '/extra',
    name: 'MobileExtraView',
    component: () => import(/* webpackChunkName: "about" */ '../views/MobileExtraView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordRecoverView.vue'),
  },
  {
    path: '/account-recover',
    name: 'AccountRecover',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountRecoverView.vue'),
  },
  {
    path: '/confirm-account',
    name: 'ConfirmAccount',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmAccountView.vue'),
  },
  {
    path: '/exercises',
    name: 'Exam',
    component: () => import(/* webpackChunkName: "about" */ '../views/WeeklyExercisesView.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },
  {
    path: '/plan-details',
    name: 'PlanDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/PlanDetailsView.vue'),
  },
  {
    path: '/admin',
    name: 'adminView',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
  },
  {
    path: '/admin/customer-support',
    name: 'CustomerSupport',
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerSupportView.vue'),
  },
  {
    path: '/games/operations',
    name: 'Operations',
    component: () => import(/* webpackChunkName: "about" */ '../views/OperationsView.vue'),
  },
  {
    path: '*',
    name: 'NotFoundView',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue'),
  },
  {
    path: '*',
    redirect: '/not-found',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
