<template>
  <div v-if="isVisible">
    <v-dialog v-model="isVisible" width="auto">
      <v-card>
        <v-btn icon large @click="$emit('close-modal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Oh no! Estas seguro que quieres desactivar tu suscripción?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-list two-line subheader>
            <v-subheader
              >Ventajas que te estarias perdiendo si cancelas:</v-subheader
            >
            <v-list-item v-for="feature in features">
              <v-list-item-content>
                <v-list-item-title>{{ feature.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ feature.usage }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-check</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <div class="w-100">
            <v-btn
              :block="isMobile"
              color="red"
              @click="$emit('on-cancel-subscription')"
              class="mb-5 mb-md-0 mr-0 mr-md-5 white--text"
              >Si, quiero cancelar</v-btn
            >
            <v-btn :block="isMobile" color="gray" @click="$emit('close-modal')"
              >Quiero quedarme</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';

  @Component
  export default class UnsubscribeModal extends Vue {
    @Prop({ required: true }) isVisible: boolean;
    @Prop({ required: true }) features: Array<any>;
    @Prop({ required: true }) isMobile: Boolean;
  }
</script>
