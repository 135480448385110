import Ecuation from './Ecuation';
import EcuationLevelInterface from './EcuationLevelInterface';

export default class EcuationLevelThree implements EcuationLevelInterface {
  public get(): Ecuation {
    let a = this.getRandomNumber(0, 50);
    let c = this.getRandomNumber(0, 50);

    while (a - c == 0) {
      a = this.getRandomNumber(0, 50);
      c = this.getRandomNumber(0, 50);
    }

    let d = this.getRandomNumber(0, 50);
    let b = this.getRandomNumber(0, 50);

    while ((d - b) % (a - c) !== 0) {
      d = this.getRandomNumber(0, 50);
      b = this.getRandomNumber(0, 50);
    }

    const ecuation = new Ecuation();
    ecuation.title = 'Nivel 3';
    ecuation.description = 'Resuelve la ecuación';

    if (b < 0 && d >= 0) {
      b = -1 * b;
      ecuation.ecuation = a + 'x - ' + b + ' = ' + c + 'x + ' + d;
    } else if (d < 0 && b >= 0) {
      d = -1 * d;
      ecuation.ecuation = a + 'x + ' + b + ' = ' + c + 'x - ' + d;
    } else if (d < 0 && b < 0) {
      d = -1 * d;
      b = -1 * b;
      ecuation.ecuation = a + 'x - ' + b + ' = ' + c + 'x - ' + d;
    } else {
      ecuation.ecuation = a + 'x + ' + b + ' = ' + c + 'x + ' + d;
    }

    ecuation.answer = (d - b) / (a - c);

    return ecuation;
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
