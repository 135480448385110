import { render, staticRenderFns } from "./StoreDefault.vue?vue&type=template&id=6e2a8394&scoped=true"
import script from "./StoreDefault.vue?vue&type=script&lang=js"
export * from "./StoreDefault.vue?vue&type=script&lang=js"
import style0 from "./StoreDefault.vue?vue&type=style&index=0&id=6e2a8394&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e2a8394",
  null
  
)

export default component.exports