<template>
  <div class="d-flex lobby">
    <!-- Customer order -->
    <WaiterTicket
      v-if="isFinishedAnimation"
      class="w-50 mt-auto"
      :class="{ ticket: isFinishedAnimation }"
      :order="customerOrder"
      origin="Factura correcta"
    />
    <WaiterTicket
      class="w-50 ml-auto mt-auto ticket"
      :order="order"
      origin="Factura realizada por ti"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import Order from '../Structures/Order';
  import WaiterTicket from './WaiterTicket.vue';
  import OrderService from '../Services/OrderService';

  @Component({
    components: {
      WaiterTicket,
    },
  })
  export default class CustomerCheckout extends Vue {
    @Prop() order: Order;
    @Prop() customerOrder: Order;

    constructor() {
      super();
      this.orderService = new OrderService();
    }

    orderService: OrderService;

    isFinishedAnimation: boolean = false;

    created() {
      this.orderService.checkout(this.order, this.customerOrder);

      this.orderService.setTotal(this.customerOrder);

      setTimeout(() => {
        this.isFinishedAnimation = true;
      }, 1000);

      setTimeout(() => {
        this.$emit('onFinishCheckout');
      }, 10000);
    }
  }
</script>

<style scoped>
  .lobby {
    background-image: url('../assets/img/waiter_lobby_2.svg');
    background-repeat: no-repeat;
    height: 80vh;
    width: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  /* Animation for ticket coming from top to down */
  .ticket {
    animation: ticket-falling 1s ease-in-out;
  }

  @keyframes ticket-falling {
    0% {
      transform: translateY(-500%);
    }
    100% {
      transform: translateY(0%);
    }
  }
</style>
