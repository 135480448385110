import ProductHelper from '../Helpers/ProductHelper';
import GameParams from '../Structures/GameParams';
import OrderParams from '../Structures/OrderParams';
import Product from '../Structures/Product';
import OrderLevelResolvingService from './OrderLevelResolvingService';
import OrderParamsService from './OrderParamsService';

export default class CustomerOrderService {
  orderLevelResolvingService: OrderLevelResolvingService = new OrderLevelResolvingService();
  orderService: OrderParamsService = new OrderParamsService();

  genererateOrderParams(gameParams: GameParams): OrderParams {
    const foodAmount = this.orderLevelResolvingService.getAmountOfFoods(gameParams.level);
    const drinkAmount = this.orderLevelResolvingService.getAmountOfDrinks(gameParams.level);

    const foods = this.getFoods(foodAmount);
    const drinks = this.getDrinks(drinkAmount);

    let params = new OrderParams();

    foods.forEach((food) => {
      const product = this.buildProduct(food.name, food.price);
      this.orderService.addProduct(params, product);
    });

    drinks.forEach((drink) => {
      const product = this.buildProduct(drink.name, drink.price);
      this.orderService.addProduct(params, product);
    });

    params.customer = 'Cliente';
    params.table = 1;

    return params;
  }

  private getFoods(amount: number) {
    const foods = [];

    for (let i = 0; i < amount; i++) {
      foods.push(ProductHelper.getRandomFood());
    }

    return foods;
  }

  private getDrinks(amount: number) {
    const drinks = [];

    for (let i = 0; i < amount; i++) {
      drinks.push(ProductHelper.getRandomDrink());
    }
    return drinks;
  }

  private buildProduct(name: string, price: number): Product {
    const product = new Product();
    product.name = name;
    product.price = price;
    product.quantity = 1;

    return product;
  }
}
