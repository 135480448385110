export default class Country {
  public name: string;
  public capital: string;
  public flag: string;
  public population: number;
  public region: string;

  constructor(name: string, capital: string, flag: string, population: number, region: string) {
    this.name = name;
    this.capital = capital;
    this.flag = flag;
    this.population = population;
    this.region = region;
  }
}
