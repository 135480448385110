<template>
  <div class="d-flex mt-5 mx-auto justify-center">
    <div class="mt-5 square col-sm-3 col-xs-6" v-for="face in faces" :key="face.id">
      <v-icon :id="face.id">{{ face.icon }}</v-icon>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      faces: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
  };
</script>