<template>
  <div
    v-if="this.renderComponent"
    class="d-flex-inline fill-height"
  >
    <div class="d-flex ml-auto mr-3">
      <custom-dialog
        class="d-flex ml-auto mt-4"
        :is-visible="dialog"
        :button-text="dialogData.buttonText"
        :title="dialogData.title"
        :description="dialogData.description"
      />
    </div>
    <div class="d-flex">
      <div
        v-if="isTimeOut"
        class="d-flex mx-auto my-auto w-25"
      >
        <div class="d-flex mx-auto">
          <v-chip>{{ 'Intentos: ' }}</v-chip>
          <v-chip class="primary">{{ tries }}</v-chip>
        </div>
      </div>
      <div class="mx-auto py-8 px-8 d-flex">
        <LevelProgress :value="this.getProgreso" />
        <div class="d-flex align-center ml-5">
          <v-icon
            large
            color="green darken-2"
            class="mx-auto"
            >mdi-trophy-outline
          </v-icon>
          <v-chip
            color="green-light "
            class="text--darken-2"
          >
            {{ this.level }}
          </v-chip>
        </div>
      </div>
    </div>
    <div class="d-flex mt-64 justify-center">
      <v-btn
        v-if="!this.isGameStarted"
        elevation="2"
        text
        class="d-flex mb-15 mx-auto"
        @click.prevent="startCountDown()"
      >
        Comenzar!
      </v-btn>

      <div
        id="game"
        v-if="this.isStarted && !this.isTimeOut"
      >
        <linear-progress :value="getTimeUntilStart" />

        <item-list
          :items="this.params.levelData.items"
          class="item mx-auto"
        />
      </div>
      <div
        v-if="isTimeOut"
        class="d-flex w-100 mx-auto my-auto mt-32"
      >
        <item-list
          @onClickItem="onClickItem"
          :items="this.randomTest"
          class="item"
        />
        <div
          v-if="!hasTries"
          class="warning"
        >
          <button
            class="btn btn-success"
            @click="reload()"
          />
        </div>
      </div>
      <div
        v-if="isWinner"
        class="mx-auto"
      >
        <ExerciseWinnerModal
          :is-visible="isWinner && !isPlayingLastLevel"
          :previousLevelOption="true"
          @callback="reload(false)"
          @repeat="onRepeat"
          @previousLevel="onPreviousLevel"
          @close-modal="reload(false)"
        />
      </div>
      <div
        v-if="isLoser"
        class="mx-auto"
      >
        <ExerciseLoserModal
          :is-visible="isLoser"
          :previousLevelOption="level > 1"
          @callback="reload(false)"
          @repeat="onRepeat"
          @previousLevel="onPreviousLevel"
          @close-modal="reload(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Global from '@/helpers/Global';
  import http from '@/helpers/https';
  import LevelProgress from './Misc/LevelProgress';
  import LinearProgress from './Misc/LinearProgress';
  import ItemList from './Misc/ItemList';
  import RepeatLevelModal from './Misc/RepeatLevelModal';
  import scrollToGame from '@/helpers/GameHelper';
  import { DIALOG_MEMORY_DATA } from '../helpers/DialogHelper';
  import CustomDialog from './Misc/CustomDialog';
  import { mapGetters } from 'vuex';
  import ExerciseWinnerModal from './Misc/Exercises/Modals/ExerciseWinnerModal.vue';
  import ExerciseLoserModal from './Misc/Exercises/Modals/ExerciseLoserModal.vue';
  import MemoryColourGameService from './Games/MemoryColour/Services/MemoryColourGameService';
  import GameRequestMixing from '@/mixins/gameRequestMixin';

  export default {
    name: 'MemoryColour',
    components: {
      LevelProgress,
      LinearProgress,
      ItemList,
      RepeatLevelModal,
      CustomDialog,
      ExerciseWinnerModal,
      ExerciseLoserModal,
    },

    mixins: [GameRequestMixing],

    data() {
      return {
        renderComponent: true,
        params: {
          levelData: {},
        },
        randomTest: [],
        timeUntilFinish: 100,
        isStarted: false,
        assertions: 0,
        level: 0,
        maximoPuntaje: 0,
        http: http,
        dialog: false,
        dialogData: DIALOG_MEMORY_DATA,
        prefixItem: 'MemoryColourItem:',
        solution: [],
      };
    },

    async created() {
      this.setGameController('MemoryColourController');
      if (!this.isOnChallenge) {
        await this.initComponent();
      } else {
        await this.initChallenge();
      }
    },

    computed: {
      ...mapGetters({ isLogged: 'getIsLogged' }),

      hasTries() {
        return this.params.levelData.tries > 0;
      },

      tries() {
        return this.params.levelData.tries;
      },

      getTimeUntilStart() {
        return this.params.levelData.timeUntilStart;
      },

      isGameStarted() {
        return this.isStarted;
      },

      isTimeOut() {
        return this.params.levelData.timeUntilStart === 0;
      },

      isWinner() {
        return this.assertions === this.maximoPuntaje && this.assertions !== 0;
      },

      isLoser() {
        return !this.hasTries && this.isStarted;
      },

      getProgreso() {
        return this.params.porcentajeProgreso;
      },

      isFirstLevel() {
        return this.level < 2;
      },

      isPlayingLastLevel() {
        return this.params.isPlayingLastLevel;
      },
    },

    methods: {
      async initComponent(needLastGame = false) {
        let action = 'init_game';

        if (needLastGame) {
          action = 'init_last_game';
        }

        const gameService = new MemoryColourGameService();
        gameService.initGame({ action }, (data) => {
          this.params = data;
          this.randomTest = this.params.levelData.items.slice();
          this.solution = this.params.levelData.solution.slice();
          this.level = this.params.currentLevel;
          this.maximoPuntaje = this.params.maximoPuntaje;
          this.shuffleArray();
        });
      },

      startCountDown() {
        if (!this.isGameStarted) {
          var interval = setInterval(() => {
            if (this.params.levelData.timeUntilStart > 0) {
              this.isStarted = true;
              this.params.levelData.timeUntilStart--;
            } else {
              clearInterval(interval);
            }
          }, 100);
          scrollToGame();
        }
      },

      onClickItem(item) {
        if (item.color === this.solution[0]) {
          this.removeAnimated(item);
        } else {
          this.shakeElement(item);
        }
      },

      shakeElement(item) {
        let itemElement = document.getElementById(this.prefixItem + item.id);
        itemElement.classList.add('shakeItem');
        itemElement.classList.remove('elevation-12');

        setTimeout(() => {
          itemElement.classList.remove('shakeItem');
          itemElement.classList.add('elevation-12');

          this.params.levelData.tries--;
        }, 500);
      },

      removeAnimated(item) {
        this.solution.shift();
        const itemElement = document.getElementById(this.prefixItem + item.id);

        itemElement.classList.add('removeAnimated');
        itemElement.ariaDisabled = true;

        setTimeout(() => {
          itemElement.classList.add('checked');
          this.assertions++;
        }, 800);
      },

      shuffleArray() {
        this.randomTest.sort(function () {
          return Math.random() - 0.5;
        });
      },

      sendResults(needLastGame = false) {
        if (!this.isLogged) {
          return;
        }

        const data = {
          game: 'MemoryColour',
          total: this.assertions,
          idNivelPregunta: this.params.currentLevel,
          idAlumno: this.params.idAlumno,
        };

        if (this.isOnChallenge) {
          this.$emit('on-finish-challenge', data);
          return;
        }

        if (needLastGame) {
          this.initComponent(needLastGame);
          return;
        }

        const request = http();

        request
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/MemoryColourController.php', {
            action: 'save_user_results',
            data: JSON.stringify(data),
            hashDev: Global.hashDev(),
          })
          .then(
            function () {
              this.initComponent();
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      },

      reload(needLastGame = false) {
        this.sendResults(needLastGame);
        this.resetComponent();

        this.$nextTick(() => {
          // Add the component back in
          this.renderComponent = true;
          this.randomTest = this.params.levelData.items.slice();
          this.solution = this.params.levelData.solution.slice();
        });
      },

      resetComponent() {
        this.renderComponent = false;
        this.isStarted = false;
        this.assertions = 0;
        this.params.levelData.tries = 3;
        this.randomTest = [];
        this.timeUntilFinish = 100;
        this.params.levelData.timeUntilStart = 100;
      },

      /**
       * @returns {void}
       */
      onRepeat() {
        this.reload(true);
      },

      /**
       * @returns {void}
       */
      onPreviousLevel() {
        this.reload(true);
        this.randomTest = this.params.levelData.items.slice();
        this.solution = this.params.levelData.solution.slice();
      },

      initChallenge() {
        const request = http();
        request
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/MemoryColourController.php', {
            //dev route
            action: 'init_challenge',
            levelId: this.challengeLevel,
            hashDev: Global.hashDev(),
          })
          .then(
            function (response) {
              if (response) {
                this.params = response.data.challenge;
                this.randomTest = this.params.levelData.items.slice();
                this.level = this.params.currentLevel;
                this.maximoPuntaje = this.params.maximoPuntaje;
                this.shuffleArray();
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error.data);
          });
      },
    },
  };
</script>

<style>
  .break-object {
    max-width: 100%;
  }

  .square {
    max-width: 50px;
  }

  .progress {
    max-width: 500px;
  }

  .item {
    flex-wrap: wrap;
  }

  .removeAnimated {
    animation: elevate 0.8s;
    perspective: 1000px;
    transform-style: preserve-3d;
    pointer-events: none;
  }

  .checked {
    opacity: 0.3;
  }

  @keyframes elevate {
    0% {
      transform: translateY(-1px) rotateY(90deg);
    }

    10% {
      transform: translateY(-2px) rotateY(180deg);
    }

    20% {
      transform: translateY(-4px) rotateY(90deg);
    }

    30% {
      transform: translateY(-8px) rotateY(0deg);
    }

    40% {
      transform: translateY(-10px) rotateY(90deg);
    }

    50% {
      transform: translateY(-8px) rotateY(180deg);
    }

    60% {
      transform: translateY(-14px) rotateY(90deg);
    }

    70% {
      transform: translateY(-15px) rotateY(0deg);
    }

    80% {
      transform: translateY(-15px) rotateY(90deg);
    }

    90% {
      transform: translateY(-15px) rotateY(180deg);
    }

    100% {
      transform: translateY(-15px) rotateY(0deg);
    }
  }

  .shakeItem {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    animation-iteration-count: 1;
    border: 10px solid red !important;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
</style>
