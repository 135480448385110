export default class GoNoGoSubjectDataMapperService {
  public static getSubjectImageUrl(subjectName: string): string {
    try {
      switch (subjectName) {
        case 'pelota':
          return require('./assets/img/pelota.png');
        case 'tobogan':
          return require('./assets/img/tobogan.png');
        case 'lapiz':
          return require('./assets/img/lapiz.png');
        case 'goma':
          return require('./assets/img/goma.png');
        default:
          return '';
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  }
}
