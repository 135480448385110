<template>
  <div>
    <div
      v-for="game in questionGames"
      class="justify-space-around d-flex w-100"
    >
      <div class="d-flex">
        <v-card>
          <v-card-title
            >{{ game.name }}
            <v-img
              class="ml-5"
              style="max-width: 50px"
              :src="game.imageUrl"
            ></v-img
          ></v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-btn
              class="mx-auto"
              color="primary"
              @click="() => $router.push(game.route)"
              >Jugar</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component
  export default class QuestionsNew extends Vue {
    public questionGames: Array<any> = [
      { name: 'Operaciones Matemáticas', imageUrl: require('./img/signosIcon.png'), route: '/games/operations' },
    ];
  }
</script>
