import Product from './Product';

export default class Order {
  public id: number;
  public customer: string;
  public table: number;
  public products: Array<Product> = [];
  public status: string;
  public total: number;
  public errors: Array<string> = [];
}
