export const DIALOG_SMILER_DATA = {
    buttonText: 'Como jugar?',
    title: 'Intrucciones',
    description: `El juego consiste en contar cuantas <b>caras sonrientes</b> hay antes
    de que se termine el tiempo. <b>Recuerda</b>, algunas caras pueden
    parecer sonrientes pero no lo son. :)`

};

export const DIALOG_CATEGORY_DATA = {
    buttonText: 'Como jugar?',
    title: 'Instrucciones',
    description: `El juego consiste en identificar que palabra no tiene relacion con las demás. Esto solo aplica 
    a las lineas <b>horizontales</b>`,
    rotateScreenRequired: `Para obtener la mejor experiencia es necesario inclinar horizontalmente tu dispositivo.`
};

export const DIALOG_MEMORY_DATA = {
    buttonText: 'Como jugar?',
    title: 'Instrucciones',
    description: `Aparece una combinación de colores en cierto orden, luego de unos instantes desaparecen los colores y aparecen en un orden diferente. Lo que se debe hacer es seleccionar los colores el orden correcto`,
}

export const DIALOG_SIGNOS_DATA = {
    buttonText: 'Como jugar?',
    title: 'Instrucciones',
    description: `El programa muestra tres números y se debe seleccionar la operación que se adecúa a la igualdad. Por ejemplo. 1 2 = 3 implica que la operación entre el 1 y el 2 sea el +`,
}

export const DIALOG_MEMORYCARD_DATA = {
    buttonText: 'Como jugar?',
    title: 'Instrucciones',
    description: 'El juego consiste en encontrar cada pareja de cartas antes de que se acabe el tiempo. Solo podrás ver dos cartas al mismo tiempo.'
}

