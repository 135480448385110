import CountryGameMode from '../Models/CountryGameMode';
import CountryGameParams from '../Models/CountryGameParams';
import ICountryGameBuilderStrategy from './Strategies/ICountryGameBuilderStrategy';
import StrategyMapper from './Strategies/StrategyMapper';

export default class CountryService {
  public buildGame(mode: CountryGameMode): CountryGameParams {
    const strategy = StrategyMapper.getStrategy(mode) as ICountryGameBuilderStrategy;

    return strategy.build(mode);
  }
}
