class Signos {
    currentLevelId: Number;

    exitosRequeridos: Number;

    idAlumno: Number;

    isPlayingLastLevel: Boolean;

    levelData: Array<any>;

    maximoPuntaje: Number;

    porcentajeProgreso: Number;

    progresoActual: Number;

    constructor (currentLevelId: Number,
        exitosRequeridos: Number,
        idAlumno: Number,
        isPlayingLastLevel: boolean,
        levelData: Array<any>,
        maximoPuntaje: Number,
        porcentajeProgreso: Number,
        progresoActual: Number) {

        this.currentLevelId = currentLevelId;
        this.exitosRequeridos = exitosRequeridos;
        this.idAlumno = idAlumno;
        this.isPlayingLastLevel = isPlayingLastLevel;
        this.levelData = levelData;
        this.maximoPuntaje = maximoPuntaje;
        this.porcentajeProgreso = porcentajeProgreso;
        this.progresoActual = progresoActual;
    }


}
export default Signos;