import Vue from 'vue';
import Vuex from 'vuex';
import VueLocalStorage from 'vue-ls';
import UserRoles from '@/core/Users/UserRoles';

Vue.use(Vuex);
Vue.use(VueLocalStorage);

const ls = VueLocalStorage;


export default new Vuex.Store({
  state: {
    isLogged: false,
    hasMobileSize: false,
    screenSize: 0,
    activeSubscription: {},
    userInfo: {} as any,
    mercadoPagoScript: null,
    susbcription: {},
  },
  mutations: {
    setIsLogged(state, value: boolean) {
      state.isLogged = value;
    },

    setIsMobile(state, value: boolean) {
      state.hasMobileSize = value;
    },

    setScreenSize(state, value: number) {
      state.screenSize = value;
    },

    setSubscription(state, value: any) {
      state.susbcription = value;
    },

    setUserInfo(state, value) {
      state.userInfo = value;
    },

    setMercadoPagoScript(state, value: HTMLScriptElement) {
      state.mercadoPagoScript = value;
    },
  },

  getters: {
    getIsLogged(state) {
      return state.isLogged;
    },
    getUserInfo(state) {
      return state.userInfo;
    },

    getSubscription(state) {
      return state.userInfo.subscription;
    },

    getUserId(state) {
      return state.userInfo.id;
    },

    getIsAdmin(state) {
      return state.userInfo.role === UserRoles.ADMIN;
    }
  },
  actions: {},
  modules: {},
});
