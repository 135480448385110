<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    :persistent="true"
    v-model="isActive"
  >
    <v-card>
      <v-card-title class="text-h3 grey lighten-2 mx-auto">
        <p class="mx-auto">{{ title }}</p>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          v-if="hasLevelCompleted"
          class="btn primary mb-2 mx-auto"
          @click="reload()"
          >{{ "Siguiente nivel" }}</v-btn
        >
        <v-btn
          v-if="!hasLevelCompleted"
          class="warning mb-2 ml-auto"
          @click="reload(true)"
          >Reintentar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ProgressModal",
    event: "reload",

    props: {
      isActive: {
        type: Boolean,
        required: true,
        default: false,
      },

      isLastLevel: {
        type: Boolean,
        required: true,
        default: false,
      },

      isFirstLevel: {
        type: Boolean,
        required: true,
        default: false,
      },

      progress: {
        type: Number,
        required: true,
        default: null,
      },
    },

    computed: {
      title() {
        return this.hasLevelCompleted ? "Has ganado!" : "Lo siento..";
      },

      hasLevelCompleted() {
        return this.progress === 100;
      },
    },

    methods: {
      reload(needLastLevel = false) {
        this.$emit("reload", needLastLevel);
      },
    },
  };
</script>