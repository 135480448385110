import GamePlatAttempt from './GamePlayAttempt';
import RequestStructure from '@/components/Request/Structures/RequestStructure';

export default class GamePerformanceStructure extends RequestStructure {

    public gameId: number;
    public averageScore: number;
    public averageTime: number;
    public averageAttempts: number;
    public averageCorrectAnswers: number;
    public averageWrongAnswers: number;
    public averageSkippedAnswers: number;
    public averageCorrectAnswersPercentage: number;
    public averageWrongAnswersPercentage: number;
    public averageSkippedAnswersPercentage: number;
    public attempts: Array<GamePlatAttempt> = [];
}