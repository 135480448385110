import { GoNoGoLevelConfiguration } from './GoNoGoLevelConfiguration';

/**
 * This function returns the amount of elements that will be shown in the game
 * The function should be replaced by a database query @TODO
 */
export const getAmountOfElements = (configuration: GoNoGoLevelConfiguration): number => {
  switch (configuration.targetUser) {
    case 'niño':
      return 8;
    case 'adolescente':
      return 10;
    case 'adulto':
      return 15;
    case 'adulto mayor':
      return 10;
    default:
      return 8;
  }
};
