import GoNoGoSubject from '../Structures/GoNoGoSubject';
import IGoNoGoModeStrategy from './IGoNoGoModeStrategy';

export default class GoNoGoInhibitionStrategy implements IGoNoGoModeStrategy {
  public static readonly MODE: string = 'inhibition';

  public isValid(currentSubject: GoNoGoSubject, receivedSubject: GoNoGoSubject): boolean {
    return currentSubject.id !== receivedSubject.id;
  }
}
