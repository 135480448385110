export default class FeatureFlag {
    public static readonly FF_CAN_SEE_GAME_LIST: string = 'games-list';
    public static readonly FF_CAN_SEE_MEMORY_GAME: string = 'memory-game';
    public static readonly FF_CAN_SEE_MEMORY_GAME_LEVEL_1: string = 'memory-game-level-1';
    public static readonly FF_CAN_SEE_LETRAS_GAME: string = 'letras-game';
    public static readonly FF_CAN_SEE_LETRAS_GAME_LEVEL_1: string = 'letras-game-level-1';
    public static readonly FF_CAN_SEE_SONRISAS_GAME: string = 'sonrisas-game';
    public static readonly FF_CAN_SEE_SONRISAS_GAME_LEVEL_1: string = 'sonrisas-game-level-1';
    public static readonly FF_HAS_UNLIMITED_GAME_ACCESS: string = 'unlimited-game-access';
    public static readonly FF_CAN_USE_CREATIVITY_GAME: string = 'creativity-game';
    public static readonly FF_CAN_USE_SIGNOS_GAME: string = 'signos-game';
    public static readonly FF_CAN_USE_CATEGORY_GAME: string = 'category-game';
    public static readonly FF_CAN_USE_PREGUNTAS_GAME: string = 'preguntas-game';
    public static readonly FF_CAN_USE_MATRIX_GAME: string = 'matrix-game';
    public static readonly FF_CAN_USE_CHALLENGE_GAME: string = 'challenge-game';
    public static readonly FF_CAN_USE_DUPLICADOS_GAME: string = 'duplicados-game';
}