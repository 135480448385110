import Subscription from '@/Models/User/Subscription';
import UserPermissionService from '@/core/Users/UserPermissionService';
import UserRoles from '@/core/Users/UserRoles';

class UserModel {
  public id: number;
  public userName: string;
  public email: string;
  public subscription: Subscription | null;
  public permissions: any = [];
  public role: string = '';
  public isLogged: boolean = false;
  public isGuest: boolean = false;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;

  constructor(params: any = {}) {
    this.id = params.id ?? null;
    this.userName = params.userName ?? 'Usuario';
    this.email = params.email ?? null;
    this.subscription = params.subscription ? new Subscription(params.subscription) : null;

    if (this.email) {
      this.isLogged = true;
      this.isGuest = false;
    } else {
      this.isLogged = false;
      this.isGuest = true;
      this.role = UserRoles.GUEST;
    }

    if(params.role === 2) {
      this.isAdmin = true;
      this.role = UserRoles.ADMIN;
    }

    this.permissions.push(UserPermissionService.getPermissions(this.role));
  }
}
export default UserModel;
