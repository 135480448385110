<template>
  <div>
    <v-card>
      <div>
        <h1 class="text-center">Factura </h1>
        <p class="text-center">({{ origin }})</p>
      </div>
      <v-card-text>
        <div class="d-flex flex-column w-75 mx-auto">
          <hr class="mx-auto w-75 h-5" />
          <div
            class="w-100 justify-content-around"
            v-for="product in order.products"
          >
            <div class="d-flex justify-between w-75 mx-auto">
              <span>1 x {{ ' ' + product.name }}</span>
              <span><b>$</b>{{ product.price }}</span>
            </div>
            <hr class="w-75 mx-auto" />
          </div>
          <div class="d-flex justify-between w-75 mx-auto">
            <h3 class="text-center">Total</h3>
            <span class="p-10 align-end">
              <b>${{ order.total }}</b></span
            >
          </div>
          <hr class="mx-auto w-75 h-5" />
        </div>

        <TicketBarCode class="mx-auto" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import Order from '../Structures/Order';
  import TicketBarCode from './TicketBarCode.vue';

  @Component({
    components: {
      TicketBarCode,
    },
  })
  export default class WaiterTicket extends Vue {
    @Prop() order: Order;
    @Prop() origin: string;

    total: string = null;
    rules;
  }
</script>

<style scoped>
  .h-5 {
    height: 5px;
    background-color: black;
    padding-left: 25%;
  }

  .w-75 {
    width: 75%;
  }

  .justify-content-around {
    justify-content: space-around;
  }

  .justify-between {
    justify-content: space-between;
  }
</style>
