<template>
  <div>
    <v-card
      class="mx-auto rounded-lg"
      max-width="900"
      max-height="600"
      outlined
      tile
    >
      <v-card-title class="text-center">
        <h1> {{ text }} </h1>
      </v-card-title>
      <v-card-text class="text-center">
        <h2> {{ recomendation }} </h2>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component
  export default class WaiterPreparationScene extends Vue {
    text: string = 'Es momento de preparar la orden';
    recomendation: string = 'Recuerda que tienes un tiempo límite para preparar la orden';
  }
</script>
