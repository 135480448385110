export const contexts = ['vehículos', 'hospital', 'zoo', 'supermercado', 'parque', 'escuela', 'hogar', 'calle'];
const contextsHistory = [
  {
    scenario: 'vehículos',
    text: 'En la calle hay muchos vehículos. Hay autos, camiones, motos, bicicletas, colectivos, ambulancias, taxis, trenes, aviones, helicópteros, barcos, lanchas, etc.',
  },
  {
    scenario: 'hospital',
    text: 'En el hospital hay médicos, enfermeros, camillas, sillas de ruedas, camas, medicamentos, etc.',
  },
  {
    scenario: 'zoo',
    text: 'En el zoológico hay animales como leones, tigres, jirafas, elefantes, monos, etc.',
  },
  {
    scenario: 'supermercado',
    text: 'En el supermercado hay frutas, verduras, carnes, lácteos, galletitas, golosinas, etc.',
  },
  {
    scenario: 'parque',
    text: 'En el parque hay árboles, plantas, flores, bancos, pelotas y toboganes etc. Cuando aparezca una pelota debes seleccionar un tobogán y cuando aparezca un tobogán, selecciona una pelota. Mucha Suerte!!',
  },
  {
    scenario: 'escuela',
    text: 'En la escuela hay maestros, alumnos, pupitres, pizarrones, lápices, gomas, etc. Si tenemos un lápiz, precisamos una goma pero cuando tenemos la goma, necesitamos el lápiz. Entonces, cuando aparezca una goma debes seleccionar el lápiz y cuando aparezca el lápiz, selecciona la goma. Mucha Suerte!!',
  },
  {
    scenario: 'hogar',
    text: 'En el hogar hay una mesa, sillas, una cama, una cocina, una heladera, etc.',
  },
  {
    scenario: 'calle',
    text: 'En la calle hay autos, camiones, motos, bicicletas, colectivos, ambulancias, taxis, etc.',
  },
];

/**
 * @throws Error if the context is not found
 */
export const getHistory = (context: string): string => {
  const history = contextsHistory.find((history) => {
    return history.scenario === context.toLowerCase();
  });

  if (history === undefined) {
    throw new Error('No se encontró el contexto');
  }

  return history.text;
};

const getHistoriesByContext = (context: string): Object => {
  const histories = contextsHistory.find((history) => {
    return history.scenario === context;
  });

  return histories;
};

export const levelBySpeed = {
  slow: 1,
  medium: 2,
  fast: 3,
};

export const levels = {
  1: 'Fácil',
  2: 'Medio',
  3: 'Difícil',
};
