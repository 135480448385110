<template lang="">
  <div class="d-flex justify-center w-100">
    <canvas :id="id"></canvas>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import {
    Chart,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
  } from 'chart.js';
  import { Component, Prop } from 'vue-property-decorator';
  @Component({})
  export default class CustomLineChart extends Vue {
    @Prop({ required: true }) id: string;
    @Prop({ required: true }) data: any;

    _chart: Chart;
    _canvas: any;

    constructor() {
      super();
      Chart.register(
        Title,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
        LineElement,
        PointElement,
        LineController
      );
    }

    mounted() {
      this._canvas = document.getElementById(this.id);
      this._chart = new Chart(this._canvas, {
        type: 'line',
        data: this.data,
        options: this.data.options,
      });
    }
  }
</script>
