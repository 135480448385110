<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    :persistent="true"
    v-model="isActive"
  >
    <v-card>
      <v-card-title class="text-h3 grey lighten-2 mx-auto">
        <p class="mx-auto">Muy bien!</p>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="d-flex mx-auto justify-space-between">
          <v-btn class="primary mb-2 mx-auto" @click="reload()"
            >Volver al nivel actual</v-btn
          >
          <v-btn
            v-if="isLastLevel"
            class="warning mb-2 mx-auto"
            @click="reload(true)"
            >Jugar este nivel otra vez</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "RepeatLevelModal",
    event: "reload",

    props: {
      isActive: {
        type: Boolean,
        required: true,
        default: false,
      },

      isLastLevel: {
        type: Boolean,
        required: true,
        default: false,
      },

      isFirstLevel: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    methods: {
      reload(needLastLevel = false) {
        this.$emit("reload", needLastLevel);
      },
    },
  };
</script>