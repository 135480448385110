import GoNoGoSubject from './Structures/GoNoGoSubject';
import { GoNoGoLevelConfiguration } from './GoNoGoLevelConfiguration';
import GoNoGoInhibitionStrategy from './Strategies/GoNoGoInhibitionStrategy';
import GoNoGoSelectiveStrategy from './Strategies/GoNoGoSelectiveStrategy';
import IGoNoGoModeStrategy from './Strategies/IGoNoGoModeStrategy';
import GoNoGoSwitchingStrategy from './Strategies/GoNoGoSwitchingStrategy';

export default class GoNoGoActionValidatorService {
  private configuration: GoNoGoLevelConfiguration;

  constructor(configuration: GoNoGoLevelConfiguration) {
    this.configuration = configuration;
  }

  public isValid(currentSubject: GoNoGoSubject, receivedSubject: GoNoGoSubject): boolean {
    const strategy: IGoNoGoModeStrategy = this.getStrategyByConfiguration();

    return strategy.isValid(currentSubject, receivedSubject);
  }

  private getStrategyByConfiguration(): IGoNoGoModeStrategy {
    switch (this.configuration.mode) {
      case GoNoGoLevelConfiguration.MODES.INHIBITION:
        return new GoNoGoInhibitionStrategy();
      case GoNoGoLevelConfiguration.MODES.SELECTIVE:
        return new GoNoGoSelectiveStrategy();
      case GoNoGoLevelConfiguration.MODES.SWITCHING:
        return new GoNoGoSwitchingStrategy();
      default:
        throw new Error('The mode is not valid');
    }
  }
}
