import GamePerformanceStructure from './Structure/GamePerformanceStructure';
import GamePlayAttempt from './Structure/GamePlayAttempt';
import GamePlayResult from './Structure/GamePlayResult';
import GamePerformanceRepository from './Repositories/GamePerformanceRepository';

export class GamePerformanceService {
  private static instance: GamePerformanceService;
  private gamePerformance: GamePerformanceStructure;
  private unixTimeStart: number = 0;
  private unixTimeEnd: number = 0;
  private assertionCount: number = 0;
  private errorCount: number = 0;
  private skippedCount: number = 0;
  public currentAttempt: GamePlayAttempt;
  public gameResult: GamePlayResult;
  private performanceRepository: GamePerformanceRepository;

  constructor() {
    this.gamePerformance = new GamePerformanceStructure();
    this.performanceRepository = new GamePerformanceRepository();
    this.setCurrentAttempt();
  }

  public static getInstance(): GamePerformanceService {
    if (!GamePerformanceService.instance) {
      GamePerformanceService.instance = new GamePerformanceService();
    }

    return GamePerformanceService.instance;
  }

  public setGameId(gameId: number): void {
    this.gamePerformance.gameId = gameId;
  }

  public getGamePerformance(): GamePerformanceStructure {
    return this.gamePerformance;
  }

  public setGamePerformance(gamePerformance: GamePerformanceStructure): void {
    this.gamePerformance = gamePerformance;
  }

  public trackGameStart(): void {
    this.unixTimeStart = Date.now();
  }

  public trackGameEnd(): void {
    this.unixTimeEnd = Date.now();
  }

  public getGameDuration(): number {
    return this.unixTimeEnd - this.unixTimeStart;
  }

  public addAssertion(): void {
    this.assertionCount++;
  }

  public addError(): void {
    this.errorCount++;
  }

  public setCurrentAttempt(): void {
    this.currentAttempt = new GamePlayAttempt();
  }

  public trackAttemptStart(): void {
    this.currentAttempt.startTimestamp = Date.now();
  }

  public trackAttemptEnd(): void {
    this.currentAttempt.endTimestamp = Date.now();
  }

  public setAttemptResult(result: number): void {
    this.currentAttempt.result = result;
  }

  public collectAttempt(): void {
    this.gamePerformance.attempts.push(this.currentAttempt);
    this.setCurrentAttempt();
  }

  public calculateGamePerformance(): void {
    this.gamePerformance.averageScore = this.calculateAverageScore();
    this.gamePerformance.averageTime = this.calculateAverageTime();
    this.gamePerformance.averageAttempts = this.calculateAverageAttempts();
    this.gamePerformance.averageCorrectAnswers = this.calculateAverageCorrectAnswers();
    this.gamePerformance.averageWrongAnswers = this.calculateAverageWrongAnswers();
    this.gamePerformance.averageSkippedAnswers = this.calculateAverageSkippedAnswers();
    this.gamePerformance.averageCorrectAnswersPercentage = this.calculateAverageCorrectAnswersPercentage();
    this.gamePerformance.averageWrongAnswersPercentage = this.calculateAverageWrongAnswersPercentage();
    this.gamePerformance.averageSkippedAnswersPercentage = this.calculateAverageSkippedAnswersPercentage();
  }

  private calculateAverageScore(): number {
    let sum = 0;
    this.gamePerformance.attempts.forEach((attempt) => {
      sum += attempt.result;
    });
    return sum / this.gamePerformance.attempts.length;
  }

  private calculateAverageTime(): number {
    let sum = 0;
    this.gamePerformance.attempts.forEach((attempt) => {
      sum += attempt.endTimestamp - attempt.startTimestamp;
    });
    return sum / this.gamePerformance.attempts.length;
  }

  private calculateAverageAttempts(): number {
    return this.gamePerformance.attempts.length;
  }

  private calculateAverageCorrectAnswers(): number {
    let sum = 0;
    this.gamePerformance.attempts.forEach((attempt) => {
      sum += attempt.result;
    });
    return sum / this.gamePerformance.attempts.length;
  }

  private calculateAverageWrongAnswers(): number {
    let sum = 0;
    this.gamePerformance.attempts.forEach((attempt) => {
      sum += 1 - attempt.result;
    });
    return sum / this.gamePerformance.attempts.length;
  }

  private calculateAverageSkippedAnswers(): number {
    return 0;
  }

  private calculateAverageCorrectAnswersPercentage(): number {
    return this.calculateAverageCorrectAnswers() * 100;
  }

  private calculateAverageWrongAnswersPercentage(): number {
    return this.calculateAverageWrongAnswers() * 100;
  }

  private calculateAverageSkippedAnswersPercentage(): number {
    return 0;
  }

  public reset(): void {
    this.gamePerformance = new GamePerformanceStructure();
    this.unixTimeStart = 0;
    this.unixTimeEnd = 0;
    this.assertionCount = 0;
    this.errorCount = 0;
    this.skippedCount = 0;
    this.setCurrentAttempt();
  }

  public saveGamePerformance(): void {
    this.performanceRepository.saveGamePerformance(this.gamePerformance);
  }

  /**
   * @returns string in format mm:ss
   * example: 01:30
   */
  public getGameDurationFormatted(): string {
    const duration = this.getGameDuration();
    const minutes = Math.floor(duration / 60000);
    const seconds = ((duration % 60000) / 1000).toFixed(0);
    return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds;
  }
}
