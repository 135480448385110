<template lang="">
  <div>
    <!-- Game title -->
    <h1 class="h1 text-center">Ecuaciones</h1>

    <!-- Game description -->
    <v-card-subtitle class="description">
      <h4 class="font-weight-bold">Descripción</h4>
      <p>
        El juego consiste en resolver ecuaciones de primer grado. El jugador debe seleccionar el valor de la incógnita y
        hacer click en el botón comprobar para verificar si la ecuación es verdadera.
      </p>
      <h4 class="font-weight-bold">Dificultad</h4>
      <p> El juego cuenta con 3 niveles de dificultad. Debes seleccionar el nivel que desees jugar. </p>
    </v-card-subtitle>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({})
  export default class EcuationDescriptor extends Vue {}
</script>
