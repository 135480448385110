import GoNoGoSubjectDataMapperService from "../GoNoGoSubjectDataMapperService";
import GoNoGoSubject from "../Structures/GoNoGoSubject";

export default class GoNoGoSubjectBuilder {
    public build(name: string): GoNoGoSubject {
        let subject = new GoNoGoSubject(name);
        subject.imageUrl = GoNoGoSubjectDataMapperService.getSubjectImageUrl(name);

        return subject;
    }
}