<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" dark v-bind="attrs" v-on="on">
          {{ buttonText }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>
        <br />
        <v-card-text v-html="description"> </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="visible = false"> Entendido </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'CustomDialog',

    props: {
      buttonText: {
        type: String,
        required: true,
        default: '',
      },

      title: {
        type: String,
        required: true,
        default: '',
      },

      description: {
        type: String,
        required: true,
        default: '',
      },

      isVisible: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    data() {
      return {
        visible: this.isVisible,
      };
    },
  };
</script>
