<template>
  <div>
    <v-select
      v-model="selectedLevel"
      :items="levels"
      label="Nivel"
      dense
      outlined
      class="mx-2"
    ></v-select>

    <v-select
      v-model="selectedMode"
      :items="modes"
      label="Modo"
      dense
      outlined
      class="mx-2"
    ></v-select>

    <div class="d-flex w-100">
      <v-btn
        :disabled="!isGameReady"
        @click="pushGame"
        class="mx-2 mx-auto"
        color="primary"
        dark
        outlined
      >
        Jugar
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import CountryGameMode from '../Models/CountryGameMode';

  @Component
  export default class CountryGameSelector extends Vue {
    levels: number[] = [1, 2, 3];
    modes: string[] = ['Regiones', 'Capitales', 'Banderas'];

    selectedLevel: number = null;
    selectedMode: string = null;

    get isLevelSelected(): boolean {
      return this.selectedLevel !== null;
    }

    get isModeSelected(): boolean {
      return this.selectedMode !== null;
    }

    get isGameReady(): boolean {
      return this.isLevelSelected && this.isModeSelected;
    }

    public pushGame(): void {
      if (!this.isGameReady) {
        console.error('Selecciona un nivel y un modo de juego');
        return;
      }

      this.$emit('push-game', new CountryGameMode(this.selectedMode, this.selectedLevel));
    }
  }
</script>

<style scoped></style>
