<template lang="">
  <div>
    <v-dialog
      v-model="isVisible"
      max-width="500px"
      @click:outside="$emit('close-modal')"
    >
      <v-card>
        <v-card-title>
          <span class="">{{ title }}</span>
          <v-btn class="ml-auto" icon large @click="$emit('close-modal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle class=" mt-5 mb-3">
          <span class="">{{ description }}</span>
        </v-card-subtitle>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ContainerModal extends Vue {
    @Prop({ type: Boolean, required: true }) isVisible!: boolean;
    @Prop({ type: String, required: true }) title!: string;
    @Prop({ type: String, required: true }) description!: string;
    @Prop({ type: String, required: true }) buttonText!: string;
  }
</script>
<style lang=""></style>
