var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap"},[_c('v-app-bar',{attrs:{"elevation":"4","outlined":"","prominent":"","rounded":"","shaped":"","height":"auto"}},[_c('v-container',{attrs:{"grid-list-xs":""}},[_c('div',{staticClass:"d-flex w-100 rounded"},[_c('div',{staticClass:"d-md-inline-flex d-flex justify-end w-100"},[_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"d-flex mr-md-auto mx-md-0 align-center"},[_c('logo')],1)]),(!_vm.isLogged)?_c('div',{staticClass:"d-flex justify-end mt-4"},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"d-none d-md-flex"},[(_vm.isLogged)?_c('button-loader',{staticClass:"ml-auto mr-4 mt-1",attrs:{"buttonText":'Cerrar sesión',"animationTime":2000},on:{"event":function($event){return _vm.logout()}}}):_vm._e()],1),_c('div',{staticClass:"d-flex d-md-none"},[(_vm.isLogged)?_c('button-loader',{staticClass:"ml-auto mt-1",attrs:{"buttonText":'Salir',"animationTime":2000},on:{"event":function($event){return _vm.logout()}}}):_vm._e()],1),_c('div',{staticClass:"d-none d-md-flex mt-1"},[(_vm.isLogged && _vm.userData)?_c('div',{staticClass:"d-flex"},[_c('h5',{staticClass:"ml-auto mr-2"},[_vm._v("Bienvenido, "+_vm._s(_vm.userData.userName))])]):_vm._e()])]),_c('div',{staticClass:"ml-4"},[(!typeof _vm.group == 'undefined')?_c('p',{staticClass:"text-lg-left"},[_vm._v(_vm._s(_vm.actualGame))]):_vm._e()])]),_c('div',{staticClass:"d-flex mt-5"},[_c('v-tabs',{staticClass:"justify-center d-none d-md-flex",attrs:{"slider-size":"3","align-with-title":"","grow":"","centered":"","show-arrows":""}},_vm._l((_vm.items),function(item){return (
              (item.mustBeLogged && _vm.isLogged) ||
              (item.mustBeUnLogged && !_vm.isLogged) ||
              (!item.mustBeLogged && !item.mustBeUnLogged)
            )?_c('v-tab',{key:item.id,ref:item.view,refInFor:true,attrs:{"to":item.view}},[_vm._v(" "+_vm._s(item.key)+" ")]):_vm._e()}),1),_c('v-tabs',{ref:"sliderMobile",staticClass:"d-flex d-md-none w-100 justify-center",attrs:{"id":"sliderMobile"}},_vm._l((_vm.mobileItems),function(mobileItem){return (
              (mobileItem.mustBeLogged && _vm.isLogged) ||
              (mobileItem.mustBeUnLogged && !_vm.isLogged) ||
              (!mobileItem.mustBeLogged && !mobileItem.mustBeUnLogged)
            )?_c('v-tab',{key:mobileItem.id,staticClass:"d-flex",staticStyle:{"max-width":"90px"},attrs:{"to":mobileItem.view},on:{"click":function($event){return _vm.setTab(mobileItem.id)}}},[_c('v-icon',[_vm._v(_vm._s(mobileItem.icon))])],1):_vm._e()}),1)],1)])],1),_c('div',{staticClass:"d-flex justify-center align-center bg-blur bg-white w-100"},[_c('router-view')],1),(_vm.tab === 7 && _vm.isMobile)?_c('div',{staticClass:"d-flex w-100"},[_c('v-card',{staticClass:"w-100 justify-center"},[_c('v-list',{staticClass:"w-75 mx-auto"},_vm._l((_vm.mobileExtraItems),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.setTab(item.id)}}},[_c('div',{staticClass:"d-flex mx-auto justify-center align-center"},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-text',{staticStyle:{"min-width":"100px"}},[_vm._v(_vm._s(item.key))])],1)])}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }