import { getHistory } from './GoNoGoHistoryMapper';
import GoNoGoHistoryScenario from './GoNoGoHistoryScenario';
import { GoNoGoLevelConfiguration } from './GoNoGoLevelConfiguration';
import { getAmountOfElements } from './GoNoGoLevelMapping';
import GoNoGoBehaviorBuilder from './Builders/GoNoGoBehaviorBuilder';
import GoNoGoBehavior from './Structures/GoNoGoBehavior';

export default class GoNoGoBuilderGameService {
  private configuration: GoNoGoLevelConfiguration;
  private behaviorBuilder: GoNoGoBehaviorBuilder;

  constructor(configuration: GoNoGoLevelConfiguration) {
    this.configuration = configuration;
    this.behaviorBuilder = new GoNoGoBehaviorBuilder(configuration);

    this.buildHistory();
    this.setAmountOfElements();
  }

  public getGameConfiguration(): GoNoGoLevelConfiguration {
    return this.configuration;
  }

  public buildBehavior(): GoNoGoBehavior {
    const selectedContext = this.configuration.context;

    const subjects = this.getSubjectsByConfigurationContext(selectedContext);
    
    return this.behaviorBuilder.build(subjects[0], subjects[1]);
  }

  private buildHistory(): void {
    this.configuration.history = new GoNoGoHistoryScenario(
      this.configuration.context,
      getHistory(this.configuration.context)
    );
  }

  private setAmountOfElements(): void {
    this.configuration.amountOfElements = getAmountOfElements(this.configuration);
  }

  private getSubjectsByConfigurationContext(context: string): string[] {
    switch (context.toLowerCase()) {
      case 'cars':
        return ['car', 'animal'];
      case 'animals':
        return ['animal', 'car'];
      case 'fruits':
        return ['fruit', 'vegetable'];
      case 'vegetables':
        return ['vegetable', 'fruit'];
      case 'colors':
        return ['color', 'shape'];
      case 'shapes':
        return ['shape', 'color'];
      case 'parque':
        return ['pelota', 'tobogan'];
        case 'escuela':
        return ['lapiz', 'goma'];
      default:
        return ['car', 'animal'];
    }
  }
}
