<template>
  <v-dialog
    :overlay="false"
    max-width="500px"
    transition="dialog-bottom-transition"
    :value="value"
    @click:outside="$emit('close-modal')"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!isMobile"
        :id="btnId"
        class="d-md-flex d-sm-block my-1 mx-sm-auto mx-md-0"
        small
        text
        plain
        :color="buttonColor"
        text-color="black"
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonText }}
      </v-btn>
      <v-btn
        v-else
        :id="btnId"
        class="d-md-flex d-sm-block my-1 mx-sm-auto mx-md-0"
        small
        icon
        :color="buttonColor"
        text-color="black"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <slot></slot>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DefaultModal',

    props: {
      buttonText: {
        type: String,
        required: false,
        default: '',
      },
      buttonColor: {
        type: String,
        required: false,
        default: '',
      },
      btnId: {
        type: String,
        required: false,
        default: '',
      },

      icon: {
        type: String,
        required: false,
        default: '',
      },

      isMobile: {
        type: Boolean,
        required: true,
      },

      value: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>
