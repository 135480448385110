
import http from '@/helpers/https';
import { AxiosInstance } from 'axios';
export default class CognosApi {

    private axios?: AxiosInstance;
    constructor () {
        this.axios = http();
        this.setApiInstance();
    }
        
        
    private setApiInstance() {
        this.axios.defaults.baseURL = process.env.NODE_ENV === 'production'
            ? 'https://api.cognosmemory.com/'
            : 'http://localhost:3000/';
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    public async get(url: string) {

        const response = await this.axios.get(url);

        return response.data;
    }

    public async post(url: string, data: any) {
      const response = await this.axios.post(url, data);

      return response.data;
    }
}
