import CountryGameMode from '../../Models/CountryGameMode';
import CapitalCountryGameBuilderStrategy from './CapitalCountryGameBuilderStrategy';
import FlagCountryGameBuilderStrategy from './FlagCountryGameBuilderStrategy';
import RegionCountryGameBuilderStrategy from './RegionCountryGameBuilderStrategy';

export default class StrategyMapper {
  static getStrategy(mode: CountryGameMode): any {
    switch (mode.name) {
      case 'Regiones':
        return new RegionCountryGameBuilderStrategy();
      case 'Capitales':
        return new CapitalCountryGameBuilderStrategy();
      case 'Banderas':
        return new FlagCountryGameBuilderStrategy();
      case 'Poblacion':
        throw new Error('Not implemented yet.');
      default:
        throw new Error('Invalid game mode:' + mode.name + ' for Country game.');
    }
  }
}
