<template>
  <div>
    <v-card
      class="px-5 py-1 my-1 mx-5"
      max-width="200"
      min-width="200"
      max-height="200"
      min-height="200"
    >
      <v-img
        class="my-auto mt-5 softBoder rounded"
        :src="flag"
        max-height="100"
        min-height="100"
      ></v-img>

      <v-card-subtitle class="mb-5">{{ name }}</v-card-subtitle>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class TheCountry extends Vue {
    @Prop({ type: String, required: true }) name!: string;
    @Prop({ type: String, required: true }) flag!: string;
  }
</script>
