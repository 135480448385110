import { render, staticRenderFns } from "./DashboardDefault.vue?vue&type=template&id=87d8f322"
import script from "./DashboardDefault.vue?vue&type=script&lang=ts"
export * from "./DashboardDefault.vue?vue&type=script&lang=ts"
import style0 from "./DashboardDefault.vue?vue&type=style&index=0&id=87d8f322&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports