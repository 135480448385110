import Ecuation from './Ecuation';
import EcuationLevelInterface from './EcuationLevelInterface';
import EcuationLevelHelper from './Helpers/EcuationLevelHelper';

export default class EcuationLevelFour implements EcuationLevelInterface {
  public get(): Ecuation {
    let a = EcuationLevelHelper.getRandomNumber(-99, 99);
    let c = EcuationLevelHelper.getRandomNumber(-99, 99);

    while (a - c == 0) {
      a = EcuationLevelHelper.getRandomNumber(-99, 99);
      c = EcuationLevelHelper.getRandomNumber(-99, 99);
    }

    let d = EcuationLevelHelper.getRandomNumber(-99, 99);
    let b = EcuationLevelHelper.getRandomNumber(-99, 99);

    while ((d - b) % (a - c) != 0) {
      d = EcuationLevelHelper.getRandomNumber(-99, 99);
      b = EcuationLevelHelper.getRandomNumber(-99, 99);
    }

    const ecuation = new Ecuation();
    ecuation.title = 'Ecuación de nivel cuatro';
    ecuation.description = 'Resuelve la ecuación de primer grado';
    ecuation.answer = (d - b) / (a - c);

    if (b < 0 && d >= 0) {
      b = -1 * b;
      ecuation.ecuation = a + 'x - ' + b + ' = ' + c + 'x + ' + d;
    } else if (d < 0 && b >= 0) {
      d = -1 * d;
      ecuation.ecuation = a + 'x + ' + b + ' = ' + c + 'x - ' + d;
    } else if (d < 0 && b < 0) {
      d = -1 * d;
      b = -1 * b;
      ecuation.ecuation = a + 'x - ' + b + ' = ' + c + 'x - ' + d;
    } else {
      ecuation.ecuation = a + 'x + ' + b + ' = ' + c + 'x + ' + d;
    }

    return ecuation;
  }
}
