<template>
  <div class="d-flex mt-5 mx-auto justify-center">
    <div
      v-for="item in items"
      :key="item.id"
      class="mt-5 square col-sm-3 col-xs-6"
    >
      <v-sheet
        :id="'MemoryColourItem:' + item.id"
        elevation="12"
        height="30"
        width="30"
        :class="item.color"
        class="rounded box-shadow"
        @click="clickItem(item)"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ItemList',
    event: 'onClickItem',

    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
    },

    methods: {
      clickItem(item) {
        this.$emit('onClickItem', item);
      },
    },
  };
</script>
