<template>
  <div>
    <div
      v-if="!isStarted"
      class="d-flex justify-center"
    >
      <v-sheet
        class="rounded justify-center"
        width="105"
      >
        <div class="d-flex mx-auto level">
          <trophy-item
            v-if="levelId"
            :level="levelId"
            color="blue"
          />
        </div>
      </v-sheet>
    </div>
    <div
      v-if="isStarted"
      class="d-flex justify-center"
    >
      <button-loader
        class="ml-auto mr-4"
        :color="'red'"
        :buttonText="'Abandonar challenge'"
        @event="skipChallenge()"
      />
    </div>
    <div
      v-if="!isStarted"
      class="d-flex justify-center my-5"
    >
      <button-loader
        :buttonText="'Comenzar challenge'"
        @event="initChallenge"
      />
    </div>
    <memory-colour
      v-if="isStarted && currentStep === 0 && levelId !== null"
      :challengeLevel="levelId"
      :isOnChallenge="true"
      @on-finish-challenge="addResults"
    />
    <signos
      v-if="isStarted && currentStep === 1"
      :isOnChallenge="true"
      @on-finish-challenge="addResults"
    />
    <smiler
      v-if="isStarted && currentStep === 2"
      :challengeLevel="levelId"
      :isOnChallenge="true"
      @on-finish-challenge="addResults"
    />
    <questions
      v-if="isStarted && currentStep === 3"
      :challengeLevel="levelId"
      :isOnChallenge="true"
      @on-finish-challenge="addResults"
      @skip-challenge="currentStep++"
    />
    <category
      v-if="isStarted && currentStep === 4"
      :challengeLevel="levelId"
      :isOnChallenge="true"
      @on-finish-challenge="addResults"
    />
    <table-custom
      v-if="isFinalStep"
      :params="challengeResults"
      @on-finish-review="nextLevel()"
    />
  </div>
</template>

<script>
  import Global from '@/helpers/Global';
  import http from '@/helpers/https';
  import MemoryColour from './MemoryColour.vue';
  import ButtonLoader from './Misc/ButtonLoader.vue';
  import Questions from './Questions.vue';
  import Category from './Category.vue';
  import Signos from './Signos.vue';
  import TableCustom from './Tables/TableCustom.vue';
  import TrophyItem from './Misc/TrophyItem.vue';
  import Smiler from './Smiler.vue';
  export default {
    name: 'ChallengeBasic',
    components: {
      MemoryColour,
      ButtonLoader,
      Signos,
      TableCustom,
      Questions,
      Category,
      TrophyItem,
      Smiler,
    },

    data() {
      return {
        isStarted: false,
        currentStep: 0,
        challengeData: [],
        finalStep: 4,
        levelId: null,
        challengeResults: [],
      };
    },

    computed: {
      isFinalStep() {
        return this.currentStep === this.finalStep;
      },
    },

    async created() {
      if (!this.levelId) {
        await this.setChallengeLevel();
      }
    },

    methods: {
      initChallenge() {
        const requestInstance = http();
        requestInstance
          .post(
            Global.resolveUrl(process.env.NODE_ENV) +
              'IntelectoController/ChallengeController.php',
            {
              //dev route
              action: 'init_game',
              levelId: this.levelId,
              hashDev: Global.hashDev(),
            }
          )
          .then(
            function (response) {
              if (response.data['error']) {
                this.onErrorModal(response.data['error']);
                this.nextLevel();
                return;
              }
              this.challengeData = response.data.challenge;
              this.levelId = this.challengeData['id'];
              this.isStarted = true;
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      },

      async setChallengeLevel() {
        const requestInstance = http();

        requestInstance
          .post(
            Global.resolveUrl(process.env.NODE_ENV) +
              'IntelectoController/ChallengeController.php',
            {
              //dev route
              action: 'set_level',
              levelId: this.levelId,
              hashDev: Global.hashDev(),
            }
          )
          .then((response) => {
            this.levelId = response.data;
          });
      },

      async addResults(data) {
        this.challengeResults.push({ name: data.game, total: data.total });
        this.currentStep++;
        if (this.isFinalStep) {
          await this.saveChallenge();
        }
      },

      async saveChallenge() {
        const request = http();
        request
          .post(
            Global.resolveUrl(process.env.NODE_ENV) +
              'IntelectoController/ChallengeController.php',
            {
              action: 'save_user_results',
              data: JSON.stringify(this.challengeResults),
              levelId: this.levelId,
              hashDev: Global.hashDev(),
            }
          )
          .then(() => {})
          .catch(function (error) {
            console.log(error.response.data);
          });
      },

      async nextLevel() {
        this.levelId++;
        this.initChallenge();
        this.currentStep = 0;
      },

      skipChallenge() {
        this.$emit('skip-challenge');
      },
    },
  };
</script>
<style scoped>
  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }

    50% {
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-20px);
    }

    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }

  .level {
    box-sizing: border-box;
    border: 5px white solid;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
    animation: float 5s ease-in-out infinite;
  }
</style>
