import ChartDataInterface from "../../Interfaces/ChartDataInterface";

class ChartDataStructure implements ChartDataInterface {
    labels: string[] = [];
    datasets: any[] = [];
    options = {
        plugins: {
            title: {
                display: true,
                text: '',
                font: {
                    size: 36
                },
            },
            legend: {
                title: {
                    display: true
                },
                display: false
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                weight: 10
            }
        },
    };

    public setText(text: string): void {
        this.options.plugins.title.text = text
    }
};

export default ChartDataStructure;