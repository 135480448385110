<template>
  <div>
    <v-banner
      color="yellow darken-2"
      elevation="20"
      icon="mdi-chart-line"
      outlined
      shaped
    >
      <h3 class="my-5">Hey!</h3>
      <p class="">
        Hasta el momento no tenemos estadisticas para presentarte, ni bien comiences a utilizar nuestros juegos de
        desarrollo y mantenimiento cognitivo vamos a poder mostrarte tus logros y los puntos en los cuales debes
        enfocarte más. Nos vemos pronto!
      </p>
    </v-banner>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({})
  export default class DashboardEmpty extends Vue {}
</script>
