<template>
  <div
    v-if="renderComponent"
    class="d-flex"
  >
    <custom-dialog-modal
      :title="'Ouch!'"
      :isVisible="showNotFoundModal"
      :description="notFoundMessage"
      @close-modal="showNotFoundModal = false"
    />
    <div
      v-if="showStartingButton"
      class="d-flex align-center justify-center my-auto mx-auto"
    >
      <div class="py-2">
        <div class="py-1">
          <p class="h3">Elige una categoria</p>
          <v-radio-group v-model="subjectSelected">
            <v-radio
              v-for="categoria in categorias"
              :key="categoria.id"
              :label="`${categoria.title}`"
              :value="categoria.value"
            ></v-radio>
          </v-radio-group>
        </div>
        <button-loader @event="showGame()" />
      </div>
    </div>
    <div
      id="game"
      v-if="isGameStarted"
      class="mx-auto my-auto"
    >
      <div class="d-flex justify-center my-2">
        <p class="h3 my-auto mx-1">{{ subjectTitle }}</p>
        <level-progress
          v-if="isGameStarted"
          :max-value="50"
          :width="5"
          :value="progress"
        />
      </div>
      <div
        v-if="!isGameOver"
        class="container align-center"
      >
        <div class="d-flex align-center mx-auto py-1">
          <div class="d-flex mx-auto">
            <v-chip
              color="green-light"
              class="text--darken-2"
            >
              {{ 'Cantidad de preguntas: ' + this.localParams.length }}
            </v-chip>
          </div>
        </div>
        <div class="d-flex align-center mx-auto py-1">
          <div class="d-flex mx-auto">
            <v-chip
              color="green-light"
              class="green"
            >
              {{ 'Preguntas acertadas: ' + this.assertions }}
            </v-chip>
          </div>
        </div>
      </div>
      <div class="d-flex w-50 mx-auto my-auto justify-center">
        <v-skeleton-loader
          v-if="isLoading"
          class="mx-auto"
          max-width="300"
          min-width="300"
          type="image"
        />
        <div>
          <div class="my-4">
            <question
              v-if="!isLoading && currentQuestion"
              :question="currentQuestion"
            />
          </div>
          <v-radio-group v-model="selectedAnswer">
            <v-radio
              v-for="option in this.options"
              :key="option.id"
              :label="`${option}`"
              :value="option"
            ></v-radio>
          </v-radio-group>
          <div
            v-if="!isLoading"
            class="d-flex w-100 justify-center"
          >
            <v-btn
              elevation="2"
              @click="validate()"
              :disabled="!selectedAnswer"
              >Enviar</v-btn
            >
          </div>
        </div>
      </div>
      <div>
        <ExerciseWinnerModal
          :is-visible="isWinner"
          @callback="reload(false)"
          @repeat="onRepeat"
          @close-modal="reload(false)"
        />

        <ExerciseLoserModal
          :is-visible="isLoser"
          @callback="reload(false)"
          @repeat="onRepeat"
          @close-modal="reload(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import scrollToGame from '@/helpers/GameHelper';
  import QuestionItem from '../Models/Questions/QuestionItem';
  import Global from '@/helpers/Global';
  import http from '@/helpers/https';
  import CustomDialogModal from './Misc/CustomDialogModal.vue';
  import ButtonLoader from './Misc/ButtonLoader.vue';
  import LevelProgress from './Misc/LevelProgress.vue';
  import { roundDecimal, shuffleArray } from '@/helpers/Utils';
  import ExerciseWinnerModal from './Misc/Exercises/Modals/ExerciseWinnerModal.vue';
  import ExerciseLoserModal from './Misc/Exercises/Modals/ExerciseLoserModal.vue';
  import Question from './Games/Questions/Question.vue';

  export default {
    components: {
      CustomDialogModal,
      ButtonLoader,
      LevelProgress,
      ExerciseWinnerModal,
      ExerciseLoserModal,
      Question,
    },
    name: 'Questions',

    props: {
      isOnChallenge: {
        type: Boolean,
        required: false,
        default: false,
      },

      challengeLevel: {
        type: Number,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        renderComponent: true,
        categorias: [
          { title: 'Aleatorio', value: 'aleatorio' },
          { title: 'Matemáticas', value: 'matemáticas' },
          { title: 'Razonamiento', value: 'razonamiento' },
        ],
        subjectSelected: '',
        options: [],
        answer: '',
        selectedAnswer: null,
        params: [],
        questionItem: {},
        assertions: 0,
        isLoading: false,
        isGameOver: false,
        isGameStarted: false,
        showStartingButton: true,
        totalPreguntas: 0,
        notFoundMessage: '',
        showNotFoundModal: false,
        questionCollector: [],
        localParams: [],
      };
    },

    computed: {
      dialogText() {
        return this.assertions > 1 ? 'Has hecho un buen trabajo!' : 'Sigue intentandolo!';
      },

      dialogTitle() {
        return this.assertions > 1 ? 'Genial!' : 'Lo siento..';
      },

      progress() {
        return roundDecimal((this.assertions * 100) / this.totalPreguntas, 2);
      },

      subjectTitle() {
        return this.capitalize(this.subjectSelected);
      },

      isLoser() {
        return this.isGameOver && this.assertions < 1;
      },

      isWinner() {
        return this.isGameOver && this.assertions >= 1;
      },

      currentQuestion() {
        return this.questionItem.question;
      },
    },

    methods: {
      validate() {
        if (this.selectedAnswer === this.questionItem.rightAnswer) {
          this.assertions++;
          this.questionCollector.push(this.questionItem.idActividadPregunta);
        }
        this.selectedAnswer = null;
        this.buildNextQuestion();
      },

      onRepeat() {
        this.isGameOver = false;
        this.assertions = 0;
        this.localParams = this.params.slice();
        this.options = [];
        this.isLoading = false;
        this.isGameStarted = true;
        this.questionItem = new QuestionItem(this.localParams[0]);
        this.buildOptions();
      },

      async initComponent(needLastGame = false) {
        let actionGame = 'init_game';

        if (needLastGame) {
          actionGame = 'init_last_game';
        }

        const requestInstance = http();

        requestInstance
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/QuestionController.php', {
            //dev route
            action: actionGame,
            subject: this.subjectSelected,
            hashDev: Global.hashDev(),
          })
          .then(
            function (response) {
              if (response.data['error']) {
                this.onErrorModal(response.data['error']);

                if (this.isOnChallenge) {
                  this.$emit('skip-challenge');
                }

                return;
              }

              this.params = response.data;
              this.localParams = this.params.slice();
              const data = shuffleArray(this.localParams);
              this.localParams = data;
              this.totalPreguntas = this.params.length;
              this.questionItem = new QuestionItem(this.localParams[0]);
              this.buildOptions();
              this.isGameStarted = true;
              scrollToGame();
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      },

      buildOptions() {
        this.options = [
          this.questionItem.optionOne,
          this.questionItem.optionTwo,
          this.questionItem.optionThree,
          this.questionItem.optionFour,
        ];
      },

      buildNextQuestion() {
        this.questionItem = {};
        this.options = [];
        this.isLoading = true;
        setTimeout(() => {
          this.localParams.shift();

          if (this.localParams.length === 0) {
            if (this.isOnChallenge) {
              const data = {
                game: 'Questions',
                total: this.assertions,
                idNivelPregunta: this.level ?? 1,
                idAlumno: this.level ?? null,
              };
              this.$emit('on-finish-challenge', data);
              return;
            }
            this.isGameOver = true;
          } else {
            this.questionItem = new QuestionItem(this.localParams[0]);
            this.buildOptions();
          }
          this.isLoading = false;
        }, 500);
      },

      async sendResults(needLastGame = false) {
        if (needLastGame) {
          this.initComponent(needLastGame);
          return;
        }

        const request = http();

        const data = {
          total: this.assertions,
          solvedQuestions: this.questionCollector,
          idNivelPregunta: this.level ?? 1,
          idAlumno: this.level ?? null,
        };

        await request
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/QuestionController.php', {
            action: 'save_user_results',
            data: JSON.stringify(data),
            hashDev: Global.hashDev(),
          })
          .then(() => {
            this.initComponent();
          })
          .catch(function (error) {
            console.log(error.response.data);
          });
      },

      reload(lastGame = false) {
        this.sendResults(lastGame);
        this.questionItem = {};
        this.assertions = 0;
        this.params = {};
        this.options = [];
        this.isLoading = false;
        this.isGameOver = false;

        // Remove my-component from the DOM
        this.renderComponent = false;
        this.$nextTick(() => {
          // Add the component back in
          this.renderComponent = true;
          scrollToGame();
        });
      },

      async showGame() {
        if (!this.subjectSelected) {
          this.subjectSelected = 'aleatorio';
        }
        this.showStartingButton = false;
        if (this.isOnChallenge) {
          await this.initChallenge();
        } else {
          await this.initComponent();
        }
      },

      async onErrorModal(errorMessage) {
        this.notFoundMessage = errorMessage;
        this.showNotFoundModal = true;
        this.isGameStarted = false;

        setTimeout(() => {
          this.showNotFoundModal = false;
          this.showStartingButton = true;
        }, 2500);
      },

      capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      },

      async initChallenge() {
        const requestInstance = http();

        requestInstance
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/QuestionController.php', {
            //dev route
            action: 'init_challenge',
            levelId: this.challengeLevel,
            subject: this.subjectSelected,
            hashDev: Global.hashDev(),
          })
          .then(
            function (response) {
              if (response.data['error']) {
                this.onErrorModal(response.data['error']);
              }

              this.params = response.data.challenge;

              if (this.params.error) {
                this.onErrorModal(this.params.error);
                this.$emit('skip-challenge');

                return;
              }

              this.totalPreguntas = this.params.length;
              this.questionItem = new QuestionItem(this.params[0]);
              this.buildOptions();
              this.isGameStarted = true;
              scrollToGame();
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      },
    },
  };
</script>

<style>
  .w-50 {
    width: 50%;
  }

  .background-question {
    background: url('./img/question.webp');
    background-size: contain;
    background-blend-mode: luminosity;
  }
</style>
