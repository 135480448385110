<template>
  <div>
    <v-chip
      class="mx-auto rounded-circle"
      color="primary"
      text-color="white"
    >
      {{ internalTime }}
    </v-chip>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class Timer extends Vue {
    @Prop({ required: true }) time: number;

    internalTime: number = 0;

    created() {
      this.internalTime = this.time;
      const interval = setInterval(() => {
        this.internalTime--;

        if (this.internalTime <= 0) {
          clearInterval(interval);
          this.$emit('on-timeout');
        }
      }, 1000);
    }
  }
</script>
