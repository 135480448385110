<template>
  <div class="d-flex  rounded-circle hover">
    <v-sheet color="white" elevation="7" height="auto" class="rounded-circle " shaped width="100">
      <v-img class="rounded-circle" width="100" :src="logo" />
    </v-sheet>
  </div>
</template>

<script>
  export default {
    name: 'Logo',

    data() {
      return {
        logo: require('../img/logo_nuevo.png'),
      };
    },
  };
</script>
<style >
  .hover:hover {
    box-shadow: 0px 5px 15px 0px rgb(131, 131, 131);
  }
</style>
