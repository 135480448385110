import Vue from 'vue'
import Component from 'vue-class-component';

@Component
class GameUtilsMixing extends Vue {

    public timerValue: number = 50;

    public scrollToGame() {
        setTimeout(() => {
            const element = document.getElementById("game");

            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 100)
    }

    public initTimer(value: number) {
        this.timerValue = value;
        var interval = setInterval(() => {
            if (this.timerValue > 0) {
                this.timerValue--;
            } else {
                clearInterval(interval);
            }
        }, 100);
    }

    public shuffleArray(array: Array<any>) {
        array.sort(function () {
            return Math.random() - 0.5;
        });
    }

    // Game conditions: First letters to keep and mix the rest to increase level.
    public shuffleRules(length: Number) {
        let rule = 0;
        switch (length) {
            case 4:
                rule = 1;
                break;
            case 5:
                rule = 2;
                break;
            case 6:
                rule = 2;
                break;
            case 7:
                rule = 3;
            default:
                rule = 4;
                break;
        }

        return rule;
    }
}
export default GameUtilsMixing;

