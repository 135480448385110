import Ecuation from './Ecuation';
import EcuationLevelFour from './EcuationLevelFour';
import EcuationLevelOne from './EcuationLevelOne';
import EcuationLevelThree from './EcuationLevelThree';
import EcuationLevelTwo from './EcuationLevelTwo';
import EcuationUnknownOptions from './EcuationUnknownOptions';

export default class EcuationBuilder {
  public generateEcuation(level: number): Ecuation {
    let ecuation: Ecuation = new Ecuation();
    ecuation.level = level;

    switch (level) {
      case 1:
        ecuation = this.generateLevel1();
        break;
      case 2:
        ecuation = this.generateLevel2();
        break;
      case 3:
        ecuation = this.generateLevel3();
        break;
      case 4 : 
        ecuation = this.generateLevel4();
        break;
      default:
        break;
    }

    return ecuation;
  }

  private generateLevel1(): Ecuation {
    const levelOne = new EcuationLevelOne();
    const ecuation: Ecuation = levelOne.get();
    ecuation.options.push(new EcuationUnknownOptions('x', this.generateOptions(ecuation.answer)));
    ecuation.result = ecuation.answer;

    return ecuation;
  }

  private generateLevel2(): Ecuation {
    const ecuationLevelTwo = new EcuationLevelTwo();
    const ecuation = ecuationLevelTwo.get();
    ecuation.options.push(new EcuationUnknownOptions('x', this.generateOptions(ecuation.answer)));
    ecuation.result = ecuation.answer;

    return ecuation;
  }

  private generateLevel3(): Ecuation {
    const levelThree = new EcuationLevelThree();
    const ecuation: Ecuation = levelThree.get();
    ecuation.options.push(new EcuationUnknownOptions('x', this.generateOptions(ecuation.answer)));
    ecuation.result = ecuation.answer;

    return ecuation;
  }

  private generateLevel4(): Ecuation {
    const levelFour = new EcuationLevelFour();
    const ecuation: Ecuation = levelFour.get();
    ecuation.options.push(new EcuationUnknownOptions('x', this.generateOptions(ecuation.answer)));
    ecuation.result = ecuation.answer;

    return ecuation;
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private generateOptions(answer: number): number[] {
    const options: number[] = [];

    options.push(answer);

    while (options.length < 4) {
      const option: number = this.getRandomNumber(answer - 10, answer + 10);

      if (!options.includes(option)) {
        options.push(option);
      }
    }

    return options.sort((a, b) => a - b);
  }
}
