<template lang="">
  <div
    v-if="ecuation"
    class="d-flex flex-column justify-center align-center"
  >
    <v-card
      class="px-10 py-10"
      style="min-width: 350px"
    >
      <div class="ecuation">
        <div class="d-flex mx-auto w-100 justify-center ecuation__text">
          <v-icon
            class="align-center"
            color="primary"
            large
          >
            mdi-arrow-right
          </v-icon>
          <p class="h1 align-center mb-2 ml-5">{{ ecuation.ecuation }}</p>
          <v-icon
            class="mb-2 ml-5"
            :color="statusIcon.color"
            large
          >
            {{ statusIcon.icon }}
          </v-icon>
        </div>
        <v-divider></v-divider>
        <div class="ecuation__right py-10">
          <div
            v-if="ecuation.options"
            v-for="option in ecuation.options"
            :key="option.key"
          >
            <EcuationUnknownSelector
              :values="option.values"
              :unknownKey="option.key"
              @on-select="onInput"
            />
          </div>
        </div>
        <div class="d-inline w-100 mt-5 py-5">
          <v-alert
            :value="showWarning"
            class="mx-auto w-100"
            type="error"
            transition="slide-y-transition"
          >
            Debes seleccionar una opción
          </v-alert>

          <div class="d-flex w-100 justify-center">
            <v-btn
              :disabled="isValidating"
              class="mx-auto"
              @click="validate"
            >
              Comprobar
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Seccion de aciertos y errores -->
      <div class="d-flex my-5">
        <div class="d-flex w-100 justify-center">
          <p class="h3">
            Errores:
            <v-chip color="red">{{ errors }} </v-chip>
          </p>
        </div>
        <div class="d-flex w-100 justify-center">
          <p class="h3 text-green"
            >Aciertos:
            <v-chip color="green">{{ assertions }} </v-chip>
          </p>
        </div>
      </div>
      <div class="d-flex justify-end mt-5">
        <EcuationGameCounter
          v-if="totalEcuations"
          :total="totalEcuations"
          :correct="assertions"
        />
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';
  import EcuationBuilder from '../EcuationBuilder';
  import Ecuation from '../Ecuation';
  import EcuationUnknownSelector from './EcuationUnknownSelector.vue';
  import EcuationGameCounter from './EcuationGameCounter.vue';
  import EcuationLevelHelper from '../Helpers/EcuationLevelHelper';

  @Component({
    components: {
      EcuationUnknownSelector,
      EcuationGameCounter,
    },
  })
  export default class EcuationGame extends Vue {
    @Prop({ default: 1 }) public level!: number;

    private ecuationBuilder: EcuationBuilder = new EcuationBuilder();

    public ecuation: Ecuation | null = null;
    public ecuations: Ecuation[] = [];

    public assertions: number = 0;
    public totalEcuations: number = 0;
    public errors: number = 0;
    public showResult: boolean = false;
    public showWarning: boolean = false;
    public statusIcon: any = {};
    public isValidating: boolean = false;

    public iconOptions: any = {
      success: { icon: 'mdi-check-circle', color: 'green' },
      error: { icon: 'mdi-close-circle', color: 'red' },
      default: { icon: 'mdi-circle', color: 'transparent' },
    };

    public selectedOption = {
      key: null,
      value: null,
    };

    created(): void {
      const amount = EcuationLevelHelper.getAmountOfEcuationByLevel(this.level);
      this.totalEcuations = amount;

      for (let i = 0; i < amount; i++) {
        this.ecuations.push(this.ecuationBuilder.generateEcuation(this.level));
      }
      this.setEcuation();

      this.statusIcon = this.iconOptions.default;
    }

    public onInput(key: string, value: number): void {
      this.selectedOption.key = key;
      this.selectedOption.value = value;
    }

    public validate(): void {
      this.isValidating = true;

      if (this.ecuation === null) {
        return;
      }

      if (this.selectedOption.value === null) {
        this.showWarning = true;

        this.hideWarning();

        return;
      }

      this.showResult = true;

      if (this.ecuation.answer === this.selectedOption.value) {
        this.processAssertion();
      } else {
        this.processError();
      }

      this.selectedOption.value = null;
      this.selectedOption.key = null;
      this.hideResult();
    }

    private hideWarning(): void {
      setTimeout(() => {
        this.showWarning = false;
        this.isValidating = false;
      }, 1000);
    }

    private setEcuation(): void {
      this.ecuation = this.ecuations[0];
      this.ecuations.shift();
    }

    private hideResult(): void {
      setTimeout(() => {
        this.showResult = false;
        this.statusIcon = this.iconOptions.default;
        this.isValidating = false;

        if (this.ecuations.length === 0) {
          this.$emit('on-game-finished', {
            assertions: this.assertions,
            errors: this.errors,
            total: this.totalEcuations,
          });

          return;
        }

        this.setEcuation();
      }, 1000);
    }

    private processAssertion(): void {
      this.assertions++;
      this.statusIcon = this.iconOptions.success;
    }

    private processError(): void {
      this.errors++;
      this.statusIcon = this.iconOptions.error;
    }
  }
</script>
<style lang="scss" scoped>
  .h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
    margin: 0;
  }

  .h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #000;
    margin: 0;
  }

  .ecuation {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .ecuation__text {
    font-size: 2rem;
    font-weight: 500;
    color: #000;
    margin: 0;
    min-width: 480px;
  }

  .softBorder {
    border: 1px solid #e0e0e0;
  }
</style>
