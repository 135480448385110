<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-list
          class="rounded"
        >
          <v-list-item
            three-line
            class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title class="mb-2 text-center"
                >Ejercicios
                <v-icon>mdi-weight</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex nowrap">
                ¿Necesitas ejercicios para mejorar la memoria o para potenciar la atención y las demás funciones
                mentales?</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            three-line
            class="mb-5"
          >
            <v-list-item-content>
              <v-list-item-title class="mb-3 text-center"
                >Plan de trabajo
                <v-icon>mdi-handshake</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle>
                Accede a un <a href="/plan-details">Plan de trabajo</a> guiado semanalmente.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-list
          disabled
          class="rounded"
        >
          <v-subheader>¿A quienes estan dirigidos estos ejercicios?</v-subheader>

          <v-list-item
            v-for="(item, i) in features"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DefaultContent',

    data() {
      return {
        selectedItem: 1,
        features: [
          { text: 'Adultos', icon: 'mdi-human-male-female' },
          { text: 'Profesionales', icon: 'mdi-briefcase-account' },
          { text: 'Deportistas', icon: 'mdi-run-fast' },
          { text: 'Trabajadores', icon: 'mdi-briefcase' },
          { text: 'Estudiantes', icon: 'mdi-school' },
          { text: 'Docentes', icon: 'mdi-human-male-board-poll' },
        ],
      };
    },
  };
</script>
