<template>
  <v-container fluid>
    <div class="d-flex nowrap">
      <v-col align="left">
        <v-icon @click="goTo('https://twitter.com/?lang=es')" class="hover rounded ml-1">mdi-twitter</v-icon>
      </v-col>
      <v-col align="center">
        <v-icon @click="goTo('https://facebook.com/?lang=es')" class="hover rounded ml-1">mdi-facebook</v-icon>
      </v-col>
      <v-col align="end">
        <v-icon @click="goTo('https://instagram.com/?lang=es')" class="hover rounded ml-1">mdi-instagram</v-icon>
      </v-col>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'SocialMedia',

    methods: {
      goTo(url) {
        const w = window.open(url, '_blank');
        w.focus();
      },
    },
  };
</script>

<style>
  .hover:hover {
    box-shadow: 0px 5px 15px 0px rgb(131, 131, 131);
  }
</style>
