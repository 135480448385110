<template>
  <div class="d-flex w-100 justify-center align-center flex-wrap mx-auto col-md-10 col-lg-8">
    <CountryGameSelector
      v-if="!params"
      @push-game="onPushGame"
    />
    <div class="">
      <CountryGame
        v-if="params"
        :params="params"
        @answer="onAnswer"
      />

      <div class="d-flex w-100 softBorder justify-end">
        <CountryGameCounter
          v-if="params"
          class="mr-2"
          :total="totalAnswers"
          :correct="correctAnswers"
        />
      </div>
    </div>

    <CountryGameResultModal
      v-if="showModal"
      :show-modal="showModal"
      :correctAnswers="correctAnswers"
      :totalAnswers="totalAnswers"
      @close-modal="resetGame()"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import Country from '../Models/Country';
  import CountryMapperHelper from '../Helpers/CountryMapperHelper';
  import TheCountry from './TheCountry.vue';
  import CountryGameSelector from './CountryGameSelector.vue';
  import CountryGameMode from '../Models/CountryGameMode';
  import CountryService from '../Services/CountryService';
  import CountryGameParams from '../Models/CountryGameParams';
  import CountryGame from './CountryGame.vue';
  import CountryGameLevelHelper from '../Helpers/CountryGameLevelHelper';
  import CountryGameCounter from './CountryGameCounter.vue';
  import CountryGameResultModal from './CountryGameResultModal.vue';

  @Component({
    components: {
      TheCountry,
      CountryGameSelector,
      CountryGame,
      CountryGameCounter,
      CountryGameResultModal,
    },
  })
  export default class Countries extends Vue {
    countries: Country[] = [];

    params: CountryGameParams = null;

    gameMode: CountryGameMode = null;

    gameService = new CountryService();

    gameSet: CountryGameParams[] = [];

    correctAnswers: number = 0;

    totalAnswers: number = 0;

    showModal: boolean = false;

    created() {
      const allCountries = this.getCountries();
      this.countries = allCountries.slice(0, 10);
    }

    public onPushGame(gameMode: CountryGameMode): void {
      this.gameMode = gameMode;

      const amount = CountryGameLevelHelper.getRoundAmountByLevel(gameMode.level);
      this.totalAnswers = amount;

      for (let i = 0; i <= amount; i++) {
        this.gameSet.push(this.gameService.buildGame(gameMode));
      }

      this.assignNewGame();
    }

    public onAnswer(isCorrect: boolean): void {
      if (isCorrect) {
        this.correctAnswers++;
      }

      this.assignNewGame();
    }

    public resetGame(): void {
      this.params = null;
      this.totalAnswers = 0;
      this.correctAnswers = 0;
      this.showModal = false;
    }

    private getCountries(): Country[] {
      return CountryMapperHelper.mapCountriesByName();
    }

    private assignNewGame(): void {
      if (this.gameSet.length === 0) {
        this.showModal = true;

        return;
      }

      this.params = this.gameSet[0];
      this.gameSet.shift();
    }
  }
</script>

<style>
  .softBorder {
    border: 1px solid #e0e0e0;
  }
</style>
