<template lang="">
  <div>
    <PieChart v-if="performanceData" :chartData="performanceData" />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';
  import PieChart from './Charts/PieChart.vue';
  import ChartDataStructure from './Charts/Structures/ChartDataStructure';

  @Component({
    components: {
      PieChart,
    },
  })
  export default class DashboardPerformance extends Vue {
    @Prop({ required: true }) performanceData!: ChartDataStructure;
  }
</script>
