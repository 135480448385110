<template lang="">
  <div class="d-inline px-10">
    <!-- Descriptor -->
    <v-slide-y-transition>
      <div
        v-if="showLevelSelector"
        class="d-flex justify-center w-75 mx-auto"
      >
        <EcuationDescriptor />
      </div>
    </v-slide-y-transition>

    <!-- Level selector -->
    <v-slide-y-transition>
      <div v-if="showLevelSelector">
        <EcuationLevelSelector
          v-show="showLevelSelector"
          @on-select-level="onSelectLevel"
        />
      </div>
    </v-slide-y-transition>

    <!-- Back button -->
    <v-expand-x-transition>
      <div class="d-flex w-100 mb-5">
        <v-btn
          v-show="showEcuationGame"
          class="mr-auto ml-20"
          @click="onBack"
        >
          <v-icon>mdi-arrow-left</v-icon>
          Volver
        </v-btn>
      </div>
    </v-expand-x-transition>

    <!-- Game -->
    <v-expand-x-transition>
      <div v-if="showEcuationGame">
        <EcuationGame
          v-show="showEcuationGame"
          :level="level"
          @on-game-finished="onGameFinished"
        />
      </div>
    </v-expand-x-transition>

    <EcuationGameResultModal
      v-if="showResultModal"
      :show-modal="showResultModal"
      :correctAnswers="assertions"
      :totalAnswers="totalEcuations"
      @close-modal="onBack()"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import EcuationLevelSelector from './EcuationLevelSelector.vue';
  import EcuationGame from './EcuationGame.vue';
  import EcuationDescriptor from './EcuationDescriptor.vue';
  import EcuationGameResultModal from './EcuationGameResultModal.vue';

  @Component({
    components: {
      EcuationLevelSelector,
      EcuationGame,
      EcuationDescriptor,
      EcuationGameResultModal,
    },
  })
  export default class Ecuations extends Vue {
    public level: number = null;
    public showLevelSelector: boolean = true;
    public showEcuationGame: boolean = false;
    public showResultModal: boolean = false;

    // Game results
    public assertions: number = 0;
    public errors: number = 0;
    public totalEcuations: number = 0;

    public onSelectLevel(level: number): void {
      this.level = level;
      this.showLevelSelector = false;

      setTimeout(() => {
        this.showEcuationGame = true;
      }, 1000);
    }

    public onBack(): void {
      this.showEcuationGame = false;
      this.showResultModal = false;

      setTimeout(() => {
        this.showLevelSelector = true;
      }, 1000);
    }

    public onGameFinished(params: { assertions: number; errors: number; total: number }): void {
      this.assertions = params.assertions;
      this.errors = params.errors;
      this.totalEcuations = params.total;

      this.showResultModal = true;
    }
  }
</script>
<style>
  .ml-20 {
    margin-left: 87px;
  }
</style>
