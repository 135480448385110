<template>
  <div>
    <v-icon class="h2">mdi-flag</v-icon>
    <span class="h2">{{ correct }}</span>
    <span>/</span>
    <span>{{ total }}</span>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class CountryGameCounter extends Vue {
    @Prop({ required: true }) total: number;
    @Prop({ required: true }) correct: number;
  }
</script>
