<template>
  <div class="d-inline my-auto mx-auto">
    <div class="d-flex d-md-none justify-center">
      <p class="text-sm-center">Quieres probar algun juego gratis? </p>
    </div>

    <div class="d-flex d-md-none justify-center">
      <a class="d-flex text-sm-center mx-auto" @click="triggerRegister()"> Registrate!</a>
    </div>
    <h3 class="d-none d-md-flex text-md-center"
      >Quieres probar algun juego gratis? <a class="ml-2" @click="triggerRegister()">Registrate!</a></h3
    >
    <div class="d-flex justify-center my-4">
      <h3 class="d-none d-md-flex text-md-center"
        ><a class="d-flex text-sm-center mx-auto" @click="triggerLogin()">Ya estoy registrado</a>
      </h3>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContentGameDefault',

    methods: {
      triggerRegister() {
        document.getElementById('btnRegister').click();
      },

      triggerLogin() {
        document.getElementById('btnLogin').click();
      },
    },
  };
</script>

<style>
  .b-text {
    font-size: 40px;
  }
</style>
