<template>
  <div class="d-flex">
    <v-btn
      id="onSubmit"
      v-if="isVisible"
      :loading="isLoading"
      :disabled="isLoading"
      :color="color"
      class="ma-2 white--text mr-auto lowerCase"
      small
      @click.prevent="startLoader"
    >
      {{ buttonText }}
      <v-icon
        right
        dark
      >
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'ButtonLoader',
    props: {
      buttonText: {
        type: String,
        required: false,
        default: 'Comenzar',
      },

      color: {
        type: String,
        required: false,
        default: 'blue-grey',
      },

      animationTime: {
        type: Number,
        required: false,
        default: 3000,
      },
    },

    computed: {
      isVisible() {
        return this.loader === 1;
      },

      isLoading() {
        return this.loading;
      },
    },

    data() {
      return {
        loader: 1,
        loading: false,
        emitted: false,
      };
    },

    methods: {
      async startLoader() {
        this.loading = true;
        this.loader = 1;

        await this.sleep(this.animationTime);

        this.loader = 0;
        this.loading = false;

        if (!this.emitted) {
          this.emitted = true;
          this.emitEvent();
        }
      },

      sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      },

      emitEvent() {
        this.$emit('event');
      },
    },
  };
</script>

<style lang="scss">
  .lowerCase {
    text-transform: capitalize !important;
  }
</style>
