export default class Utils {

    public static deepClone(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

    public static shuffleArray(array: any[]): any[] {
        let currentIndex = array.length;
        let temporaryValue;
        let randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    public static shuffleRules(length: Number) {
        let rule = 0;
        switch (length) {
            case 4:
                rule = 1;
                break;
            case 5:
                rule = 2;
                break;
            case 6:
                rule = 2;
                break;
            case 7:
                rule = 3;
            default:
                rule = 4;
                break;
        }

        return rule;
    }

    public static fromObjectSnakeToCamel(object: any): any {
        let newObject = {};

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                const newKey = this.snakeToCamel(key);
                newObject[newKey] = value;
            }
        }

        return newObject;
    }

    public static fromObjectCamelToSnake(object: any): any {
        let newObject = {};

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                const newKey = this.camelToSnake(key);
                newObject[newKey] = value;
            }
        }

        return newObject;
    }

    private static snakeToCamel(s: string): string {
        return s.replace(/(\_\w)/g, function (m) {
            return m[1].toUpperCase();
        });
    }

    private static camelToSnake(s: string): string {

        return s.replace(/([A-Z])/g, function ($1) {
            return "_" + $1.toLowerCase();
        });
    }
}