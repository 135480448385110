import GoNoGoSubject from '../Structures/GoNoGoSubject';
import IGoNoGoModeStrategy from './IGoNoGoModeStrategy';

export default class GoNoGoSelectiveStrategy implements IGoNoGoModeStrategy {
  public static readonly MODE: string = 'selective';

  public isValid(currentSubject: GoNoGoSubject, receivedSubject: GoNoGoSubject): boolean {
    return currentSubject.id === receivedSubject.id;
  }
}
