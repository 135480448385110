export default class OrderLevelResolvingService {
    getAmountOfDrinks(levelId: number) {
        switch(levelId) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            default:
                return 1;
        }
    }

    getAmountOfFoods(levelId: number) {
        switch(levelId) {
            case 1:
                return 2;
            case 2:
                return 3;
            case 3:
                return 4;
            default:
                return 2;
        }
    }

    getLevelTime(levelId: number) {
        switch(levelId) {
            case 1:
                return 60;
            case 2:
                return 45;
            case 3:
                return 30;
            default:
                return 60;
        }
    }

    getTimeToSeeOrderRequest(levelId: number) {
        switch(levelId) {
            case 1:
                return 5;
            case 2:
                return 7;
            case 3:
                return 5;
            default:
                return 10;
        }
    }
}