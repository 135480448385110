<template>
  <div>
    <v-card>
      <v-card-title class="text-center">Análisis de resultados</v-card-title>

      <v-list-item>
        Tasa de efectividad: <p class="bold mt-4 ml-2">{{ efectivenessData + '%' }}</p>
        <v-icon
          class="ml-2"
          >mdi-crosshairs</v-icon
        >
      </v-list-item>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class DashboardEfectiveness extends Vue {
    @Prop({ required: true }) efectivenessData!: number;
  }
</script>
