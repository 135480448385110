<template>
  <div v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      transition="dialog-transition"
      max-width="500"
    >
      <v-card>
        <v-card-title class="d-block w-100 text-sm-center mx-auto">
          <p>
            Bienvenido a Pro!
            <crown-icon />
          </p>
        </v-card-title>
        <v-card-text class="text-center">
          Empieza a disfrutar de todas las ventajas que tenemos para ti
        </v-card-text>
        <div class="d-flex w-100 justify-center">
          <v-img
            max-width="400"
            max-height="400"
            center
            :src="background.img"
          />
        </div>

        <v-card-actions class="justify-center">
          <v-btn color="success" @click="closeAndReload()">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
  import { Component, Prop } from 'vue-property-decorator';
  import Vue from 'vue';
  import CrownIcon from '../Misc/CrownIcon.vue';

  @Component({ components: { CrownIcon } })
  export default class NewSubscriptionWelcomeModal extends Vue {
    @Prop({ required: true }) isVisible: boolean;

    background: any = { img: require('../img/welcome.jpg') };

    closeAndReload() {
      this.$emit('close-modal');

      setTimeout(() => window.location.reload(), 200);
    }
  }
</script>
<style lang="scss">
  .w-100 {
    width: 100% !important;
  }
</style>
