<template>
  <div>
    <h2 class="text-center mb-5">Calcula el total de la order</h2>
    <div class="d-flex justify-center">
      <div
        v-for="product in order.products"
        class="productPreview"
      >
        <v-icon>{{ product.icon }}</v-icon>
        <span>{{ product.name }}</span>
        <span class="ml-5  p-10">${{ product.price }}</span>
      </div>
    </div>
    <div class="d-flex flex-column mt-5">
      <v-text-field
        v-model="total"
        label="Total $"
        class="total mx-auto"
        :rules="rules"
      ></v-text-field>
      <v-btn
        class="mx-auto"
        color="primary"
        @click="onCheckout"
        >Confirmar ticket</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import Order from '../Structures/Order';

  @Component
  export default class WaiterCheckout extends Vue {
    @Prop() order: Order;

    total: string = null;

    rules = [
      (v: string) => !!v || 'El campo es requerido',
      // validate number
      (v: string) => /^[0-9]*$/.test(v) || 'Solo se permiten números',
    ];

    onCheckout() {
      this.order.total = parseInt(this.total);

      this.$emit('on-checkout', this.order);
    }
  }
</script>
<style scoped>
  .productPreview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .total {
    margin-top: 20px;
    width: 10%;
  }
</style>
