<template>
  <div v-if="renderComponent" class="container mt-4">
    <div class="d-flex ml-auto mr-3">
      <custom-dialog
        class="d-flex ml-auto mt-4"
        :is-visible="dialog"
        :button-text="dialogData.buttonText"
        :title="dialogData.title"
        :description="dialogData.description"
      />
    </div>
    <div class="d-flex">
      <level-progress class="d-flex mx-auto" :value="progress"></level-progress>
    </div>
    <div class="d-flex justify-center my-8">
      <div>
        <p class="mx-5 bold--text" :style="getHugeFontSize">
          {{ firstOperator }}
        </p>
      </div>
      <div>
        <p class="mx-5" :style="getHugeFontSize" v-html="operationSelected"></p>
      </div>
      <div>
        <p class="mx-5" :style="getHugeFontSize">{{ secondOperator }}</p>
      </div>
      <div>
        <p class="mx-5" :style="getHugeFontSize">=</p>
      </div>
      <div>
        <p class="mx-5" :style="getHugeFontSize">{{ roundDecimal(result, 2) }}</p>
      </div>
      <div>
        <v-icon v-if="assert" color="success" class="d-flex mx-auto my-auto align-center huge-icon" :style="getHugeIcon"
          >mdi-check-outline</v-icon
        >
        <v-icon v-if="wrong" color="red" class="d-flex mx-auto my-auto align-center huge-icon" :style="getHugeIcon"
          >mdi-close-thick</v-icon
        >
      </div>
    </div>
    <div class="d-flex align-content-center mx-auto">
      <div class="d-flex mx-auto" v-for="icon in icons" :key="icon">
        <v-icon :disabled="isInProcess" :style="getHugeIcon" @click="setOperation(icon)">
          {{ icon }}
        </v-icon>
      </div>
    </div>
    <progress-modal
      :isActive="isOver"
      :isFirstLevel="params.isFirstLevel"
      :progress="progress"
      :isLastLevel="params.isPlayingLastLevel"
      @reload="reload"
    />
  </div>
</template>

<script>
  import Global from '@/helpers/Global';
  import http from '@/helpers/https';
  import LevelProgress from './Misc/LevelProgress.vue';
  import SignosGameModel from '../Models/Signos/SignosGameModel';
  import ProgressModal from './Misc/ProgressModal.vue';
  import { DIALOG_SIGNOS_DATA } from '../helpers/DialogHelper';
  import CustomDialog from './Misc/CustomDialog';
  export default {
    name: 'Signos',
    components: {
      LevelProgress,
      ProgressModal,
      CustomDialog
    },

    props: {
      isOnChallenge: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      renderComponent: true,
      params: {},
      levelData: {},
      model: 0,
      icons: ['mdi-plus', 'mdi-minus', 'mdi-close'],
      operations: {
        'mdi-plus': '+',
        'mdi-minus': '-',
        'mdi-close': '*',
      },
      operatorSelected: '',
      isValid: false,
      progress: 0,
      firstOperatorData: 0,
      secondOperatorData: 0,
      resultData: 0,
      isLevelCompleted: false,
      assert: false,
      wrong: false,
      assertions: 0,
      isProcessing: false,
      totalOperations: 0,
      dialog: false,
      dialogData: DIALOG_SIGNOS_DATA,
    }),

    computed: {
      getHugeIcon() {
        return window.innerWidth > 768 ? 'font-size:100px;' : '';
      },

      getHugeFontSize() {
        return window.innerWidth > 768 ? 'font-size: 54px;' : 'font-size: 18px;';
      },

      operationSelected() {
        return this.operatorSelected;
      },

      firstOperator() {
        return this.firstOperatorData;
      },

      secondOperator() {
        return this.secondOperatorData;
      },

      result() {
        return this.resultData;
      },

      isOver() {
        return this.isLevelCompleted;
      },

      showWrong() {
        return this.wrong;
      },

      hasLevelCompleted() {
        return this.progress === 100;
      },

      isInProcess() {
        return this.isProcessing;
      },
    },

    async created() {
      this.initComponent();
    },

    methods: {
      setOperation(icon) {
        this.isProcessing = true;
        this.isValid = false;
        this.operatorSelected = this.operations[icon];
        this.isValid = this.validateResult(this.operationSelected);

        if (this.isValid) {
          this.assert = true;
          this.assertions++;
        } else {
          this.wrong = true;
        }
        this.levelData.shift();
        this.progress = this.getProgress();

        if (this.levelData.length > 0) {
          this.setNextOperation();
        } else {
          setTimeout(
            function () {
              this.isLevelCompleted = true;
              this.isProcessing = false;
            }.bind(this),
            1000
          );
        }
      },

      /**
       * @ts-ignore
       */
      roundDecimal(num, decimals) {
        return Number(Math.round(num + 'e' + decimals) + 'e-' + decimals);
      },

      validateResult(operation) {
        switch (operation) {
          case '+':
            return this.firstOperator + this.secondOperator === this.result;
          case '-':
            return this.firstOperator - this.secondOperator === this.result;
          case '*':
            return this.firstOperator * this.secondOperator === this.result;
        }
      },

      setNextOperation() {
        setTimeout(
          function () {
            this.operatorSelected = '';
            this.assert = false;
            this.wrong = false;
            this.isProcessing = false;
            this.buildGame(this.levelData);
          }.bind(this),
          500
        );
      },

      async initComponent(needLastGame = false) {
        let actionGame = 'init_game';

        if (needLastGame) {
          actionGame = 'init_last_game';
        }

        const requestInstance = http();

        requestInstance
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/SignosController.php', {
            //dev route
            action: actionGame,
            hashDev: Global.hashDev(),
          })
          .then(
            function (response) {
              if (response) {
                let signos = new SignosGameModel(
                  response.data.currentLevel,
                  response.data.exitosRequeridos,
                  response.data.idAlumno,
                  response.data.isPlayingLastLevel,
                  response.data.levelData,
                  response.data.maximoPuntaje,
                  response.data.porcentajeProgreso,
                  response.data.progresoActual
                );

                this.params = signos;
                this.levelData = this.params.levelData;
                this.totalOperations = this.levelData.length;

                this.buildGame(this.levelData);
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      },

      sendResults(needLastGame = false) {
        const data = {
          game: 'Signos',
          total: this.assertions,
          idNivelPregunta: this.params.currentLevel,
          idAlumno: this.params.idAlumno,
        };

        if (this.isOnChallenge) {
          this.$emit('on-finish-challenge', data);
          return;
        }
        if (needLastGame) {
          this.initComponent(needLastGame);
          return;
        }

        const results = http();

        results
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/SignosController.php', {
            action: 'save_user_results',
            data: JSON.stringify(data),
            hashDev: Global.hashDev(),
          })
          .then(
            function () {
              this.initComponent();
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error.response.data);
          });
      },
      buildGame(data) {
        this.firstOperatorData = data[0].firstOperator;
        this.secondOperatorData = data[0].secondOperator;
        this.resultData = data[0].result;
        this.operation = data[0].operation;
      },

      reload(lastGame = false) {
        if (this.params.currentLevelId === 1) {
          lastGame = false;
        }
        this.sendResults(lastGame);
        this.isLevelCompleted = false;
        // Remove my-component from the DOM
        this.renderComponent = false;
        this.assertions = 0;
        this.params = {};
        this.levelData = {};
        this.randomTest = [];
        this.operatorSelected = null;
        this.assert = false;
        this.wrong = false;
        this.progress = 0;

        this.$nextTick(() => {
          // Add the component back in

          setTimeout(() => {
            this.renderComponent = true;
          }, 500);
        });
      },

      getProgress() {
        return Math.round((this.assertions * 100) / this.totalOperations);
      },
    },
  };
</script>

<style>
  .operation-selector {
    max-width: 200px;
    max-height: 200px;
  }
</style>
