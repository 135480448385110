<template>
  <div
    v-if="renderComponent"
    class="d-md-flex justify-space-around mt-5 w-100"
  >
    <the-pro-subscription
      v-if="activeSubscription"
      :subscription="subscription"
      :isMobile="isMobile"
    />
    <div
      v-if="!activeSubscription"
      class="w-100"
    >
      <currency-selector @on-select-currency="setCurrency" />

      <div class="w-100 mx-1 my-2 my-md-0">
        <store-offer-pro
          :currency="currency"
          @on-init-subscription="actionCreateSubscription"
          @on-show-form="showFormulario"
          :buttonDisabled="subscription !== null"
          @on-change-revenue="onChangeRevenue"
        />
      </div>
      <ContainerModal
        v-if="isFormVisible"
        :title="'Formulario de pago'"
        :buttonText="'Suscribirse'"
        :description="'Completa los datos de tu tarjeta de crédito para suscribirte a Cognosmemory.'"
        :isVisible="isFormVisible"
        @close-modal="mustShowFormulario = false"
      >
        <div id="cardPaymentSlot">
          <div id="cardPaymentBrick_container"></div>
        </div>
      </ContainerModal>

      <custom-dialog-modal
        :title="'Espera un momento!'"
        :buttonText="'Ir a juegos'"
        :description="'Ya tienes una suscripción activa!'"
        :isVisible="mustShowAlert"
        @close-modal="mustShowAlert = false"
      />

      <new-subscription-welcome-modal
        :isVisible="isWelcomeModalVisible"
        @close-modal="showWelcome = false"
      />

      <PaymentErrorModal
        :message="errorMessageModal"
        :isVisible="showErrorModal"
        @close-modal="isErrorModalVisible = false"
      />
    </div>
  </div>
</template>

<script>
  import StoreOfferPro from './StoreOfferPro.vue';
  import http from '../../helpers/https';
  import Global from '@/helpers/Global';
  import GameModal from '../Misc/GameModal.vue';
  import Subscription from '@/Models/User/Subscription';
  import DefaultModal from '../Misc/DefaultModal.vue';
  import CustomDialogModal from '../Misc/CustomDialogModal.vue';
  import TheProSubscription from '../Subscription/TheProSubscription.vue';
  import NewSubscriptionWelcomeModal from '../Subscription/NewSubscriptionWelcomeModal.vue';
  import CurrencySelector from './CurrencySelector.vue';
  import ContainerModal from '../Misc/ContainerModal.vue';
  import { isDev } from '@/helpers/Utils';
  import PaymentErrorModal from '../Subscription/Banners/PaymentErrorModal.vue';

  export default {
    components: {
      StoreOfferPro,
      GameModal,
      DefaultModal,
      CustomDialogModal,
      TheProSubscription,
      NewSubscriptionWelcomeModal,
      CurrencySelector,
      ContainerModal,
      PaymentErrorModal,
    },
    name: 'StoreDefault',

    props: {
      isLogged: {
        type: Boolean,
        required: true,
      },

      userInfo: {
        type: Object,
        required: false,
        default: null,
      },

      subscription: {
        type: Subscription,
        required: false,
        default: null,
      },

      isMobile: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        mercadoPago: null,
        mustShowFormulario: false,
        bricksController: null,
        bricksBuilder: null,
        cardPayment: null,
        cardData: null,
        mustShowAlert: false,
        showWelcome: false,
        renderComponent: false,
        selectedPeriod: 'monthly',
        currency: 'UYU',
        isErrorModalVisible: false,
        errorMessage: '',
      };
    },

    computed: {
      isUserLogged() {
        return this.isLogged;
      },

      user() {
        return this.userInfo;
      },

      isFormVisible() {
        return this.mustShowFormulario;
      },

      isMercadoPagoImported() {
        return this.$store.state.mercadoPagoScript !== null;
      },

      isWelcomeModalVisible() {
        return this.showWelcome;
      },

      activeSubscription() {
        return this.subscription !== null;
      },

      isScriptLoaded() {
        return document.getElementById('mercadoPagoScript') !== null;
      },

      showErrorModal() {
        return this.isErrorModalVisible;
      },

      errorMessageModal() {
        return this.errorMessage;
      },
    },

    created() {
      if (!this.isScriptLoaded) {
        if (!this.isMercadoPagoImported) {
          let recaptchaScript = this.buildScript();
          this.$store.commit('setMercadoPagoScript', recaptchaScript);
        }

        document.head.append(this.$store.state.mercadoPagoScript);
      }
    },

    mounted() {
      this.renderComponent = true;
    },

    methods: {
      /**
       * @param {String} currency
       */
      setCurrency(currency) {
        this.currency = currency;
      },

      async showFormulario(selectedOptions) {
        this.cardData = selectedOptions;

        if (!this.isUserLogged) {
          alert('Debes iniciar sesion para suscribirte!');
          this.$emit('show-login');

          return;
        }
        if (this.subscription === null) {
          this.mustShowFormulario = true;
          this.mercadoPago = new MercadoPago('APP_USR-4a399c8e-21f1-4c91-9fcb-6fc2ef04ae6f');
          this.bricksBuilder = this.mercadoPago.bricks();
          await this.renderCardPaymentBrick(this.bricksBuilder);
        } else {
          this.mustShowAlert = true;
        }
      },

      buildScript() {
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://sdk.mercadopago.com/js/v2');
        recaptchaScript.setAttribute('id', 'mercadoPagoScript');

        return recaptchaScript;
      },

      async renderCardPaymentBrick(bricksBuilder) {
        const settings = {
          initialization: {
            amount: this.cardData.price, //valor del pago a realizar
          },
          locale: 'es-UY',
          callbacks: {
            onReady: () => {
              this.cardPayment = document.getElementById('cardPaymentBrick_container');
              document.getElementById('cardPaymentSlot').append(this.cardPayment);
              // callback llamado cuando Brick esté listo
            },
            onSubmit: (cardFormData) => {
              this.cardData = cardFormData;
              this.cardData.currency = this.currency;
              this.cardData.period = this.selectedPeriod;
              this.actionCreateSubscription();
            },
            onError: (error) => {
              if (isDev) {
                console.log(error);
              }
            },
          },
        };
        this.bricksController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
      },

      showLoading() {
        this.isLoadingForm = true;
      },

      onChangeRevenue(selectedPeriod) {
        this.selectedPeriod = selectedPeriod;
      },

      actionCreateSubscription() {
        if (!this.isUserLogged) {
          this.$emit('show-login');

          return;
        }

        const request = http();

        request
          .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoMercadoPago/MercadoPagoController.php', {
            //dev route
            action: 'actionCreateSubscription',
            data: {
              cardData: this.cardData,
              hashDev: Global.hashDev(),
            },
          })
          .then((response) => {
            if (response.data.status === 400) {
              this.errorMessage = response.data.message;
              this.isErrorModalVisible = true;
              return;
            }
            // show welcome modal
            this.showWelcome = response.data.isSubscriptionCreated;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
</script>
<style scoped>
  #form-checkout {
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }

  .container {
    height: 18px;
    display: inline-block;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 2px;
    padding: 1px 2px;
  }
</style>
