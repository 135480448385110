<template>
  <v-app>
    <div data-app>
      <!-- Memory monitor for development -->
      <!--       <v-chip class="d-flex mx-auto w-100">{{ memoryUsage }} </v-chip> -->
      <home
        v-if="isSessionChecked"
        :isLogged="isLogged"
        :userInfo="userInfo"
        @logout="logout()"
      />
    </div>
  </v-app>
</template>

<script lang="ts">
  import 'reflect-metadata';
  import { AxiosInstance } from 'axios';
  import http from './helpers/https';
  import { Component } from 'vue-property-decorator';
  import Home from './components/Layouts/Home.vue';
  import UserModel from './Models/User/UserModel';
  import Global from './helpers/Global';
  import Vue from 'vue';
  import SessionService from './components/Services/SessionService';
  /**
   * @description
   * Uncomment the following line to see the memory usage of the app
   */
  // import { formatMemoryUsage } from './core/metrics/performanceUtils';
  @Component({
    components: {
      Home,
    },
  })
  export default class App extends Vue {
    session: string = '';
    isRegistering: boolean = false;
    request?: AxiosInstance = http();
    userInfo?: UserModel;
    memoryUsage: string = '';
    isUserLogged: boolean = false;
    isSessionChecked: boolean = false;

    sessionService = new SessionService();

    get isLogged(): boolean {
      return this.isUserLogged;
    }

    async created() {
      this.isSessionChecked = false;
      await this.checkSession();
      this.isSessionChecked = true;

      const currentPath = this.$router.currentRoute.path;

      if (currentPath === '/' && this.isUserLogged) {
        console.log(currentPath);
        this.$router.push('/dashboard');
      } else if (currentPath === '/' && !this.isUserLogged) {
        this.$router.push('/home');
        
      }
    }

    logout() {
      this.request
        .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoAuth/AuthController.php', {
          action: 'logout',
        })
        .then((response) => {
          if (response.data.success) {
            sessionStorage.removeItem('cognosmemory');
            this.$store.commit('setIsLogged', false);
            this.$store.commit('setUserInfo', null);
            this.isUserLogged = false;

            location.reload();
          }
        });
    }

    async checkSession() {
      const callback = (userInfo = null, response = null) => {
        this.isUserLogged = userInfo;
        this.userInfo = response;
        this.$store.commit('setIsLogged', this.isUserLogged);
        this.$store.commit('setUserInfo', this.userInfo);
      };

      await this.sessionService.checkSession(callback);
    }
  }
</script>
