const Global = {
    url: 'http://localhost/INTELECTO_APP/',
    urlProd: 'https://www.cognosmemory.com/app/',
    resolveUrl: function (env: any) {
        if (env === "production") {
            return '../system/';
        }
        else {
            return 'http://localhost/INTELECTO_APP/system/';
        }
    },
    hashDev: function () {
        return process.env.NODE_ENV !== "production"
            ? '8b44af52dd049615fbdab6f5565d2ab9a6e894fa20eeb48dd9c5e5766e98411d897901095cdeddbd849153b85feb82eae01eb8a29bd23545255961640bb753ac'
            : '';
    }
};

export default Global;