<template>
	<v-dialog transition="dialog-bottom-transition" max-width="600" :persistent="true" v-model="hasWon">
		<v-card>
			<v-card-title class="text-h3 grey lighten-2 mx-auto">
				<p class="mx-auto">Has ganado!</p>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-actions>
				<div class="d-flex mx-auto justify-space-between">
					<v-btn class="success mb-2" @click="reload()"> Siguiente nivel </v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "WinnerModal",
		event: "reload",

		props: {
			hasWon: {
				type: Boolean,
				required: true,
				default: false,
			},
		},

		methods: {
			reload() {
				this.$emit("reload");
			},
		},
	};
</script>
