import { GamePerformanceService } from '@/components/UserPerformance/GamePerformanceService';
import GoNoGoResultData from './Structures/GoNoGoResultData';

export default class GoNoGoEventManager {
  private gamePerformanceService: GamePerformanceService;
  private amountOfElements: number;
  private currentAmountOfElements: number = 0;
  private vueEventBus: Vue;

  public constructor(vueEventBus: Vue, amountOfElements: number) {
    this.amountOfElements = amountOfElements;
    this.currentAmountOfElements = amountOfElements;
    this.vueEventBus = vueEventBus;
    this.gamePerformanceService = GamePerformanceService.getInstance();
  }

  public onLoadItem(): void {
    if (this.currentAmountOfElements === 0) {
      this.endGoNoGoGame();
    }

    this.endPreviousAttempt();
    this.startNextAttempt();

    this.currentAmountOfElements--;
  }

  public onStartGoNoGoGame(): void {
    this.gamePerformanceService.trackGameStart();
    this.gamePerformanceService.setGameId(10);
    this.gamePerformanceService.setCurrentAttempt();
  }

  public onGoNoGoGameClick(isSuccess: boolean): void {
    if (isSuccess) {
      this.gamePerformanceService.addAssertion();
      this.gamePerformanceService.setAttemptResult(1);
    } else {
      this.gamePerformanceService.addError();
      this.gamePerformanceService.setAttemptResult(0);
    }
    this.endPreviousAttempt();
    this.collectAttempt();
  }

  public onQuitGoNoGoGame(): void {
    this.gamePerformanceService.reset();
  }

  private setCurrentAttempt(): void {
    this.gamePerformanceService.setCurrentAttempt();
  }

  private endPreviousAttempt(): void {
    this.gamePerformanceService.trackAttemptEnd();
  }

  private startNextAttempt(): void {
    this.gamePerformanceService.trackAttemptStart();
  }

  private endGoNoGoGame(): void {
    this.gamePerformanceService.trackGameEnd();
    this.gamePerformanceService.calculateGamePerformance();

    let gameFailures = 0;
    let gameSuccesses = 0;
    let gameSkipped = 0;

    this.gamePerformanceService.getGamePerformance().attempts.forEach((attempt) => {
      const attemptResult = attempt.result;

      if (attemptResult === 0) {
        gameFailures++;
      }

      if (attemptResult === 1) {
        gameSuccesses++;
      }
    });

    gameSkipped = this.amountOfElements - (gameFailures + gameSuccesses);

    this.vueEventBus.$emit(
      'on-game-results',
      this.buildGoNoGoResultData(
        gameFailures,
        gameSuccesses,
        gameSkipped,
        this.gamePerformanceService.getGameDurationFormatted()
      )
    );

    this.resetManager();
  }

  private collectAttempt(): void {
    this.gamePerformanceService.collectAttempt();
  }

  private buildGoNoGoResultData(failures: number, successes: number, skipped: number, time: string): GoNoGoResultData {
    const resultData = new GoNoGoResultData();
    resultData.time = time;

    if (successes === 0 || failures > successes) {
      resultData.title = 'Ouch!';
      resultData.description =
        'Mas suerte la próxima vez, ¡sigue intentándolo! Estos son los resultados de tu intento:';
      resultData.button = 'Volver a intentar';
    } else {
      resultData.title = '¡Bien hecho!';
      resultData.description = 'Estos son los resultados de tu intento:';
      resultData.button = 'Continuar';
    }

    resultData.assertions = successes;
    resultData.errors = failures;
    resultData.time = time;
    resultData.skipped = skipped;

    return resultData;
  }

  private resetManager(): void {
    this.currentAmountOfElements = this.amountOfElements;
    this.gamePerformanceService.reset();
  }
}
