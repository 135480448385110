import EcuationUnknown from './EcuationUnknown';
import EcuationUnknownOptions from './EcuationUnknownOptions';

export default class Ecuation {
  public level: number = 0;
  public ecuation: string = '';
  public answer: number = 0;
  public result: number = 0;
  public options: EcuationUnknownOptions[]  = [];
  public description: string = '';
  public title: string = '';
  public unknowns: EcuationUnknown[] = [];
}
