<template>
  <div v-if="renderComponent">
    <div
      v-if="!isStarted"
      class="d-flex"
    >
      <div class="d-flex ml-auto mr-3">
        <custom-dialog
          class="d-flex ml-auto mt-4"
          :is-visible="dialog"
          :button-text="dialogData.buttonText"
          :title="dialogData.title"
          :description="dialogData.description"
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="mx-auto py-8 px-8 d-flex">
        <trophy-item
          :level="level"
          :color="throphyColor"
        />
      </div>
      <div></div>
    </div>
    <div class="d-flex mt-64 justify-center">
      <v-btn
        v-if="!this.isGameStarted"
        elevation="2"
        text
        class="d-flex mb-15 mx-auto"
        @click.prevent="startCountDown()"
      >
        Comenzar!
      </v-btn>

      <div
        id="game"
        v-if="this.isStarted && !this.isTimeOut"
        style="min-width: 300px; max-width: 300px"
      >
        <linear-progress :value="getTimeUntilStart" />
        <smiler-item
          :faces="randomTest"
          class="mt-8 item"
        ></smiler-item>
      </div>
      <div
        v-if="isTimeOut"
        class="d-flex my-auto mt-32"
      >
        <v-col>
          <custom-dialog-form
            :is-visible="isTimeOut"
            :button-text="'Enviar'"
            :title="'Cuantas caras sonrientes has visto'"
            :description="''"
            :answer="happyFaces"
            @onSubmit="setResults"
          />
        </v-col>
      </div>
      <div
        v-if="isWinner"
        class="mx-auto"
      >
        <ExerciseWinnerModal
          :is-visible="isWinner"
          @callback="reload"
          @repeat="onRepeat"
          @close-modal="onRepeat"
        />
      </div>
      <div
        v-if="isLoser"
        class="mx-auto"
      >
        <ExerciseLoserModal
          :is-visible="isLoser"
          @callback="reload"
          @repeat="onRepeat"
          @close-modal="onRepeat"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { DIALOG_SMILER_DATA } from '@/helpers/DialogHelper';
  import LinearProgress from './Misc/LinearProgress';
  import WinnerModal from './Misc/WinnerModal';
  import LoserModal from './Misc/LoserModal';
  import CustomDialog from './Misc/CustomDialog';
  import CustomDialogForm from './Misc/CustomDialogForm';
  import TrophyItem from './Misc/TrophyItem';
  import Smiler from '../Models/Smiler';
  import SmilerItem from './Misc/SmilerItem';
  import scrollToGame from '../helpers/GameHelper';
  import gameRequestMixin from '../mixins/gameRequestMixin.ts';
  import { mapGetters } from 'vuex';
  import ExerciseLoserModal from './Misc/Exercises/Modals/ExerciseLoserModal.vue';
  import ExerciseWinnerModal from './Misc/Exercises/Modals/ExerciseWinnerModal.vue';
  import SmilerGameService from './Games/Smiler/SmilerGameService';

  export default {
    name: 'Smiler',
    components: {
      LinearProgress,
      WinnerModal,
      LoserModal,
      CustomDialog,
      TrophyItem,
      SmilerItem,
      CustomDialogForm,
      ExerciseLoserModal,
      ExerciseWinnerModal,
    },

    mixins: [gameRequestMixin],

    data() {
      return {
        renderComponent: true,
        params: {},
        randomTest: [],
        timeUntilFinish: 100,
        isStarted: false,
        level: 1,
        dialog: false,
        dialogData: DIALOG_SMILER_DATA,
        throphyColor: 'yellow --darken',
        isLoser: false,
        result: null,
      };
    },

    async created() {
      await this.initGameMixin('SmilerController', this.buildGame, this.gameResults);
    },

    computed: {
      ...mapGetters({ isLogged: 'getIsLogged' }),

      getTimeUntilStart() {
        return this.params.maxTime;
      },

      isGameStarted() {
        return this.isStarted;
      },

      isTimeOut() {
        return this.params.maxTime === 0;
      },

      isWinner() {
        return this.result === this.happyFaces;
      },

      isFirstLevel() {
        return this.level < 2;
      },

      happyFaces() {
        return this.params.happyFaces;
      },
    },

    methods: {
      gameResults() {
        return {
          game: 'Smiler',
          idNivelPregunta: this.level,
          idAlumno: this.level,
          total: this.isWinner ? this.params.happyFaces + this.params.sadFaces : 0,
        };
      },

      startCountDown() {
        if (!this.isGameStarted) {
          let interval = setInterval(() => {
            if (this.params.maxTime > 0) {
              this.isStarted = true;
              this.params.maxTime--;
            } else {
              clearInterval(interval);
            }
          }, 100);
          scrollToGame();
        }
      },

      shuffleArray() {
        this.randomTest.sort(function () {
          return Math.random() - 0.5;
        });
      },

      reload() {
        if (this.isLogged) {
          this.saveResults(this.isOnChallenge);
        } else {
          this.onRepeat();

          return;
        }

        // Remove my-component from the DOM
        this.renderComponent = false;
        this.isLoser = false;
        this.result = null;
        this.params = {};
        this.randomTest = [];
        this.isStarted = false;

        this.$nextTick(() => {
          // Add the component back in
          this.renderComponent = true;
        });
      },

      setResults(result) {
        this.result = result;

        if (!this.isWinner) {
          this.isLoser = true;
        }
      },

      /**
       * @param {levelData: array, currentLevelId: number} params
       * @returns {void}
       */
      buildGame(params) {
        const gameService = new SmilerGameService();
        const data = params.levelData;
        this.level = params.currentLevelId;
        this.params = new Smiler(data.happyFaces, data.sadFaces, data.maxTime);

        for (let i = 0; i < this.params.happyFaces; i++) {
          this.randomTest.push(gameService.buildHappyItem(i));
        }
        for (let i = 0; i < this.params.sadFaces; i++) {
          this.randomTest.push(gameService.buildSadItem(i));
        }

        this.shuffleArray();
      },

      /**
       * @description Repeat the exercise when the user clicks on the repeat button in the modal.
       * This will keep the same level and the same data.
       * @returns {void}
       */
      onRepeat() {
        this.isLoser = false;
        this.result = null;

        this.$nextTick(() => {
          this.isStarted = false;
          this.params.maxTime = 100;

          // Refresh the randomTest array
          this.randomTest = [];
          this.buildGame({ levelData: this.params, currentLevelId: this.level });
        });
      },
    },
  };
</script>

<style>
  .break-object {
    max-width: 100%;
  }

  .square {
    max-width: 50px;
  }

  .progress {
    max-width: 500px;
  }

  .item {
    flex-wrap: wrap;
  }
</style>
