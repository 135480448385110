<template>
  <v-container id="cardField" class="wrappedField justify-center">
    <div v-for="card in field.cards" class="cardboard w-100 mx-auto">
      <div class="d-flex cardGame align-center">
        <v-card
          :id="card.id + '_back'"
          @click="onCardSelected(card.id, 'back')"
          class="d-flex cardGame__side cardGame__side--back"
          :class="card.class"
        >
          <v-icon class="w-100 my-auto mx-auto iconSize">{{
            card.imageSource
          }}</v-icon>
        </v-card>
        <v-card
          :id="card.id + '_front'"
          @click="onCardSelected(card.id, 'front')"
          class="d-flex cardGame__side cardGame__side--front align-center"
          :class="card.class"
        >
          <v-icon class="w-100 my-auto mx-auto iconSize">mdi-help-circle-outline</v-icon>
        </v-card>
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import MemoryCardField from '../Models/MemoryCardField';
  import MemoryCard from '../Models/MemoryCard';

  @Component
  export default class MemoryCardManager extends Vue {
    field: MemoryCardField | null = null;
    testData: Array<any> = [
      {
        id: 'smile1',
        imageSource: 'mdi-emoticon',
      },
      {
        id: 'smile2',
        imageSource: 'mdi-emoticon',
      },
      {
        id: 'car1',
        imageSource: 'mdi-car',
      },
      {
        id: 'car2',
        imageSource: 'mdi-car',
      },
      {
        id: 'cart1',
        imageSource: 'mdi-cart',
      },
      {
        id: 'cart2',
        imageSource: 'mdi-cart',
      },
      {
        id: 'cat1',
        imageSource: 'mdi-cat',
      },
      {
        id: 'cat2',
        imageSource: 'mdi-cat',
      },
      {
        id: 'castle1',
        imageSource: 'mdi-castle',
      },
      {
        id: 'castle2',
        imageSource: 'mdi-castle',
      },

      // new five
      {
        id: 'home',
        imageSource: 'mdi-home',
      },
      {
        id: 'home2',
        imageSource: 'mdi-home',
      },
      {
        id: 'google-downasaur1',
        imageSource: 'mdi-google-downasaur',
      },
      {
        id: 'google-downasaur2',
        imageSource: 'mdi-google-downasaur',
      },
      {
        id: 'pad1',
        imageSource: 'mdi-google-controller',
      },
      {
        id: 'pad2',
        imageSource: 'mdi-google-controller',
      },
      {
        id: 'dog',
        imageSource: 'mdi-dog-side',
      },
      {
        id: 'dog2',
        imageSource: 'mdi-dog-side',
      },
      {
        id: 'tortoise1',
        imageSource: 'mdi-tortoise',
      },
      {
        id: 'tortoise2',
        imageSource: 'mdi-tortoise',
      },
    ];
    cards: Array<any> = [];

    created() {
      this.testData.forEach((e) => {
        let card = new MemoryCard(e.id, e.imageSource);
        this.cards.push(card);
      });

      this.shuffleCards();

      this.field = new MemoryCardField(this.cards);
    }

    mounted() {
      this.field?.setField();
    }

    shuffleCards() {
      this.cards.sort(function () {
        return Math.random() - 0.5;
      });
    }

    onCardSelected(id: string, side: string) {
      if (!this.field) {
        return;
      }
      this.field.onCardSelected(id, side);

      this.$emit(
        'on-update-game',
        (this.field.matches / this.field.requiredMatches) * 100
      );
    }
  }
</script>
<style lang="scss">
  $background-color: #ece0e8;
  $color-primary-light: #ca3782;
  $color-primary-dark: #1e0b36;
  $color-black: #000;
  $color-gray-dark: #aaa;
  $color-gray-light: #eee;
  $color-white: #fff;

  @mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @mixin flexCenter {
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }

  @mixin respond($breakpoint) {
    @if $breakpoint == phone {
      @media (max-width: 600px) {
        @content;
      } // < 600px
    }
    @if $breakpoint == tab-port {
      @media (max-width: 900px) {
        @content;
      } // < 900px
    }
    @if $breakpoint == tab-land {
      @media (max-width: 1200px) {
        @content;
      } // < 1200px
    }
    @if $breakpoint == big-desktop {
      @media (min-width: 1200px) {
        @content;
      } // > 1800px
    }
  }

  .iconSize {
    font-size: 100px;

    @include respond(big-desktop) {
      font-size: 100px !important;
    }

    @include respond(tab-land) {
      font-size: 80px !important;
    }
    @include respond(tab-port) {
      font-size: 50px !important;
    }

    @include respond(phone) {
      font-size: 24px !important;
    }
  }

  .wrappedField {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(5, minmax(min(50px, 100%), 1fr));
  }

  .cardboard {
    display: flex;
    @include flexCenter;
    flex-grow: 1;
  }

  .cardGame {
    flex: initial;
    position: relative;
    min-height: 90px;
    min-width: 90px;
    -moz-perspective: 200rem;
    perspective: 200rem;
    margin: 0.5rem;

    @include respond(tab-port) {
      min-height: 80px;
      min-width: 80px;
    }

    @include respond(phone) {
      min-height: 40px;
      min-width: 40px;
    }

    &__side {
      height: -webkit-fill-available;
      transition: all 0.8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      -webkit-backface-visibility: hidden; /* We don't want to see the back part of the element. */
      backface-visibility: hidden; /* We don't want to see the back part of the element. */
      border-radius: 3px;
      overflow: hidden; /* The image is overflowing the parent. */
      box-shadow: 0 2rem 6rem rgba($color-black, 0.15);

      &--front {
        //
        background-color: rgb(250, 250, 250) !important;
      }

      &--back {
        background-color: $color-white;
        transform: rotateY(180deg);
      }
    }

    // Front side of the card
    &__theme {
      @include absCenter;
      top: 54%;
      width: 90%;
      text-align: center;
    }

    &__theme-box {
      color: $color-white;
      margin-bottom: 8rem;
    }

    &__subject {
      font-family: 'Inconsolata', monospace;
      letter-spacing: 0.8rem;
      font-size: 1.6rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    &__title {
      font-family: 'VT323', monospace;
      text-transform: uppercase;
      font-size: 6rem;
      font-weight: 100;
    }

    // Back side of the card
    &__cover {
      position: relative;
      background-size: cover;
      height: 14rem;
      // background-blend-mode: screen;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  .flipFromHidden {
    transform: rotateY(0deg);
  }

  .flipToHidden {
    transform: rotateY(-180deg);
  }

  .disable {
    pointer-events: none;
  }
</style>
