export default class GoNoGoHistoryScenario {
  /**
   * The text of the history
   */
  public historyText: string;

  /**
   * Is related to the background of the game. It can be "school", "home", "street", "park", "supermarket", "hospital", "zoo"
   */
  public scenario: string;

  constructor(scenario: string, historyText: string) {
    this.historyText = historyText;
    this.scenario = scenario;
  }
}
