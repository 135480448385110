import Order from '../Structures/Order';
import Product from '../Structures/Product';

export default class OrderService {
  public addProduct(order: Order, product: Product) {
    order.products.push(product);
    order.total += product.price;
  }

  public removeProduct(order: Order, product: Product) {
    for (let i = 0; i < order.products.length; i++) {
      if (order.products[i].name === product.name) {
        order.products.splice(i, 1);
        break;
      }
    }
  }

  public setTotal(order: Order) {
    order.total = this.calculateTotal(order);
  }

  private calculateTotal(order: Order): number {
    let total = 0;
    order.products.forEach((product) => {
      total += product.price;
    });
    return total;
  }

  public checkout(order: Order, customerOrder: Order): void {
    this.setTotal(customerOrder);
    this.validateOrder(order, customerOrder);
  }

  private validateOrder(order1: Order, order2: Order): boolean {
    if (order1.products.length > order2.products.length) {
      order1.errors.push('La order preparada contiene mas productos que la orden del cliente.');
    }

    if (order1.products.length < order2.products.length) {
      order1.errors.push('La order preparada contiene menos productos que la orden del cliente.');
    }

    if (order1.total !== order2.total) {
      order1.errors.push('El total de la order preparada es incorrecto.');
    }

    if (order1.total > order2.total) {
      order1.errors.push(
        'Lo que habia pedido el cliente es mas barato que lo que se le ha cobrado.Se deberia haber cobrado ' +
          order2.total +
          ' y se ha cobrado ' +
          order1.total +
          '.'
      );
    }

    if (order1.total < order2.total) {
      order1.errors.push(
        'Se le ha cobrado menos al cliente de lo que se deberia haber cobrado. Se deberia haber cobrado ' +
          order2.total +
          ' y se ha cobrado ' +
          order1.total +
          '.'
      );
    }

    if (this.orderContainSameProducts(order1, order2)) {
      return true;
    }

    order1.errors.push('La order preparada contiene productos diferentes a la orden del cliente.');

    return false;
  }

  private orderContainSameProducts(order1: Order, order2: Order): boolean {
    // Order products by name from order 1
    this.sortOrderProducts(order1);
    this.sortOrderProducts(order2);

    for (let i = 0; i < order1.products.length; i++) {
      if (order1.products[i].name !== order2.products[i].name) {
        return false;
      }
    }

    return true;
  }

  private sortOrderProducts(order: Order): void {
    const order1Products = order.products.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    order.products = order1Products;
  }
}
