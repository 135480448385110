<template>
  <div>
    <div v-if="isVisible">
      <v-dialog
        v-model="isVisible"
        transition="dialog-transition"
        max-width="500"
      >
        <v-card>
          <v-card-title class="d-block w-100 text-sm-center mx-auto">
            <p> Lo sentimos, hubo un error al procesar tu pago. </p>
          </v-card-title>
          <v-card-text class="text-center"> Motivo: {{ message }} </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              color="success"
              @click="closeAndReload()"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class PaymentErrorModal extends Vue {
    @Prop({ type: String, required: false }) message!: string;
    @Prop({ type: Boolean, required: true }) isVisible!: boolean;

    closeAndReload() {
      this.$emit('close-modal');

      setTimeout(() => window.location.reload(), 200);
    }
  }
</script>

<style scoped></style>
