import RequestStructure from '@/components/Request/Structures/RequestStructure';
import Global from '@/helpers/Global';
import http from '@/helpers/https';
import RequestService from '../../Request/RequestService';
import PerformancePreparatorService from './PerformancePreparatorService';
import GamePerformanceStructure from '../../UserPerformance/Structure/GamePerformanceStructure';
import CognosApi from '@/core/API/CognosApi';
import UsagePreparatorService from './UsagePreparatorService';
import GameUsageStructure from '../Structures/GameUsageStructure';

class DashboardStatisticsService {
  private requestService: RequestService;
  private performancePreparatorService: PerformancePreparatorService;
  private usagePreparatorService: UsagePreparatorService;
  private cognosApi: CognosApi;

  constructor() {
    this.requestService = new RequestService();
    this.performancePreparatorService = new PerformancePreparatorService();
    this.usagePreparatorService = new UsagePreparatorService();
    this.cognosApi = new CognosApi();
  }

  public async getUsageData(): Promise<Array<GameUsageStructure>> {
    const request = http();

    const data = await request
      .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/GameUsageController.php', {
        action: 'get-last-month',
      })
      .then((response) => {
        if (response.data.error) {
          return [];
        }

        return [...response.data];
      })
      .catch(function (error) {
        return [];
      });

    return this.usagePreparatorService.prepareData(data);
  }

  public async getWeeklyGameResults(): Promise<Array<any>> {
    const request = http();

    const data = await request
      .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/StatisticsController.php', {
        action: 'weekly',
      })
      .then((response) => {
        if (response.data.error) {
          return [];
        }

        return [...response.data];
      })
      .catch(function (error) {
        return [];
      });

    return data ?? [];
  }

  public async getMonthlySingleGameResults(gameId: number): Promise<Array<any>> {
    const request = http();

    const data = await request
      .post(Global.resolveUrl(process.env.NODE_ENV) + 'IntelectoController/StatisticsController.php', {
        action: 'monthly',
        gameId: gameId,
      })
      .then((response) => {
        if (response.data.error) {
          return [];
        }

        return [...response.data];
      })
      .catch(function (error) {
        return [];
      });

    return data ?? [];
  }

  public async getPerformanceData(userId: number): Promise<GamePerformanceStructure | null> {
    let requestStructure: RequestStructure = new RequestStructure();
    requestStructure.action = 'all';
    requestStructure.controller = 'GamePerformanceController';

    let data = await this.cognosApi.get('/performance/' + userId);

    if (!data || data.error) {
      return null;
    }

    return this.performancePreparatorService.prepareData(data);
  }
}

export default DashboardStatisticsService;
