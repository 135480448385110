export default class ProductHelper {
  private static drinks: Array<any> = [
    {
      name: 'Coca Cola',
      price: 3.0,
      icon: 'mdi-bottle-soda-classic-outline'
    },
    {
      name: 'Fanta',
      price: 3.0,
      icon: 'mdi-bottle-soda-classic-outline'
    },
    {
      name: 'Sprite',
      price: 3.0,
      icon: 'mdi-bottle-soda-classic-outline'
    },
    {
      name: 'Botella de agua',
      price: 2.0,
      icon: 'mdi-bottle-soda-classic-outline'
    },
    {
      name: 'Jarra de cerveza',
      price: 4.0,
      icon: 'mdi-beer'
    },
    {
      name: 'Café',
      price: 2.0,
      icon: 'mdi-coffee'
    },
  ];

  private static foods: Array<any> = [
    {
      name: 'Hamburguesa',
      price: 5.0,
      icon: 'mdi-hamburger'
    },
    {
      name: 'Pizza',
      price: 5.0,
      icon: 'mdi-pizza'
    },
    {
      name: 'Papas Fritas',
      price: 3.0,
      icon: 'mdi-french-fries'
    },
    {
      name: 'Ensalada',
      price: 4.0,
      icon: 'mdi-salad'
    },
    {
      name: 'Sandwich',
      price: 4.0,
      icon: 'mdi-sandwich'
    },
    {
      name: 'Tacos',
      price: 4.0,
      icon: 'mdi-taco'
    },
  ];

  static getRandomDrink() {
    return this.drinks[Math.floor(Math.random() * this.drinks.length)];
  }

  static getRandomFood() {
    return this.foods[Math.floor(Math.random() * this.foods.length)];
  }

  static getDrinks() {
    return this.drinks;
  }

  static getFoods() {
    return this.foods;
  }
}
