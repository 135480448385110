import GoNoGoBehavior from '../Structures/GoNoGoBehavior';
import GoNoGoSubjectBuilder from './GoNoGoSubjectBuilder';
import {GoNoGoLevelConfiguration} from '../GoNoGoLevelConfiguration';

export default class GoNoGoBehaviorBuilder {
  private subjectBuilder: GoNoGoSubjectBuilder;
  private configuration: GoNoGoLevelConfiguration;

  public constructor(configuration: GoNoGoLevelConfiguration) {
    this.configuration = configuration;
    this.subjectBuilder = new GoNoGoSubjectBuilder();
  }

  public build(subectToTrackName: string, subjectToAvoidName: string): GoNoGoBehavior {
    let firstSubject = this.subjectBuilder.build(subectToTrackName);
    let secondSubject = this.subjectBuilder.build(subjectToAvoidName);

    return new GoNoGoBehavior(firstSubject, secondSubject);
  }
}
