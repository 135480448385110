import ChartOptions from '../Structures/ChartOptions';

export default class ChartOptionProvider {
  public options = {
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          callback: function (value: string) {
            return value + '%';
          },
        },
      },
    },
    indexAxis: null,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  defaultOptions: ChartOptions = new ChartOptions();

  public getOptionsForVerticalBarChart(): ChartOptions {
    let options = this.defaultOptions;

    options.scales = {
      x: {
        ticks: {
          callback: function (value: string) {
            return value + '%';
          },
        },
      },
      y: null,
    };
    options.indexAxis = 'y';
    options.plugins = { legend: { display: false } };

    return options;
  }
}
