import CountryGameLevelHelper from '../../Helpers/CountryGameLevelHelper';
import CountryMapperHelper from '../../Helpers/CountryMapperHelper';
import Country from '../../Models/Country';
import CountryGameMode from '../../Models/CountryGameMode';
import CountryGameParams from '../../Models/CountryGameParams';
import ICountryGameBuilderStrategy from './ICountryGameBuilderStrategy';
import { getRandomElementsFromArray } from '@/helpers/Utils';

export default class FlagCountryGameBuilderStrategy implements ICountryGameBuilderStrategy {
  public build(mode: CountryGameMode): CountryGameParams {
    const params = new CountryGameParams();
    params.mode = mode.name;
    params.countries = this.prepareCountries(mode, params);
    params.question = this.getQuestion(params);
    params.correctAnswer = params.correctCountry.flag;

    return params;
  }

  // @ts-ignore
  public prepareCountries(mode: CountryGameMode, params: CountryGameParams): Country[] {
    const modeName = CountryMapperHelper.getMappedMode(mode.name);

    if (modeName !== 'flag') {
      throw new Error('Invalid game mode:' + mode.name + ' for FlagCountryGameStrategy');
    }

    const allCountries = CountryMapperHelper.mapCountriesByName().filter((country) => country.capital.length > 0);

    const countryAmount = CountryGameLevelHelper.getAmountOfCountries(mode);

    const randomRegion = CountryGameLevelHelper.getRandomRegion();

    const countriesInRegion = allCountries.filter((country) => country.region === randomRegion);

    const countryInRegion = getRandomElementsFromArray(countriesInRegion, 1) as Country[];

    const otherCountries = getRandomElementsFromArray(
      allCountries.filter((country) => country.region !== randomRegion),
      countryAmount - 1
    ) as Country[];

    params.correctCountry = countryInRegion[0];

    const countries = [...otherCountries, ...countryInRegion];

    return countries;
  }

  private getQuestion(result: CountryGameParams): string {
    return 'Cual es la bandera de ' + result.correctCountry.name + '?';
  }
}
