import Country from '../Models/Country';
import { countries } from '../Enum/CountryEnum';

export default class CountryMapperHelper {
  countries: any = countries;

  static mapCountriesByName(): Country[] {
    return countries.map((country) => {
      let capital = '';

      if (Object.hasOwnProperty.call(country, 'capital')) {
        capital = country.capital[0];
      }
   
      const flag = country.flags.png ?? '';

      return new Country(country.translations.spa.common, capital, flag, country.population, country.region);
    }, countries);
  }

  static getUnicodeFlag(countryName: string): string {
    const codePoints = Array.from(countryName.toUpperCase()).map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  }

  static getMappedMode(mode: string): string {
    switch (mode) {
      case 'Regiones':
        return 'region';
      case 'Capitales':
        return 'capital';
      case 'Banderas':
        return 'flag';
      case 'Poblacion':
        return 'population';
      default:
        throw new Error('Invalid game mode:' + mode + ' for Country game.');
    }
  }
}
