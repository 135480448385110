<template lang="">
  <div v-if="isQuestionCreated" class="d-inline justify-center my-4">
    <p class="no-wrap text-center">{{ questionTitle }}</p>
    <p class="no-wrap text-center">{{ questionValue }}</p>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Prop, Component } from 'vue-property-decorator';

  @Component
  export default class Question extends Vue {
    @Prop({ required: true }) question: string;

    private questionMathPrefix: string = '¿Cual es el resultado de la siguiente operación?';

    private title: string = '';
    private questionValue: string = '';

    /**
     * Indicates if the question is created and ready to be displayed.
     */
    public isQuestionCreated: boolean = false;

    get questionTitle(): string {
      return this.title;
    }

    get questionMath(): string {
      return this.questionValue;
    }

    public created(): void {
      this.prepareQuestion(this.question);
      this.isQuestionCreated = true;
    }

    private prepareQuestion(question: string): void {
      if (question.includes(this.questionMathPrefix)) {
        this.title = this.questionMathPrefix;
        this.questionValue = question.replace(this.questionMathPrefix, '');
      } else {
        this.title = '';
        this.questionValue = question;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .no-wrap {
    white-space: nowrap;
  }
</style>
