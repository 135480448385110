export default class EcuationEnum {
  public static readonly LEVEL_1: number = 1;
  public static readonly LEVEL_2: number = 2;
  public static readonly LEVEL_3: number = 3;
  public static readonly LEVEL_4: number = 4;
  // Background level colors

  // skyblue color in hex
    public static readonly LEVEL_1_BACKGROUND: string = '#87CEEB';
    public static readonly LEVEL_2_BACKGROUND: string = 'green';
    public static readonly LEVEL_3_BACKGROUND: string = 'orange';
    public static readonly LEVEL_4_BACKGROUND: string = 'red';

  public static readonly RULES: string =
    'En este juego deberás resolver ecuaciones matemáticas. Para ello, deberás seleccionar la respuesta correcta entre las opciones que se te presentan.';

  public static readonly LEVEL_1_RULES: string =
    'En este nivel deberás resolver ecuaciones de primer grado con una incógnita. Para ello, deberás seleccionar la respuesta correcta entre las opciones que se te presentan.';

  public static readonly LEVEL_2_RULES: string =
    'En este nivel deberás resolver ecuaciones de primer grado con una incógnita y con dos o mas cifras. Para ello, deberás seleccionar la respuesta correcta entre las opciones que se te presentan.';

  public static readonly LEVEL_3_RULES: string =
    'En este nivel deberás resolver ecuaciones de primer grado con una incógnita y con dos o mas cifras. Para ello, deberás seleccionar la respuesta correcta entre las opciones que se te presentan.';

  public static readonly LEVEL_4_RULES: string =
    'En este nivel deberás resolver ecuaciones de primer grado con una incógnita y con dos o mas cifras. Para ello, deberás seleccionar la respuesta correcta entre las opciones que se te presentan.';

  public static readonly LEVEL_1_TITLE: string = 'Nivel 1';
  public static readonly LEVEL_2_TITLE: string = 'Nivel 2';
  public static readonly LEVEL_3_TITLE: string = 'Nivel 3';
  public static readonly LEVEL_4_TITLE: string = 'Nivel 4';

  public static readonly LEVEL_1_DESCRIPTION: string = 'Ecuaciones de primer grado con una incógnita.';
  public static readonly LEVEL_2_DESCRIPTION: string =
    'Ecuaciones de primer grado con una incógnita y con dos o mas cifras.';
  public static readonly LEVEL_3_DESCRIPTION: string =
    'Ecuaciones de primer grado con una incógnita y con dos o mas cifras de mas de tres dígitos.';
  public static readonly LEVEL_4_DESCRIPTION: string =
    'Ecuaciones de primer grado con una incógnita y con dos o mas cifras de mas de tres dígitos.';

  public static readonly LEVEL_ONE_DATA: any = {
    id: EcuationEnum.LEVEL_1,
    title: EcuationEnum.LEVEL_1_TITLE,
    description: EcuationEnum.LEVEL_1_DESCRIPTION,
    rules: EcuationEnum.LEVEL_1_RULES,
    backgroundColor: EcuationEnum.LEVEL_1_BACKGROUND,
  };

  public static readonly LEVEL_TWO_DATA: any = {
    id: EcuationEnum.LEVEL_2,
    title: EcuationEnum.LEVEL_2_TITLE,
    description: EcuationEnum.LEVEL_2_DESCRIPTION,
    rules: EcuationEnum.LEVEL_2_RULES,
    backgroundColor: EcuationEnum.LEVEL_2_BACKGROUND,
  };

  public static readonly LEVEL_THREE_DATA: any = {
    id: EcuationEnum.LEVEL_3,
    title: EcuationEnum.LEVEL_3_TITLE,
    description: EcuationEnum.LEVEL_3_DESCRIPTION,
    rules: EcuationEnum.LEVEL_3_RULES,
    backgroundColor: EcuationEnum.LEVEL_3_BACKGROUND,
  };

  public static readonly LEVEL_FOUR_DATA: any = {
    id: EcuationEnum.LEVEL_4,
    title: EcuationEnum.LEVEL_4_TITLE,
    description: EcuationEnum.LEVEL_4_DESCRIPTION,
    rules: EcuationEnum.LEVEL_4_RULES,
    backgroundColor: EcuationEnum.LEVEL_4_BACKGROUND,
  };

  public static readonly LEVELS: any = [
    EcuationEnum.LEVEL_ONE_DATA,
    EcuationEnum.LEVEL_TWO_DATA,
    EcuationEnum.LEVEL_THREE_DATA,
    EcuationEnum.LEVEL_FOUR_DATA,
  ];
}
