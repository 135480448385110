import SmileItem from '../../../Models/SmileItem';

export default class SmilerService {
  public buildHappyItem(itemId: number): SmileItem {
    return new SmileItem(itemId + 'happy', 'mdi-emoticon');
  }

  public buildSadItem(itemId: number): SmileItem {
    return new SmileItem(itemId + 'sad', 'mdi-emoticon-sad');
  }
}
