<template lang="">
  <div>
    <div v-if="!isGameStarted">
      <div class="d-flex justify-center align-center mb-15">
        <h1>GoNoGo</h1>
        <div class="d-flex translated-top">
          <GoNoGoTrafficLights />
        </div>
      </div>
      <div class="d-flex mt-4 justify-space-around align-center">
        <div
          v-if="!isFormVisible"
          class="d-flex mx-auto"
        >
          <custom-dialog
            :is-visible="false"
            :button-text="dialogData.buttonText"
            :title="dialogData.title"
            :description="dialogData.description"
          />
        </div>

        <v-btn
          class="mx-auto"
          @click.prevent="isFormVisible = !isFormVisible"
          >Seleccionar dificultad</v-btn
        >
      </div>

      <v-form
        v-if="isFormVisible"
        class="container w-50 mx-auto mt-10"
        ref="formGoNoGo"
        lazy-validation
        v-model="valid"
      >
        <v-select
          :items="levelNames"
          label="Dificultad"
          v-model="configuration.speed"
          :rules="notNullRule"
          required
          :cache-items="true"
        ></v-select>
        <v-select
          :items="contexts"
          label="Escenario"
          :rules="[(v) => !!v || 'Escenario es requerido']"
          required
          v-model="configuration.context"
        ></v-select>

        <div class="d-flex w-100 justify-center mt-5">
          <v-btn
            class="mx-auto success"
            @click.prevent="onStartHistory()"
            >Jugar</v-btn
          >
        </div>
      </v-form>
    </div>
    <GoNoGoLayout
      v-if="isGameStarted"
      :scenario="configuration.context"
      @return-to-menu="resetCounters"
      @on-error="onError"
      class="mx-auto my-auto"
    >
      <div
        class="d-flex w-100 flex-column"
        style="flex-grow: 5"
      >
        <GoNoGoSceneManager
          v-if="isSceneStarted"
          :behavior="behavior"
          @onLoadItem="onLoadItem"
          @reset-counters="resetCounters"
          :level="configuration.speed"
        />
        <GoNoGoHistoryRenderer
          v-if="isHistoryStarted"
          class="d-flex w-100 justify-center align-center mb-5"
          :configuration="configuration"
          @initGame="onInitGame"
        />
        <GoNoGoGameControlllers
          class="mt-auto mb-1 align-self-bottom"
          v-if="behavior"
          :behavior="behavior"
          :is-clickable="unlocked"
          :errorCount="errorCount"
          :assertCount="successCount"
          @action="validateAction"
        />
      </div>

      <LinearProgress
        v-if="isSceneStarted"
        :value="getProgress()"
        class="verticalBar"
      />
    </GoNoGoLayout>
    <DialogDefault
      v-if="showDialogResult"
      :is-visible="showDialogResult"
      :button-text="dialogResultData.button"
      :title="dialogResultData.title"
      :description="dialogResultData.description"
      @on-close="onHideDialog"
    >
      <v-simple-table class="p-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                id="errors"
                class="text-left"
                >Errores</th
              >
              <th
                id="assertions"
                class="text-left"
                >Aciertos</th
              >
              <th
                id="skipped"
                class="text-left"
                >Omitidos</th
              >
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dialogResultData.errors }}</td>
              <td>{{ dialogResultData.assertions }}</td>
              <td>{{ dialogResultData.skipped }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </DialogDefault>
  </div>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import GoNoGoBuilderGameService from '../GoNoGoBuilderGameService';
  import { GoNoGoLevelConfiguration } from '../GoNoGoLevelConfiguration';
  import { contexts, levels } from '../GoNoGoHistoryMapper';
  import { notNullFormRule } from '../../../../core/Utils/FormRules';
  import GoNoGoBehavior from '../Structures/GoNoGoBehavior';
  import GoNoGoSubject from '../Structures/GoNoGoSubject';
  import GoNoGoActionValidatorService from '../GoNoGoActionValidatorService';
  import GoNoGoEventManager from '../GoNoGoEventManager';
  import { EventBus } from '../GoNoGoEventBus';
  import GoNoGoResultData from '../Structures/GoNoGoResultData';

  @Component({
    components: {
      CustomDialog: () => import('@/components/Misc/CustomDialog.vue'),
      GoNoGoLayout: () => import('./GoNoGoLayout.vue'),
      GoNoGoHistoryRenderer: () => import('./GoNoGoHistoryRenderer.vue'),
      GoNoGoGameControlllers: () => import('./GoNoGoGameControlllers.vue'),
      GoNoGoSceneManager: () => import('./GoNoGoSceneManager.vue'),
      DialogDefault: () => import('@/components/Misc/DialogDefault.vue'),
      LinearProgress: () => import('@/components/Misc/LinearProgress.vue'),
      GoNoGoTrafficLights: () => import('./GoNoGoTrafficLights.vue'),
    },
  })
  export default class GoNoGo extends Vue {
    @Prop({ default: false }) public isExercise!: boolean;

    private builder?: GoNoGoBuilderGameService = null;
    private configuration: GoNoGoLevelConfiguration;
    private actionValidator: GoNoGoActionValidatorService;
    private manager: GoNoGoEventManager;
    public contexts = ['Escuela', 'Parque'];
    public levelNames = Object.keys(levels).map((e) => parseInt(e));
    public behavior?: GoNoGoBehavior = null;
    public valid = false;
    public isFormVisible = false;
    public step: number = 0;
    public currentItem?: GoNoGoSubject = null;

    public errorCount: number = 0;
    public successCount: number = 0;
    public unlocked: boolean = false;

    // Modal variables
    public isDialogVisible = false;
    public dialogResultData: GoNoGoResultData = new GoNoGoResultData();

    constructor() {
      super();
      this.resetCounters();
      this.configuration = new GoNoGoLevelConfiguration();
      this.configuration.targetUser = 'adulto'; // this is hardcoded for now..
    }

    created() {
      EventBus.$on('on-game-results', (result: GoNoGoResultData) => {
        this.setDialogResultData(result);
        this.resetCounters();

        if(this.isExercise) {
          this.$emit('finishExercise', result.assertions);
        }
      });
    }

    get notNullRule(): Array<any> {
      return notNullFormRule;
    }

    get isGameStarted(): boolean {
      return this.step > 0;
    }

    get isHistoryStarted(): boolean {
      return this.step === 1;
    }

    get isSceneStarted(): boolean {
      return this.step === 2;
    }

    get showDialogResult(): boolean {
      return this.isDialogVisible;
    }

    get dialogData(): Object {
      return {
        title: 'GoNoGo',
        description:
          'Go No Go es un juego que trata de control e inhibición de impulsos, puedes elegir varios contextos para jugar, elige el que más te guste y diviértete! <br><br> El juego consiste en pulsar sobre el elemento opuesto al cual se muestra. <br><br> Atencion:  pueden cambiar de manera inesperada!',
        buttonText: 'Como jugar?',
      };
    }

    public onInitGame(behavior: GoNoGoBehavior): void {
      this.step = 2;
      this.manager.onStartGoNoGoGame();
    }

    public onStartHistory(): void {
      if (!this.validate()) {
        return;
      }

      this.builder = new GoNoGoBuilderGameService(this.configuration);
      this.configuration = this.builder.getGameConfiguration();
      this.behavior = this.builder.buildBehavior();
      this.actionValidator = new GoNoGoActionValidatorService(this.configuration);
      this.manager = new GoNoGoEventManager(EventBus, this.configuration.amountOfElements);

      this.step = 1;
    }

    public onLoadItem(item: GoNoGoSubject): void {
      if (!this.isSceneStarted) {
        return;
      }

      this.manager.onLoadItem();
      this.currentItem = item;
      this.unlocked = true;
    }

    public resetCounters(): void {
      this.step = 0;
      this.errorCount = 0;
      this.successCount = 0;
      this.unlocked = false;
    }

    public onHideDialog(): void {
      this.isDialogVisible = false;
      this.resetCounters();
    }

    public getProgress(): number {
      if (this.successCount === 0) {
        return 0;
      }

      return (this.successCount / this.configuration.amountOfElements) * 100;
    }

    public validateAction(clickedSubject: GoNoGoSubject): void {
      this.unlocked = false;
      const isValid = this.actionValidator.isValid(this.currentItem, clickedSubject);
      this.manager.onGoNoGoGameClick(isValid);

      isValid ? this.successCount++ : this.errorCount++;
    }

    private setDialogResultData(result: GoNoGoResultData): void {
      this.dialogResultData = result;
      this.isDialogVisible = true;
    }

    private validate(): boolean {
      const form = this.$refs.formGoNoGo;

      if (form) {
        // @ts-ignore
        return form.validate();
      }

      return false;
    }

    public onError(message: string): void {
      this.step = 0;
      window.confirm(message);
    }
  }
</script>
<style class="scss">
  /**
* Used to display the progress bar in the right side of the screen and vertically
*/
  .verticalBar {
    transform: rotate(-90deg);
    width: 100px;
    z-index: 1;

    /** green borders */
    border-left: 1px solid #4caf50;
    border-right: 1px solid #4caf50;
    border-top: 1px solid #4caf50;
    border-bottom: 1px solid #4caf50;

    /** Rounded borders */
    border-radius: 5px;
    padding: 0px 0px 0px 0px;

    /** Positioned to the right middle */
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -50px;
  }

  .translated-top {
    transform: translateY(-70px);
  }
</style>
