import GoNoGoSubject from '../Structures/GoNoGoSubject';
import IGoNoGoModeStrategy from './IGoNoGoModeStrategy';

/**
 * This strategy is used for the switching mode.
 * In this mode, the user must press the button when the subject is different from the previous one.
 * If the subject is the same, the user must not press the button
 */
export default class GoNoGoSwitchingStrategy implements IGoNoGoModeStrategy {
  public static readonly MODE: string = 'inhibition';

  public isValid(currentSubject: GoNoGoSubject, receivedSubject: GoNoGoSubject): boolean {
    return currentSubject.id !== receivedSubject.id;
  }
}
