import Ecuation from './Ecuation';
import EcuationLevelInterface from './EcuationLevelInterface';

/*Tipo de ecuaciones
ax + b = cx + d  (con los coeficientes a, b, c, d variando de -9 a 9)
Ejemplo: 4x +3 = 5x +1
El valor de x es 2
La solucion viene dada por:
x = (d-b)/(a-c)
*/

export default class EcuationLevelOne implements EcuationLevelInterface {
  public get(): Ecuation {
    // Get a random number between -9 and 9
    let a = this.getRandomNumber(-9, 9);
    let c = this.getRandomNumber(-9, 9);

    let b = this.getRandomNumber(-9, 9);
    let d = this.getRandomNumber(-9, 9);

    while (a - c == 0) {
      a = this.getRandomNumber(-9, 9);
      c = this.getRandomNumber(-9, 9);
    }

    while ((d - b) % (a - c) !== 0) {
      b = this.getRandomNumber(-9, 9);
      d = this.getRandomNumber(-9, 9);
    }

    const result = (d - b) / (a - c);

    const ecuation = new Ecuation();
    ecuation.title = 'Ecuación de primer grado';
    ecuation.description = 'Resuelve la ecuación de primer grado';

    if (b < 0 && d >= 0) {
      b = -1 * b;
      ecuation.ecuation = a + 'x - ' + b + ' = ' + c + 'x + ' + d;
    } else if (d < 0 && b >= 0) {
      d = -1 * d;
      ecuation.ecuation = a + 'x + ' + b + ' = ' + c + 'x - ' + d;
    } else if (d < 0 && b < 0) {
      d = -1 * d;
      b = -1 * b;
      ecuation.ecuation = a + 'x - ' + b + ' = ' + c + 'x - ' + d;
    } else ecuation.ecuation = a + 'x + ' + b + ' = ' + c + 'x + ' + d;

    ecuation.answer = result;

    return ecuation;
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
