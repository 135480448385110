import GoNoGoHistoryScenario from './GoNoGoHistoryScenario';

export class GoNoGoLevelConfiguration {
  /**
   * The modes of the game. It can be: "inhibition", "selective", "switching"
   * This object is used to validate the mode of the game. If the mode is inhibition, the user
   * has to press the button when the subject is not the target. If the mode is selective, the user
   * has to press the button when the subject is the target. If the mode is switching, the user has to
   * press the button when the subject is the target and not press the button when the subject
   * is not the target
   */
  public static readonly MODES = {
    INHIBITION: 'inhibition',
    SELECTIVE: 'selective',
    SWITCHING: 'switching',
  };

  /**
   * The speed of the game. It can be: "slow":1, "medium":2, "fast":3
   */
  public speed?: number;

  /**
   * The complexity of the game. It can be: "easy":1, "medium":2, "hard":3
   * The complexity is related to the amount of elements that the user has to remember
   * and the amount of elements that the user has to ignore
   */
  public complexity?: number;

  /**
   * The context of the game. It can be "cars, "animals", "fruits", "vegetables", "colors", "shapes"
   */
  public context?: string;
  public amountOfElements?: number = 0;

  /**
   * It can be "teenager", "child", "adult", "elderly"
   */
  public targetUser?: string;

  /**
   * This is related to the history of the game. Each game has a history that is related to the context and the scenario
   */
  public history?: GoNoGoHistoryScenario = null;

  public mode?: string = 'inhibition';

  constructor(speed?: number, context?: string, targetUser?: string, mode: string = 'inhibition') {
    this.speed = speed;
    this.context = context;
    this.targetUser = targetUser;
    this.mode = mode ?? GoNoGoLevelConfiguration.MODES.INHIBITION;
  }
}
