<template>
  <div>
    <WaiterDefault
      v-if="!isGameStarted"
      @on-start-game="startGame"
    />

    <!-- When game is started -->
    <div v-if="isGameStarted">
      <WaiterLobby
        v-if="step === 1"
        :gameParams="params"
        @onTimeOut="onTimeOut"
      />
    </div>

    <!-- When user is preparing the order -->
    <div v-if="step === 2">
      <WaiterPreparationScene />
    </div>

    <!-- When user is delivering the order -->
    <div v-if="step === 3">
      <MenuBar
        :timeToMakeOrder="timeToMakeOrder"
        @onTimeOut="onMenuBarTimeOut"
        @onDeliver="onDeliver"
      />
    </div>

    <!-- When user has delivered the order in timeout -->
    <div v-if="step === 4">
      <WaiterTimeOut @on-timeout="step = 6" />
    </div>

    <!-- When user has delivered the order without using all the time -->
    <div v-if="step === 5">
      <WaiterOnDeliver @on-timeout="step = 6" />
    </div>

    <!-- When user has delivered the order and move to calculate cost in checkout -->
    <div v-if="step === 6">
      <WaiterCheckout
        :order="preparedOrder"
        @on-checkout="onCheckout"
      />
    </div>

    <!-- User has calculated the order and will deliver to the customer -->
    <div v-if="step === 7">
      <WaiterDeliverToCustomer
        :preparedOrder="preparedOrder"
        @on-timeout="step = 8"
      />
    </div>

    <!-- User has delivered the order to the customer -->
    <div v-if="step === 8">
      <CustomerCheckout
        :order="preparedOrder"
        :customerOrder="customerOrder"
        @onFinishCheckout="onFinishCheckout"
      />
    </div>

    <!-- User has finished the game -->
    <div
      class="w-50 mx-auto"
      v-if="step === 9"
    >
      <WaiterSummary
        :order="preparedOrder"
        @on-restart="onRestart"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import WaiterDefault from './WaiterDefault.vue';
  import WaiterLobby from './WaiterLobby.vue';
  import GameParams from '../Structures/GameParams';
  import WaiterPreparationScene from './WaiterPreparationScene.vue';
  import MenuBar from './MenuBar.vue';
  import OrderLevelResolvingService from '../Services/OrderLevelResolvingService';
  import WaiterTimeOut from './WaiterTimeOut.vue';
  import WaiterOnDeliver from './WaiterOnDeliver.vue';
  import Order from '../Structures/Order';
  import WaiterCheckout from './WaiterCheckout.vue';
  import WaiterDeliverToCustomer from './WaiterDeliverToCustomer.vue';
  import CustomerCheckout from './CustomerCheckout.vue';
  import WaiterSummary from './WaiterSummary.vue';

  @Component({
    components: {
      WaiterDefault,
      WaiterLobby,
      WaiterPreparationScene,
      MenuBar,
      WaiterTimeOut,
      WaiterOnDeliver,
      WaiterCheckout,
      WaiterDeliverToCustomer,
      CustomerCheckout,
      WaiterSummary,
    },
  })
  export default class TheWaiter extends Vue {
    orderLevelResolvingService: OrderLevelResolvingService = new OrderLevelResolvingService();

    isGameStarted: boolean = false;
    levelSelected: number = 1;
    params: GameParams | null = null;
    step: number = 0;
    timeToMakeOrder: number = 0;
    preparedOrder: Order | null = null;
    customerOrder: Order | null = null;

    public startGame(level: number) {
      this.levelSelected = level;

      this.params = new GameParams(level);
      this.step = 1;

      setTimeout(() => {
        this.isGameStarted = true;
      }, 2000);
    }

    onTimeOut(customerOrder: Order) {
      this.customerOrder = customerOrder;
      this.step = 2;

      setTimeout(() => {
        this.timeToMakeOrder = this.orderLevelResolvingService.getLevelTime(this.levelSelected);
        this.step = 3;
      }, 4000);
    }

    onMenuBarTimeOut(order: Order) {
      this.preparedOrder = order;
      this.step = 4;
    }

    onDeliver(order: Order) {
      this.preparedOrder = order;
      this.step = 5;
    }

    onCheckout(order: Order) {
      this.preparedOrder = order;
      this.step = 7;
    }

    onDeliverToCustomer(order: Order) {
      this.preparedOrder = order;
      this.step = 8;
    }

    onFinishCheckout() {
      this.step = 9;
    }

    onRestart() {
      this.step = 0;
      this.isGameStarted = false;
      this.levelSelected = 1;
      this.params = null;
      this.step = 0;
      this.timeToMakeOrder = 0;
      this.preparedOrder = null;
      this.customerOrder = null;
    }
  }
</script>
