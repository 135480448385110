export const roundDecimal = (value: number, decimals: number) => {
  // @ts-ignore
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const shuffleArray = (array: any[]): any[] => {
  return array.sort(() => Math.random() - 0.5);
};

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
}

export const getRandomElementsFromArray = (arr: any[], numElements: number) => {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numElements);
 }
