<template lang="">
  <div v-if="visible" class="fade-in-out">
    <v-alert
      :value="true"
      type="warning"
      icon="mdi-alert"
      elevation="2"
      class="mb-4"
    >
      <span class="font-weight-bold">¡Atención!</span> No encontramos ningún ejercicio que cumpla con los parámetros
      seleccionados. Por favor, intenta con otros parámetros.
    </v-alert>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class WarningBanner extends Vue {
    @Prop({ default: false }) visible!: boolean;
}
</script>
<style>
.fade-in-out {
    animation-name: fade-in;
    animation-duration: 1.25s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
