import GoNoGoSubject from "./GoNoGoSubject";

export default class GoNoGoBehavior {
    public subjectToTrack: GoNoGoSubject;
    public subjectToAvoid: GoNoGoSubject;

    public constructor(subjectToTrack: GoNoGoSubject, subjectToAvoid: GoNoGoSubject) {
        this.subjectToTrack = subjectToTrack;
        this.subjectToAvoid = subjectToAvoid;
    }
}