class Subscription {
    id: Number;
    proTierId: Number;
    name: string;
    createdAt: string;
    activatedAt: string;
    updatedAt: string;
    expiresAt: string;
    deletedAt: string;

    constructor (params: any = {}) {
  
        this.id = params.id ?? null;

        this.proTierId = params.proTierId ?? null;

        this.name = params.name ?? null;

        this.createdAt = params.createdAt ?? null;

        this.activatedAt = params.activatedAt ?? null;

        this.updatedAt = params.updatedAt ?? null;

        this.expiresAt = params.expiresAt ?? null;

        this.deletedAt = params.deletedAt ?? null;
    }
}
export default Subscription;