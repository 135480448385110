<template>
  <div class="d-flex flex-column justify-center">
    <div class="d-flex w-100 text-center mb-3">
      <h3 class="mx-auto">Orden</h3>
    </div>
    <div class="d-flex w-100 flex-inline justify-center flex-row">
      <div
        v-for="product in products"
        class="flex-column"
      >
        <div class="productPreview">
          <v-icon>{{ product.icon }}</v-icon>
          <span>{{ product.name }}</span>
        </div>
      </div>
    </div>
    <v-btn
      class="mx-auto"
      color="primary"
      :disabled="isTimeOut"
      @click="onDeliver()"
    >
      Entregar
    </v-btn>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import Order from '../Structures/Order';
  import Product from '../Structures/Product';

  @Component
  export default class OrderPreview extends Vue {
    @Prop() order: Order;
    @Prop() isTimeOut: boolean;

    products: Array<Product> = [];

    mounted() {
      this.products = this.order.products;
    }

    onTimeOut() {
      this.$emit('onTimeOut', this.order);
    }

    onDeliver() {
      this.$emit('onDeliver', this.order);
    }
  }
</script>
<style>
  .productPreview {
    margin: 10px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    background-color: greenyellow;
  }
</style>
