import CountryGameMode from '../Models/CountryGameMode';

export default class CountryGameLevelHelper {
  static getAmountOfCountries(mode: CountryGameMode): number {
    let countryAmount: number;

    switch (mode.level) {
      case 1:
        countryAmount = 4;
        break;
      case 2:
        countryAmount = 8;
        break;
      case 3:
        countryAmount = 12;
        break;
      default:
        throw new Error('Invalid game level:' + mode.level + ' for strategy');
    }

    return countryAmount;
  }

  static getRandomRegion(): string {
    const regions = ['Africa', 'Americas', 'Asia', 'Europe', 'Oceania'];
    return regions[Math.floor(Math.random() * regions.length)];
  }

  static getRoundAmountByLevel(level: number): number {
    let roundAmount: number;

    switch (level) {
      case 1:
        roundAmount = 5;
        break;
      case 2:
        roundAmount = 10;
        break;
      case 3:
        roundAmount = 15;
        break;
      default:
        throw new Error('Invalid game level:' + level + ' for strategy');
    }

    return roundAmount;
  }
}
