<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>
        <br />
        <v-card-text v-html="description"> </v-card-text>
        <div class="px-4">
          <v-slider
            v-model="count"
            :rules="countRules"
            color="orange"
            label="Cantidad"
            hint="¿Estas seguro?"
            min="1"
            :max="randomLimit"
            thumb-label
          ></v-slider>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submit()"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "CustomDialogForm",

    props: {
      buttonText: {
        type: String,
        required: true,
        default: "",
      },

      title: {
        type: String,
        required: true,
        default: "",
      },

      description: {
        type: String,
        required: true,
        default: "",
      },

      isVisible: {
        type: Boolean,
        required: true,
        default: false,
      },

      answer: {
        type: Number,
        required: true,
        default: null,
      },
    },

    computed: {
      countSelected() {
        return this.count;
      },

      totalHappyFaces() {
        return this.answer;
      },

      randomLimit() {
        return this.getRandomLimitToAnswer();
      },

      suggestedMatch() {
       return this.totalHappyFaces + this.getRandomInt(3,9);
      }
    },

    data() {
      return {
        visible: this.isVisible,
        countRules: [(val) => val < this.suggestedMatch || `Piensalo bien!`],
        count: null,
      };
    },

    methods: {
      submit() {
        this.$emit("onSubmit", this.count);
        this.visible = false;
      },

      getRandomLimitToAnswer() {
        return this.totalHappyFaces + this.getRandomInt(10,30);
      },

      getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      },

      
    },
  };
</script>