class Smiler {

    happyFaces: Number;

    sadFaces: Number;

    maxTime: Number;

    constructor (happyFaces: Number, sadFaces: Number, maxTime: number) {
        this.happyFaces = happyFaces;
        this.sadFaces = sadFaces;
        this.maxTime = maxTime;
    }
}
export default Smiler;