import Product from './Product';

export default class OrderParams {
  public id: number;
  public customer: string;
  public name: string;
  public price: number;
  public table: number;
  public products: Array<Product> = [];
  public quantity: number;
  public total: number;
}
