import GameUsageStructure from '../Structures/GameUsageStructure';

export default class UsagePreparatorService {
  private GAME_ID_MAPPING: any = {
    1: { name: 'Memoria', icon: 'mdi-memory', color: 'blue' },
    2: { name: 'Signos', icon: 'mdi-numeric', color: 'green' },
    3: { name: 'Sonrisas', icon: 'mdi-emoticon', color: 'yellow' },
    4: { name: 'Categorias', icon: 'mdi-format-list-bulleted', color: 'red' },
    5: { name: 'Preguntas', icon: 'mdi-comment-question-outline', color: 'purple' },
    6: { name: 'Challenges', icon: 'mdi-trophy-variant', color: 'pink' },
    7: { name: 'Letras', icon: 'mdi-alphabetical', color: 'orange' },
    8: { name: 'Matrix', icon: 'mdi-matrix', color: '#607d8b' },
    9: { name: 'Duplicados', icon: 'mdi-content-duplicate', color: '#673ab7' },
    10: { name: 'Actividades', icon: 'mdi-format-list-checks', color: '#006400' },
    11: { name: 'GoNoGo', icon: 'mdi-gesture-tap-button', color: 'skyblue' },
    12: { name: 'Ecuaciones', icon: 'mdi-equation', color: 'brown' },
    13: { name: 'Paises', icon: 'mdi-earth', color: 'gray' },
    14: { name: 'Camarero', icon: 'mdi-coffee-to-go', color: 'black' },
  };

  public prepareData(data: any[]): GameUsageStructure[] {
    let preparedData: any = [];

    for (const item of data) {
      let gameUsageStructure: any = new GameUsageStructure();
      gameUsageStructure.idGame = item.idGame;
      gameUsageStructure.name = this.GAME_ID_MAPPING[item.idGame].name;
      gameUsageStructure.icon = this.GAME_ID_MAPPING[item.idGame].icon;
      gameUsageStructure.color = this.GAME_ID_MAPPING[item.idGame].color;
      gameUsageStructure.total = item.total;

      preparedData.push(gameUsageStructure);
    }

    return preparedData;
  }
}
