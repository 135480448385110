import Ecuation from './Ecuation';
import EcuationLevelInterface from './EcuationLevelInterface';

//@ts-ignore
export default class EcuationLevelTwo implements EcuationLevelInterface {
  public get(): Ecuation {
    let b = this.getRandomNumber(-9, 9);
    let d = this.getRandomNumber(-9, 9);
    let f = this.getRandomNumber(-9, 9);
    let h = this.getRandomNumber(-9, 9);

    while (b + d - f - h == 0) {
      /**
       * @var b string|number
       */
      b = this.getRandomNumber(-9, 9);
      d = this.getRandomNumber(-9, 9);
      f = this.getRandomNumber(-9, 9);
      h = this.getRandomNumber(-9, 9);
    }

    //A partir de ahora (b+d-f-h) es diferente de cero
    //Controlo que (e+g-c-a)/(b+d-f-h) sea una division entera para que no sean resultados decimales

    let e = this.getRandomNumber(-9, 9);
    let g = this.getRandomNumber(-9, 9);
    let c = this.getRandomNumber(-9, 9);
    let a = this.getRandomNumber(-9, 9);

    while ((e + g - c - a) % (b + d - f - h) != 0) {
      e = this.getRandomNumber(-9, 9);
      g = this.getRandomNumber(-9, 9);
      c = this.getRandomNumber(-9, 9);
      a = this.getRandomNumber(-9, 9);
    }

    const result = (e + g - c - a) / (b + d - f - h);

    if (b < 0) {
      b = -1 * b;
      // @ts-ignore
      b = ' - ' + b;
    } else {
      // @ts-ignore
      b = ' + ' + b;
    }

    if (c < 0) {
      c = -1 * c;
      // @ts-ignore
      c = ' - ' + c;
    } else {
      // @ts-ignore
      c = ' + ' + c;
    }
    if (d < 0) {
      d = -1 * d;
      // @ts-ignore
      d = ' - ' + d;
    } else {
      // @ts-ignore
      d = ' + ' + d;
    }

    if (f < 0) {
      f = -1 * f;
      // @ts-ignore
      f = ' - ' + f;
    } else {
      // @ts-ignore
      f = ' + ' + f;
    }

    if (g < 0) {
      g = -1 * g;
      // @ts-ignore
      g = ' - ' + g;
      // @ts-ignore
    } else g = ' + ' + g;

    if (h < 0) {
      h = -1 * h;
      // @ts-ignore
      h = ' - ' + h;
    } else {
      // @ts-ignore
      h = ' + ' + h;
    }

    const ecuation = new Ecuation();
    ecuation.title = 'Ecuación de segundo grado';
    ecuation.description = 'Resuelve la ecuación de segundo grado';
    ecuation.ecuation = a + b + 'x' + c + d + 'x = ' + e + f + 'x' + g + h + 'x';
    ecuation.answer = result;

    return ecuation;
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
